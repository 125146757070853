import React from 'react'
import { useRef } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const Filter = ( props ) => {
    const { cart } = useSelector( state => state );

    const { collections, search, setActiveCollection, getNFTsList, setSearch } = props
    const searchInput = useRef( null );
    const minInput = useRef( null );
    const maxInput = useRef( null );


    const submitHandler = ( event ) => {
        event.preventDefault()
        setSearch( {
            ...search,
            text: searchInput.current.value,
            min: minInput.current.value,
            max: maxInput.current.value
        } );
    }

    return (
        <div className="content-tabs marketplace">
            <form className="filter" onSubmit={submitHandler}>
                <div className="filter--item collection">
                    <select
                        className="collections---select"
                        onChange={e => {
                            setActiveCollection( e.target.value );
                        }}
                    >
                        <option value="">Select Collection</option>
                        {collections.length > 0 && collections.map( ( collection, index ) => (
                            <option
                                value={collection.value}
                                key={index}
                            >{collection.label}</option>
                        ) )}
                    </select>
                </div>
                <div className="filter--item search">
                    <input
                        ref={searchInput}
                        type="text"
                        placeholder="Search NFTs"
                    />
                </div>
                <div className="filter--item price">
                    <input
                        ref={minInput}
                        type="number"
                        placeholder="Min Price"
                    />
                    <span>To</span>
                    <input
                        ref={maxInput}
                        type="number"
                        placeholder="Max Price"
                    />
                </div>
                <div className="filter--item submit">
                    <button type="submit" className="btn btn-gradient">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                            <path d="M18.031 16.617l4.283 4.282-1.415 1.415-4.282-4.283A8.96 8.96 0 0 1 11 20c-4.968 0-9-4.032-9-9s4.032-9 9-9 9 4.032 9 9a8.96 8.96 0 0 1-1.969 5.617zm-2.006-.742A6.977 6.977 0 0 0 18 11c0-3.868-3.133-7-7-7-3.868 0-7 3.132-7 7 0 3.867 3.132 7 7 7a6.977 6.977 0 0 0 4.875-1.975l.15-.15z" />
                        </svg>
                        Search
                    </button>
                </div>
            </form>
            <Link to="/aqarland/shopping" className="cart--btn">
                <span>{cart.cartItems.length}</span>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                    <path d="M4 6.414L.757 3.172l1.415-1.415L5.414 5h15.242a1 1 0 0 1 .958 1.287l-2.4 8a1 1 0 0 1-.958.713H6v2h11v2H5a1 1 0 0 1-1-1V6.414zM5.5 23a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm12 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z" />
                </svg>
            </Link>
        </div>
    )
}

export default Filter
import { farms } from "../Config";
import getTokenSmartContract from "./getTokenSmartContract";

const getAllowance = async (pid, account) => {
  const tokenSmartContract = getTokenSmartContract(farms[pid].tokenAddresses[137]);
  const allowance = await tokenSmartContract.methods
    .allowance(account, farms[pid].lpAddresses[137])
    .call();
  return allowance;
};

export default getAllowance;

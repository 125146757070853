import React, { useEffect, useRef } from "react";
import Lottie from "lottie-web";
const Animation3 = () => {

    const container = useRef( null );
    useEffect( () => {
        Lottie.loadAnimation( {
            container: container.current,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: require( './Animation 3.json' )

        } )
    }, [] );
    return (
        <>
            <div className="polygon-art" ref={container} >
            </div>
        </>
    )
}

export default Animation3
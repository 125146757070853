import { combineReducers } from 'redux';

import auth from './Auth';
import app from './App';
import cart from "./Cart"

export default combineReducers( {
  auth,
  cart,
  app,
} );
import { Link } from "react-router-dom"

const Properties = () => {

    const propertiesData = [
        {
            title: "2BR Apartment at Burj Khalifa Fountain View",
            cover: require( "../../../assets/images/land-1.jpg" ).default,
            features: [
                {
                    title: "Total Share",
                    value: "1715 Sq.ft.",
                },
                {
                    title: "Available Area",
                    value: "1500 Sq.ft.",
                },
                {
                    title: "Sold Out",
                    value: "215 Sq.ft."
                },
                {
                    title: "Caimable Area",
                    value: "0"
                }
            ]
        }, {
            title: "Penthouse at Dubai Marina",
            cover: require( "../../../assets/images/land-2.png" ).default,
            features: [
                {
                    title: "Total Share",
                    value: "3724 Sq.ft.",
                },
                {
                    title: "Available Area",
                    value: "3300 Sq.ft.",
                },
                {
                    title: "Sold Out",
                    value: "424 Sq.ft."
                },
                {
                    title: "Caimable Area",
                    value: "0"
                }
            ]
        }, {
            title: "Studio Apartment with Highway View",
            cover: require( "../../../assets/images/land-3.png" ).default,
            features: [
                {
                    title: "Total Share",
                    value: "331 Sq.ft.",
                },
                {
                    title: "Available Area",
                    value: "280 Sq.ft.",
                },
                {
                    title: "Sold Out",
                    value: "51 Sq.ft."
                },
                {
                    title: "Caimable Area",
                    value: "0"
                }
            ]
        }, {
            title: "Pool View Studio Apartment",
            cover: require( "../../../assets/images/land-4.png" ).default,
            features: [
                {
                    title: "Total Share",
                    value: "361 Sq.ft.",
                },
                {
                    title: "Available Area",
                    value: "310 Sq.ft.",
                },
                {
                    title: "Sold Out",
                    value: "51 Sq.ft."
                },
                {
                    title: "Caimable Area",
                    value: "0"
                }
            ]
        }, {
            title: "Kensington Waters - I at Meydan - 1 BR",
            cover: require( "../../../assets/images/land-5.png" ).default,
            features: [
                {
                    title: "Total Share",
                    value: "826 Sq.ft.",
                },
                {
                    title: "Available Area",
                    value: "800 Sq.ft.",
                },
                {
                    title: "Sold Out",
                    value: "26 Sq.ft."
                },
                {
                    title: "Caimable Area",
                    value: "0"
                }
            ]
        }, {
            title: "Harrington House at Jumeirah Village Circle - 1BR",
            cover: require( "../../../assets/images/land-6.png" ).default,
            features: [
                {
                    title: "Total Share",
                    value: "761 Sq.ft.",
                },
                {
                    title: "Available Area",
                    value: "720 Sq.ft.",
                },
                {
                    title: "Sold Out",
                    value: "41 Sq.ft."
                },
                {
                    title: "Caimable Area",
                    value: "0"
                }
            ]
        },
        {
            title: "Private Infinity Pool 1BR Apartment",
            cover: require( "../../../assets/images/land-7.png" ).default,
            features: [
                {
                    title: "Total Share",
                    value: "850 Sq.ft.",
                },
                {
                    title: "Available Area",
                    value: "780 Sq.ft.",
                },
                {
                    title: "Sold Out",
                    value: "1500 Sq.ft."
                },
                {
                    title: "Caimable Area",
                    value: "0"
                }
            ]
        },
    ]

    return (
        <>
            <div className="lands">
                {propertiesData.map( ( property, index ) => {
                    return (
                        <div className="card" key={index}>
                            <img src={property.cover} className="card-img-top" alt="..." />
                            <div className="card-body">
                                <div className="card-name">
                                    <h5>{property.title}</h5>
                                </div>
                                <div className="card-title">
                                    {
                                        property.features.map( ( feature, index ) => {
                                            return (
                                                <div className="card-text" key={index}>
                                                    <span>{feature.title}</span>
                                                    <p>{feature.value}</p>
                                                </div>
                                            )
                                        } )
                                    }
                                </div>
                            </div>
                            <div className="card-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-toggle="modal"
                                    data-target="#exampleModal"
                                    data-whatever="@mdo"
                                    style={{ padding: "0.375rem 0.75rem" }}
                                >Claim Land</button>
                            </div>
                        </div>
                    )
                } )}
            </div>
            <div className="modal fade claim-modal" id="exampleModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="btn-close" data-dismiss="modal" aria-label="Close" />
                        </div>
                        <div className="modal-body">
                            <img src={require( "../../../assets/images/modal-img.png" ).default} alt="" />
                            <p>Land Claim can be done after the completion of Public Sale</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Properties

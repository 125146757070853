import axios from "axios";
import { authAxios, withoutAuthAxios } from "../../Config/axios";


export const getStateData = data => async dispatch => {
    await withoutAuthAxios().post(
        '/country/wise-state/',
        data,
    ).then(
        async ( response ) => {
            console.log( "response", response );
            let stateData = [];
            stateData = await response.data.data.map(
                ( { id: value, name: label, ...rest } ) => ( { value, label, ...rest } ),
            );
            dispatch( { type: 'STATE_DATA', payload: stateData } );
        },
        error => {
            console.log( error )
        }
    ).catch( error => console.log( error ) )
};

export const getCityData = data => async dispatch => {
    await withoutAuthAxios().post(
        '/state/wise-city/',
        data,
    ).then(
        async ( response ) => {
            let cityData = [];
            cityData = await response.data.data.map(
                ( { id: value, name: label, ...rest } ) => ( { value, label, ...rest } ),
            );
            dispatch( { type: 'CITY_DATA', payload: cityData } );
        },
        error => {
            console.log( error )
        }
    ).catch( error => console.log( error ) )
};


export const getTotenizedProperty = data => async () => {
    return new Promise( async ( resolve, reject ) => {
        await withoutAuthAxios().post( "/admin/tokenized-property/search", data )
            .then(
                response => resolve( response.data ),
                error => reject( error )
            )
            .catch(
                error => console.log( error )
            )
    } )
}

export const addToWhiteList = data => async () => {
    return new Promise( async ( resolve, reject ) => {
        await authAxios().post( "/whitelist", data )
            .then(
                response => resolve( response.data ),
                error => reject( error )
            )
            .catch(
                error => console.log( error )
            )
    } )
}


export const getPropertyDetails = id => async () => {
    return new Promise( async ( resolve, reject ) => {
        await withoutAuthAxios().get( `/admin/tokenized-property/${id}` )
            .then(
                response => resolve( response.data ),
                error => reject( error )
            )
            .catch(
                error => console.log( error )
            )
    } )
}

export const getAQRPrice = () => async dispatch => {
    await axios.get( "https://pro-api.coinmarketcap.com/v1/cryptocurrency/quotes/latest?symbol=BTC", {
        headers: {
            'X-CMC_PRO_API_KEY': '485c9b1b-5fa3-41d7-9d9c-af37c51a97e7',
        }
    } )
        .then( res => console.log( res ) ).catch( error => console.log( error ) )
}


export const getNFTs = async ( { activePage, limit, activeCollection, _text, sort, _min, _max } ) => {
    const _price = _max ? `&price=${_min ? _min : 0}-${_max}` : "";
    const _search = _text ? `&search=${_text}` : '';
    const _sort = sort ? `&sort=${sort}` : '&sort="price.asc"';
    const _filter = activeCollection ? `&filter=${activeCollection}` : '';
    const _limit = limit ? `&limit=${limit}` : "";
    const _pageNo = activePage ? `&page=${activePage}` : "";
    return new Promise( async ( resolve, reject ) => {
        await withoutAuthAxios().get( `/nft?${_pageNo}${_limit}${_filter}${_sort}${_search}${_price}` )
            .then(
                response => {
                    resolve( response.data )
                },
                error => {
                    reject( error )
                }
            )
            .catch(
                error => console.log( error )
            )
    } )
}

export const getNFTDetails = ( id, address ) => {
    return new Promise( async ( resolve, reject ) => {
        await withoutAuthAxios().get( `/nft/${address}/${id}` )
            .then(
                response => {
                    resolve( response.data )
                },
                error => {
                    reject( error.response )
                }
            )
            .catch( error => reject( error ) )
    } )
}

export const getCollections = async () => {
    return new Promise( async ( resolve, reject ) => {
        await withoutAuthAxios().get( `/nft/collection` )
            .then(
                response => {
                    resolve( response.data )
                },
                error => {
                    reject( error )
                }
            )
            .catch(
                error => console.log( error )
            )
    } )
}

export const getAllCuntry = () => async dispatch => {
    await axios.get( "https://gist.githubusercontent.com/anubhavshrimal/75f6183458db8c453306f93521e93d37/raw/f77e7598a8503f1f70528ae1cbf9f66755698a16/CountryCodes.json" )
        .then(
            async ( response ) => {
                let cuntryData = [];
                cuntryData = await response.data.map(
                    ( { code: value, name: label, ...rest } ) => ( { value, label, ...rest } ),
                );
                dispatch( { type: "CUNTRY_DATA", payload: cuntryData } )
            },
            error => {
                console.log( error );
            }
        )
        .catch(
            error => console.log( error )
        )
}
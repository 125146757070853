import MarketingBannerPic from '../../../assets/images/landing/marketing-banner.png';

function MarketingBanner() {
	return (
		<div className='container-lg'>
			<div className='cricket'>
				<div className='row no-gutters mt-2 mb-2 align-items-center'>
					<div className='col-md-12 col-12'>
						<div className='cricket__banner'>
							<img className='img-fluid' src={MarketingBannerPic} width='100%' />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default MarketingBanner;

import React from 'react'
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
const CircularProgressBar = ({percentage}) => {

    return (
        <div style={{ width: 70, height: 70 }}>
            <CircularProgressbar
                value={percentage}
                text={`${percentage}%`}
                styles={{
                    // Customize the root svg element
                    root: {
                        strokeWidth: '15px',
                    },
                    // Customize the path, i.e. the "completed progress"
                    
                    path: {
                        // Path color
                        stroke: `rgba(252, 182, 60, 1)`,
                        
                        // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                        // strokeLinecap: 'butt',
                        // Customize transition animation
                        transition: 'stroke-dashoffset 0.5s ease 0s',
                        // Rotate the path
                        // transform: 'rotate(0.25turn)',
                        // transformOrigin: 'center center',
                    },
                    // Customize the circle behind the path, i.e. the "total progress"
                    trail: {
                        // Trail color
                        stroke: '#d6d6d6',
                        // strokeWidth: '10px',
                        // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                        strokeLinecap: 'butt',
                        // Rotate the trail
                        transform: 'rotate(0.25turn)',
                        transformOrigin: 'center center',
                    },
                    // Customize the text
                    text: {
                        // Text color
                        fill: '#000',
                        // Text size
                        fontSize: '22px',
                    },
                    // Customize background - only used when the `background` prop is true
                    background: {
                        fill: '#FCB63C',
                    },
                }} />
        </div>
    )
}

export default CircularProgressBar
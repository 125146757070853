import React from 'react'
import { Link, useLocation } from 'react-router-dom'

const AqarlandSideBar = () => {

    const location = useLocation()

    const menus = [
        {
            title: 'Marketplace',
            icon: require( "../../../assets/images/Marketplace.svg" ).default,
            link: '/aqarland',
        },
        // {
        //     title: 'Properties',
        //     icon: require( "../../../assets/images/Properties.svg" ).default,
        //     link: "/aqarland/properties"
        // },
        {
            title: "Map",
            icon: require( "../../../assets/images/Map.svg" ).default,
            link: "/aqarland/map"
        },
        // {
        //     title: "Lands",
        //     icon: require( "../../../assets/images/Lands.svg" ).default,
        //     link: "/aqarland/collabLand"
        // }
    ]

    return (
        <div className="sidebar">
            <ul className="sidebar_nav">
                {
                    menus.map( ( menu, index ) => (
                        <li className="sidebar_nav-item" key={index}>
                            <Link className={`sidebar_nav-link ${location.pathname === menu.link ? "active" : ""}`} aria-current="page" to={menu.link}>
                                <img src={menu.icon} alt="" width="50px" />
                                <span>{menu.title}</span>
                            </Link>
                        </li>
                    ) )
                }
            </ul>
        </div>
    )
}

export default AqarlandSideBar

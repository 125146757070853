import sample from 'lodash/sample';

// Array of available nodes to connect to
export const nodes = ['https://nd-240-117-310.p2pify.com/83ce4985408b1cfb834635c52c5ead03'];

const getNodeUrl = () => {
	return sample(nodes);
};

export default getNodeUrl;

import PlatformPic from '../../../assets/images/landing/Tokenization Platform.png'

function Platform() {
    return (

        <div className="platform">
        	<div className="container-lg">
        		<div className="platform__con">
	            	<div className="row no-gutters mt-2 mb-2 align-items-center">
	                	<div className="col-md-6 col-12 order2">
	                        <div className="text-md-left text-center platform__text">
	                            <h2 className="content-subheader font-weight-bold mt-lg-4 mt-2 mb-4 alt-brand-blue">Tokenization Platform</h2>
								<p>
									Aqarchain has a platform that tokenizes real estate assets in
									real value of real estate metrics like Sq ft. A minimum share
									in the property is 1 sq ft. that can be purchased. The
									tokenization platform was launched publicly at the future
									blockchain summit part of Gitex 2021 in Dubai. With properties
									ranging from affordable housing to ultra luxury
								</p>
	                        </div>
	                    </div>
						<div className="col-md-6 col-12 order1">
							<div className='platform__img'>
	                        	<img className="img-fluid" src={PlatformPic} width="100%"/>
							</div>
	                    </div> 
	            	</div>
	            </div>
        	</div>
        </div>


    )
}


export default Platform;
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CircularProgressBar from "../../../components/CircularProgressBar";
import IsLoggedIn from "../../../components/IsLoggedIn";
import Multicall from "../../../helper/multicall/multicall";
import { Timer } from "../../../helper/Timer";
import css from "./index.module.scss";
import factoryabi from "../../../abi/aqarfactory.json";
import vaultabi from "../../../abi/aqarvault.json";
import { factory } from "../../../constants/addresses.json"
import { BigNumber } from "ethers";
import Placeholder from "../../../components/Placeholder";




const PropertyCard = ( { property, token, open } ) => {
    const {
        id,
        valuation,
        tokenAbbreviation,
        property: { cover, property_status: status, city, country },
        propertyTitle,
        ROI,
        total_share,
        totalTokenSupply,
        tokenPrice,
        AnnualRental: grossYield,
    } = property

    const [state, setState] = useState( {
        totalSupply: "",
        tokenPrice: "",
        name: "",
        symbol: "",
        saleTimer: "",
    } )

    useEffect( () => {
        setState( {
            totalSupply: "",
            tokenPrice: "",
            name: "",
            symbol: "",
            saleTimer: "",
        } )
        getBlockData()
    }, [open, id] )

    const getBlockData = async () => {
        const getTokenID = [
            {
                address: factory.address,
                name: "propIdTopropTokenId",
                params: [id],
            }
        ]
        const [ID] = await Multicall( factoryabi.abi, getTokenID );


        const getTokenAddress = [
            {
                address: factory.address,
                name: "NftToToken",
                params: [parseInt( ID )],
            }
        ];
        const [tokenAddress] = await Multicall( factoryabi.abi, getTokenAddress );

        const getTokenData = [
            {
                address: tokenAddress.toString(),
                name: "totalSupply",
            },
            {
                address: tokenAddress.toString(),
                name: "tokenPrice",
            },
            {
                address: tokenAddress.toString(),
                name: "name",
            },
            {
                address: tokenAddress.toString(),
                name: "symbol",
            },
            {
                address: tokenAddress.toString(),
                name: "endtime",
            },
        ]

        const [totalSupply, tokenPrice, token, symbol, saleTimer] = await Multicall(
            vaultabi.abi,
            getTokenData
        );
        setState( {
            totalSupply: Number( BigNumber.from( totalSupply.toString() )
                .div( BigNumber.from( "10" )
                    .pow( BigNumber.from( "18" ) ) ) ),
            tokenPrice: Number( tokenPrice.toString() ),
            name: token.toString(),
            symbol: symbol.toString(),
            saleTimer: parseInt( saleTimer ),
        } )
    }


    return (
        <div className={css.card} key={id}>
            <div className={css.propertyHead}>
                <div className={css.propertyTitle}>
                    <h3>{propertyTitle}</h3>
                    <span>{
                        status.toLowerCase() !== "active"
                            ? tokenAbbreviation
                            : !state.symbol
                                ? <Placeholder />
                                : state.symbol
                    }</span>
                </div>
                <p><span>{city}</span>, <span>{country}</span></p>
            </div>
            <div className={css.banner}>
                <img
                    className={css.propertyImage}
                    src={`${process.env.REACT_APP_BASH_URL}${cover}`}
                />
                {status === "Upcoming" && (
                    <div className={css.bannerDetails}>
                        <div className={css.price}>{
                            valuation.toLocaleString( "en-US", {
                                style: "currency",
                                currency: "USD",
                                maximumFractionDigits: 0,
                            } )
                        }</div>
                        <div className={css.impression}>
                            <h4>{`${( total_share / Number( totalTokenSupply.$numberDecimal ) * 100 ).toFixed( 2 )}%`}</h4>
                            <span>Subscribed</span>
                        </div>
                    </div>
                )}
                {status === "Active" && (
                    <>
                        <div className={css.progressBox}>
                            <CircularProgressBar percentage={status === "Closed" ? 100 : 50} />
                        </div>
                        <div className={css.timer}>
                            {!state.saleTimer ? <Placeholder /> : <Timer
                                unixtime={state.saleTimer}
                            />}
                        </div>
                    </>
                )}

            </div>
            <div className={css.propertyBody}>
                <ul>
                    <li>
                        Total Shares<span>{status === "Upcoming" ? Number( totalTokenSupply.$numberDecimal ).toLocaleString( "en-US" ) : !state.totalSupply ? <Placeholder /> : state.totalSupply}</span>
                    </li>
                    <li>
                        Per Share Price<span>
                            {
                                status === "Upcoming" ? Number( tokenPrice.$numberDecimal ).toLocaleString( "en-US", {
                                    style: "currency",
                                    currency: "USD",
                                } ) : !state.tokenPrice ? <Placeholder /> : state.tokenPrice.toLocaleString( "en-US", {
                                    style: "currency",
                                    currency: "USD"
                                } )
                            }
                        </span>
                    </li>
                    <li>
                        Gross Yield<span>{parseInt( grossYield.$numberDecimal ).toLocaleString( 'en-US', {
                            style: 'currency',
                            currency: 'USD'
                        } )}</span>
                    </li>
                    <li>
                        Guaranteed Annual ROI<span>{`${ROI.$numberDecimal}%`}</span>
                    </li>
                </ul>
                {status === "Active" || status === "Upcoming" ? (
                    <Link
                        className={css.propertyButton}
                        to={token ? `/details/${id}` : "/login"}
                    >
                        {
                            token ? "View Details" : "Login to View Details"
                        }
                    </Link>
                ) : null}
                {status === "Closed" && (
                    <button
                        className={`${css.propertyButton} ${css.disabled}`}
                    >
                        Claim Token
                    </button>
                )}
            </div>
        </div>
    )
}

export default IsLoggedIn( PropertyCard )

import { useEffect } from 'react';
import { useState } from 'react';
import Web3 from 'web3';
import { AqarMarketplace } from '../abi';
import { useMoralis, useMoralisWeb3Api } from 'react-moralis';

const useNFTs = (id, address) => {
	const RPCProvider = new Web3.providers.HttpProvider(
		'https://nd-240-117-310.p2pify.com/83ce4985408b1cfb834635c52c5ead03'
	);
	const web3 = new Web3(RPCProvider);
	const [stoke, setStoke] = useState(0);
	const Web3Api = useMoralisWeb3Api();

	const nftData = async () => {
		const AqarMarketplaceContract = new web3.eth.Contract(
			AqarMarketplace.abi,
			AqarMarketplace.networks[process.env.REACT_APP_CHAIN_ID].address
		).methods;
		const _stoke = await AqarMarketplaceContract.listings(address, id).call();
		setStoke(Number(_stoke.quantity));
	};
	const fetchNFTOwners = async () => {
		const options = {
			address: address,
			token_id: id,
			chain: 'polygon',
		};
		const nftOwners = await Web3Api.token.getTokenIdOwners(options);
		return nftOwners;
	};

	useEffect(() => {
		nftData();
	}, [stoke]);

	return { nftData, stoke, fetchNFTOwners };
};

export default useNFTs;

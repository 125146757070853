import { useCallback } from "react";
import { useWeb3React } from "@web3-react/core";
import { toast } from "react-toastify";
import getAllowance from "../utils/getAllowance";
import getInWei from "../utils/getInWei";
import getSmartContract from "../utils/getsmartcontract";
import BigNumber from "bignumber.js";
import useApprove from "./useApprove";
import useWeb3 from './useWeb3'

const useDeposit = ( id ) => {
  const { callApprove } = useApprove( id );
  const { account } = useWeb3React();
  const web3 = useWeb3()

  const callDeposit = useCallback(
    async ( pid, amount ) => {
      let token;
      if ( pid === 0 ) token = "AQR";
      else token = "LP";

      const FarmSmartContract = getSmartContract( id );
      const allowance = await getAllowance( id, account );

      // Check if deposit amount is greater than allowance
      // use BigNumber as JavaScript says =>  "1000" < "9"
      if (
        new BigNumber( amount ).isGreaterThanOrEqualTo( new BigNumber( allowance ) )
      ) {
        toast.info( "Processing...", "Approving your account for deposits" );

        try {
          await callApprove();
          toast.success( "Success", "Your account is approved for deposits" );
        } catch ( e ) {
          toast.error( "Error", "Failed to approve your account for deposits" );
        }
      }

      toast.info(
        "Processing...",
        `You requested to deposit ${amount} ${token}`
      );

      const amountInWei = getInWei( amount );

      await FarmSmartContract.methods
        .deposit( pid, amountInWei )
        .send( { from: account } )
        .on( "transactionHash", hash => {
          toast.info( `Transaction hash: ${hash}` );
        } )
        .on( "confirmation", ( confirmationNumber, receipt ) => {
          if ( confirmationNumber === 1 ) {
            toast.success( `Transaction confirmed: ${receipt.transactionHash}` );
          }
        } )
        .on( "receipt", receipt => {
          toast.info( `Transaction receipt: ${receipt.transactionHash}` );
        } )
        .on( "error", error => {
          toast.error( `Transaction error: ${error.message}` );
        } );

      // await FarmSmartContract.methods
      //   .deposit( pid, amountInWei )
      //   .estimateGas( { from: account } )
      //   .then( ( gasAmount ) => {
      //     web3.eth.getGasPrice( async ( err, currentGasPrice ) => {
      //       if ( err ) {
      //         toast.error( "Error while estimating gas \n" + err.message + "\nPlease set gas manully" );
      //       } else {
      //         await FarmSmartContract.methods
      //           .deposit( pid, amountInWei )
      //           .send( { from: account, gas: gasAmount, gasPrice: currentGasPrice } )
      //           .on( "transactionHash", hash => {
      //             toast.info( `Transaction hash: ${hash}` );
      //           } )
      //           .on( "confirmation", ( confirmationNumber, receipt ) => {
      //             if ( confirmationNumber === 1 ) {
      //               toast.success( `Transaction confirmed: ${receipt.transactionHash}` );
      //             }
      //           } )
      //           .on( "receipt", receipt => {
      //             toast.info( `Transaction receipt: ${receipt.transactionHash}` );
      //           } )
      //           .on( "error", error => {
      //             toast.error( `Transaction error: ${error.message}` );
      //           } );
      //         toast.success( "Success", `${amount} ${token} deposited successfully` );
      //       }
      //     } );
      //   }, async err => {
      //     toast.error( "Error while estimating gas \n" + err.message + "\nPlease set gas manully" );
      //     await FarmSmartContract.methods
      //       .deposit( pid, amountInWei )
      //       .send( { from: account } )
      //       .on( "transactionHash", hash => {
      //         toast.info( `Transaction hash: ${hash}` );
      //       } )
      //       .on( "confirmation", ( confirmationNumber, receipt ) => {
      //         if ( confirmationNumber === 1 ) {
      //           toast.success( `Transaction confirmed: ${receipt.transactionHash}` );
      //         }
      //       } )
      //       .on( "receipt", receipt => {
      //         toast.info( `Transaction receipt: ${receipt.transactionHash}` );
      //       } )
      //       .on( "error", error => {
      //         toast.error( `Transaction error: ${error.message}` );
      //       } );
      //     toast.success( "Success", `${amount} ${token} deposited successfully` );
      //   } );
    },
    [account, callApprove]
  );

  return { callDeposit };
};
export default useDeposit;

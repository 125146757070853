import React from 'react'
import AnimationVideo from "../../../assets/images/login/Animation 1.mp4";
const RealEstate = () => {
  return (
      <>
        <div className="real-estate-nft">
        <div className="container-lg">
          <div className="real-estate-nft__row">
            <div className="real-estate-nft__col">
              <div className="real__content">
                <h1 >Real-estate NFTs </h1>
                <p>The Property is a digital Asset which is minted into a NFT and the property is divided into square feet, so 1 Square feet is 1 share in the property. This share is a fractional portion of the Property NFT, which is also called the Property Share token.</p>
                <a className="btn btn-gradient btn-rounded semi-small">Login / Signup Now</a>
              </div>
            </div>
            <div className="real-estate-nft__col">
              <div className="real__video-content">
                <p>1 Square feet = 1 share</p>
                <div className="real__video-box">
                  <video className="video" autoPlay loop>
                    <source src={AnimationVideo} type="video/mp4" />
                  </video>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default RealEstate
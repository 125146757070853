import React from 'react'
import { Link } from 'react-router-dom'
import Popup from 'reactjs-popup'
import VideoPlayer from './VideoPlayer'

const BannerDetails = ( { apidata } ) => {



    function numberWithCommas ( x ) {
        if ( x ) { return x.toString().replace( /\B(?=(\d{3})+(?!\d))/g, "," ) }
        else return x
    }

    return (
        <section id="property-detail-meta-and-banner">
            <div id="property-detail-meta" className="container-lg">
                <div className="row">
                    <div className="col-lg-8">
                        <div id="property-detail-meta-top">
                            <div className="text-center d-block d-lg-flex justify-content-center">
                                <div
                                    id="property-detail-meta-360_tour"
                                    className="d-block d-lg-flex property-detail-meta align-items-center pb-3 mb-3 pb-lg-0 mb-lg-0 pr-lg-4 mr-lg-4"
                                >
                                    <img src="/images/icon-property-detail-meta-360_tour.svg" />
                                    <span className="ml-3">360° tour</span>
                                </div>
                                <Popup
                                    trigger={
                                        <div
                                            id="property-detail-meta-video"
                                            className="d-block d-lg-flex property-detail-meta align-items-center pb-3 mb-3 pb-lg-0 mb-lg-0 pr-lg-4 mr-lg-4">
                                            <img
                                                alt=""
                                                src="/images/icon-property-detail-meta-video.svg"
                                            />
                                            <span className="ml-3">Video</span>
                                        </div>
                                    }
                                    modal>
                                    <VideoPlayer data={apidata} />
                                </Popup>
                                <div
                                    id="property-detail-meta-report"
                                    className="d-block d-lg-flex property-detail-meta align-items-center"
                                >
                                    <a style={{
                                        color: "#fff",
                                        textDecoration: "none"
                                    }} href={`${process.env.REACT_APP_BASH_URL}/${apidata.id}.pdf`} target="_blank">
                                        <img src="/images/icon-property-detail-meta-report.svg" />
                                        <span className="ml-3">Report</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div id="property-detail-meta-middle">
                            <div className="text-center d-block text-lg-left d-lg-flex justify-content-center">
                                <div
                                    id="property-detail-meta-price"
                                    className="property-detail-meta pb-3 mb-3 pb-lg-0 mb-lg-0 pr-lg-5 mr-lg-5"
                                >
                                    <h2 className="mb-0">Price</h2>
                                    <h1 className="mb-0 font-weight-bold">{`${apidata.property?.currency} ${numberWithCommas( apidata.property?.price )}`}</h1>
                                </div>
                                <div
                                    id="property-detail-meta-location"
                                    className="property-detail-meta pb-3 mb-3 pb-lg-0 mb-lg-0 pr-lg-5 mr-lg-5"
                                >
                                    <h2 className="mb-0">Location</h2>
                                    <h1 className="mb-0 font-weight-bold">{apidata.property?.city}, {apidata.property?.country}</h1>
                                </div>
                                <div
                                    id="property-detail-meta-type"
                                    className="property-detail-meta"
                                >
                                    <h2 className="mb-0">Type</h2>
                                    <h1 className="mb-0 font-weight-bold">Apartment</h1>
                                </div>
                            </div>
                        </div>
                        <div id="property-detail-meta-bottom">
                            <div className="text-center d-block d-lg-flex justify-content-center align-items-center">
                                <div
                                    id="property-detail-meta-bedrooms"
                                    className="d-block d-lg-flex property-detail-meta align-items-center pb-3 mb-3 pb-lg-0 mb-lg-0 pr-lg-4 mr-lg-4"
                                >
                                    <img src="/images/icon-icon-property-detail-meta-bedrooms.svg" />
                                    <span className="ml-3 font-weight-bold">{apidata.property?.propertyFeature?.number_of_beds} Bed</span>
                                </div>
                                <div
                                    id="property-detail-meta-bathrooms"
                                    className="d-block d-lg-flex property-detail-meta align-items-center pb-3 mb-3 pb-lg-0 mb-lg-0 pr-lg-4 mr-lg-4"
                                >
                                    <img src="/images/icon-property-detail-meta-bathrooms.svg" />
                                    <span className="ml-3 font-weight-bold">{apidata.property?.propertyFeature?.number_of_baths} Bath</span>
                                </div>
                                <div
                                    id="property-detail-meta-area"
                                    className="d-block d-lg-flex property-detail-meta align-items-center"
                                >
                                    <img src="/images/icon-property-detail-meta-area.svg" />
                                    <span className="ml-3 font-weight-bold">{apidata.property?.propertyFeature?.propertyArea} sq.ft.</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="property-detail-banner" className="container-lg">
                <div className="row">
                    <div className="col-lg-8 text-center">
                        <div className="mt-5 mb-5">
                            <a href="#">
                                <img
                                    className="img-fluid"
                                    src="/images/property-detail-banner.png"
                                    alt="Best Deals in Dubai"
                                />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default BannerDetails

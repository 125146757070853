import { useState, useEffect } from "react"
import css from './index.module.scss'
import { useMoralis, useMoralisWeb3Api } from "react-moralis";
import trimAdd from "../../../../../utils/TrimAddress";
import Placeholder from "../../../../../components/Placeholder";
import useNFTs from "../../../../../hookss/useNFTs";

const TransactionsHistory = ( { address, id } ) => {

    const [txHistory, setTxHistory] = useState( null )
    const [owners, setOwners] = useState( null )
    const Web3Api = useMoralisWeb3Api();

    const { isInitialized } = useMoralis()
    const { fetchNFTOwners } = useNFTs( id, address )

    const fetchTransactions = async () => {
        const options = {
            address: address,
            chain: process.env.REACT_APP_CHAIN,
            token_id: id
        };
        const NFTs = await Web3Api.token.getWalletTokenIdTransfers( options );
        setTxHistory( NFTs.result )
    };

    useEffect( async () => {
        if ( isInitialized ) {
            fetchTransactions()
            setOwners( await fetchNFTOwners() )
        }
    }, [address, id, isInitialized] )
    return (
        <>
            <div className={css.title}>
                <h4>Transaction History</h4>
                <span>{`${owners ? owners.total : 0} Owners`}</span>
            </div>
            <div className={css.card}>
                {( () => txHistory || [...Array( 4 )] )().map( ( tx, index ) => (
                    <div className={css.cardItem} key={index}>
                        {!tx ? <Placeholder full={true} dark={true} /> : (
                            <>
                                <a href={`${process.env.REACT_APP_POLYCON_EXP_URL}address/${tx.from_address}`} target={"_blank"}>
                                    {tx.from_address === "0x0000000000000000000000000000000000000000"
                                        ? "Minted" : trimAdd( tx.from_address )
                                    }
                                </a>
                                <div className={css.action}>
                                    <span>{tx.amount}</span>
                                    <a href={`${process.env.REACT_APP_POLYCON_EXP_URL}tx/${tx.transaction_hash}`} target={"_blank"}>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                            <path d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z" />
                                        </svg>
                                    </a>
                                </div>
                                <a href={`${process.env.REACT_APP_POLYCON_EXP_URL}address/${tx.to_address}`} target={"_blank"}>{trimAdd( tx.to_address )}</a>
                            </>
                        )}
                    </div>
                ) )}
            </div>
        </>
    )
}

export default TransactionsHistory
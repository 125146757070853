import { Link, useLocation } from "react-router-dom"

const Menus = () => {
    const location = useLocation()
    return (
        <ul className="navbar-nav">
            <li className={`nav-item ${location.pathname === "/" && location.hash !== "#landing-page-faq-section_6" ? "active" : ""}`}>
                <Link className="nav-link" to="/">
                    Home
                </Link>
            </li>
            <li className={`nav-item ${location.pathname === "/discover" ? "active" : ""}`}>
                <Link className="nav-link" to="/discover">
                    Discover
                </Link>
            </li>
            <li className={`nav-item ${location.pathname === "/staking" ? "active" : ""}`}>
                <Link className="nav-link" to="/staking">
                    Staking
                </Link>
            </li>
            <li className={`nav-item ${location.pathname === "/aqarland" ? "active" : ""}`}>
                <Link className="nav-link" to="/aqarland">
                    AqarLand
                </Link>
            </li>
        </ul>
    )
}

export default Menus
import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import "./paginate.css";
import BigNumber from "bignumber.js";
import factoryabi from "../../abi/aqarfactory.json";
import vaultabi from "../../abi/aqarvault.json";
import Multicall from "../../helper/multicall/multicall";
import { Link } from "react-router-dom";
import CircularProgressBar from "../../components/CircularProgressBar/index";
import { Timer } from "../../helper/Timer";
import {
  factory
} from "../../constants/addresses.json";
import { useDispatch } from "react-redux";
import { getTotenizedProperty } from "../../Redux/action/App";
import IsLoadingHOC from "../IsLoadingHOC/index";
import SearchBar from "../SearchBar";
import IsLoggedIn from "../IsLoggedIn";

function Discover2 ( props ) {
  const { setLoading, token } = props;
  const [showfilter, setshowfilter] = useState( false );
  const [loader, setLoader] = useState( true );

  const [state, setstate] = useState();
  const [country, setcountry] = useState();
  const [city, setcity] = useState();
  const [proptype, setproptype] = useState();

  const [data, setdata] = useState( [{}] );
  const [propblockdata, setpropblockdata] = useState( [] );

  const [allProperty, setAllProperty] = useState( [] );

  //property pagination
  const [pagenumber, setpagenumber] = useState( 0 );
  const propPerPage = 16;
  const pagevisited = propPerPage * pagenumber;
  const pagecount = Math.ceil( data.length / propPerPage );
  const changePage = ( { selected } ) => {
    setpagenumber( selected );
  };

  //getting each user balance
  const gettingPropDataFromBlockchain = async ( tokens ) => {
    const promises = tokens.map( async ( token ) => {
      const factorycalls = [
        {
          address: factory.address,
          name: "propIdTopropTokenId",
          params: [token.property.id],
        },
      ];
      const [a] = await Multicall( factoryabi.abi, factorycalls );

      const vaultaddrescall = [
        {
          address: factory.address,
          name: "NftToToken",
          params: [parseInt( a )],
        },
      ];

      const [b] = await Multicall( factoryabi.abi, vaultaddrescall );

      const vaultcalls = [
        {
          address: b.toString(),
          name: "totalSupply",
        },
        {
          address: b.toString(),
          name: "tokenPrice",
        },
        {
          address: b.toString(),
          name: "name",
        },
        {
          address: b.toString(),
          name: "symbol",
        },
        {
          address: b.toString(),
          name: "ListPrice",
        },
        {
          address: b.toString(),
          name: "endtime",
        },
        {
          address: b.toString(),
          name: "fee",
        },
      ];
      const [c, d, e, f, listprice, endtime, fee] = await Multicall(
        vaultabi.abi,
        vaultcalls
      );

      var total = [
        new BigNumber( parseInt( c ) )
          .dividedBy( new BigNumber( 10 ).pow( "18" ) )
          .toNumber()
          .toFixed( 2 ),
        new BigNumber( parseInt( d ) )
          .dividedBy( new BigNumber( 10 ).pow( "18" ) )
          .toNumber()
          .toFixed( 2 ),
        e,
        f,
        new BigNumber( parseInt( listprice ) )
          .toNumber()
          .toFixed( 2 ),
        parseInt( endtime ),
        parseInt( fee ),
      ];
      return total;
    } );
    const resp = await Promise.all( promises );
    setpropblockdata( resp );
  };

  const dispatch = useDispatch();

  const loadProperty = async () => {
    setLoading( true );
    const body = {
      limit: "1000",
      filter: {
        propertyTitle: "",
      },
    };
    await dispatch( getTotenizedProperty( body ) )
      .then(
        ( response ) => {
          let soldOut = [];
          let property = []
          response.data.filter( item => {
            if ( item.id === "AQ740968ARE"
              || item.id === "AQ894515ARE"
              || item.id === "AQ882135ARE" ) {
              soldOut.push( item )
            } else {
              property.push( item )
            }
          } )
          setAllProperty( [...property, ...soldOut] );
          gettingPropDataFromBlockchain( [...property, ...soldOut] );
          setLoading( false );
        },
        ( error ) => {
          console.log( "response", error );
        }
      )
      .catch( ( error ) => {
        console.log( "response", error );
      } );
  };

  useEffect( () => {
    loadProperty();
  }, [] );

  return (
    <div>
      <div>
        <section id="discover-section_1">
          <div className="container-lg">
            <div className="row align-items-center">
              <div id="discover-section_1-form" className="col-lg-5">
                <div id="discover-form">
                  <div id="discover-form-top">
                    <SearchBar
                      setAllProperty={setAllProperty}
                      gettingPropDataFromBlockchain={
                        gettingPropDataFromBlockchain
                      }
                      setLoading={setLoading}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <br></br>
        {showfilter ? (
          <section id="discover-1_5">
            <div className="row mb-5">
              <div className="col-lg-8 offset-lg-2">
                <div id="discover-form">
                  <div id="discover-form-bottom">
                    <div className="row">
                      <div className="col-lg-3 col-md-6">
                        <div className="form-group">
                          <select
                            className="form-control"
                            onChange={( e ) => {
                              setcountry( e.target.value );
                            }}
                          >
                            <option value>Country</option>
                            <option>UAE</option>
                            <option>KSA</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6">
                        <div className="form-group">
                          <select
                            className="form-control"
                            onChange={( e ) => {
                              setcity( e.target.value );
                            }}
                          >
                            <option value>City</option>
                            <option>City #1</option>
                            <option>City #2</option>
                            <option>City #3</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6">
                        <div className="form-group">
                          <select
                            className="form-control"
                            onChange={( e ) => {
                              setstate( e.target.value );
                            }}
                          >
                            <option value>State</option>
                            <option>Apartment</option>
                            <option>Villa</option>
                            <option>Furnished Flat</option>
                            <option>Farm House</option>
                            <option>Under Construction</option>
                            <option>Shop</option>
                            <option>Office Space</option>
                            <option>Warehouse</option>
                            <option>Land</option>
                            <option>Building</option>
                            <option>Compound</option>
                            <option>Hotel Apartments</option>
                            <option>Penthouse</option>
                          </select>
                          {state}
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6">
                        <div className="form-group">
                          <select
                            className="form-control"
                            onChange={( e ) => {
                              setproptype( e.target.value );
                            }}
                          >
                            <option value>Property Type</option>
                            <option>Apartment</option>
                            <option>Villa</option>
                            <option>Furnished Flat</option>
                            <option>Farm House</option>
                            <option>Under Construction</option>
                            <option>Shop</option>
                            <option>Office Space</option>
                            <option>Warehouse</option>
                            <option>Land</option>
                            <option>Building</option>
                            <option>Compound</option>
                            <option>Hotel Apartments</option>
                            <option>Penthouse</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className>
                      <div className="col-lg-6 offset-lg-3 pt-4">
                        <div id="discover-form-bottom-buttons">
                          <button
                            className="btn btn-gradient-secondary"
                            type="button"
                          >
                            Find
                          </button>
                          <button
                            className="btn btn-gradient-secondary"
                            type="button"
                          >
                            Reset
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : null}
        <section id="discover-section_2" style={{ minHeight: "90vh" }}>
          <div className="container-lg">
            <div className="row pb-md-5">
              {allProperty.length !== 0 &&
                allProperty.map( ( item, index ) => (
                  <div className="col-xl-3 col-md-6 mb-5 mb-lg-2" key={index}>
                    <div className="discover-card has-box-shadow text-lg-left text-center">
                      <div className="card-body">
                        <div
                          className="card--header"
                        >
                          <h4 className="discover-card-title font-weight-bold mb-3">
                            {item.propertyTitle}{" "}
                          </h4>
                          <p><span>{item.property.city}</span>, <span>{item.property.country}</span></p>
                        </div>
                        <img
                          style={{
                            height: "240px",
                            objectFit: "cover",
                          }}
                          className="card-img-top discover-card-image"
                          src={`https://token-api.aqarchain.io/${item.property.cover}`}
                          alt="discover-cardimage cap"
                        />
                        <div
                          className="card-body"
                          style={{ paddingBottom: "0" }}
                        >
                          <div className="row"></div>
                          <div className="row">
                            <table
                              id="discover-table"
                              className="discover-table col-12"
                            >
                              <tbody>
                                <tr className="discover-table-border">
                                  <td className="discover-table-left discover-token-value" style={{
                                    width: "170px"
                                  }}>
                                    <span>Token Name</span>
                                  </td>
                                  <td className="font-weight-bold">
                                    <span className="font-normal discover-table-left mr-2 ml-2">
                                      {" "}
                                      :{" "}
                                    </span>
                                    <span className="text-secondary" style={{ overflow: "hidden" }}>
                                      {propblockdata.length !== 0 &&
                                        `${pagenumber == 0
                                          ? propblockdata[index][3]
                                          : propblockdata[
                                          index + pagenumber * 10
                                          ][1]
                                        }`}
                                    </span>
                                  </td>
                                </tr>
                                <tr className="discover-table-border">
                                  <td className="discover-table-left discover-card-content">
                                    <span>Total Shares</span>
                                  </td>
                                  <td className="font-weight-bold discover-card-content">
                                    <span className="font-normal discover-table-left mr-2 ml-2">
                                      {" "}
                                      :{" "}
                                    </span>
                                    <span className="text-secondary">
                                      {propblockdata.length !== 0 &&
                                        `${pagenumber == 0
                                          ? propblockdata[index][0]
                                          : propblockdata[
                                          index + pagenumber * 10
                                          ][0]
                                        }`}
                                    </span>
                                  </td>
                                </tr>
                                <tr className="discover-table-border">
                                  <td className="discover-table-left discover-token-value">
                                    <span>Per Share Price</span>
                                  </td>
                                  <td className="font-weight-bold">
                                    <span className="font-normal discover-table-left mr-2 ml-2">
                                      {" "}
                                      :{" "}
                                    </span>
                                    <span className="text-secondary">
                                      {propblockdata.length !== 0 &&
                                        `$ ${pagenumber == 0
                                          ? ( propblockdata[index][4] / propblockdata[index][0] ).toFixed( 2 )
                                          : propblockdata[
                                          index + pagenumber * 10
                                          ][1]
                                        }`}
                                    </span>
                                  </td>
                                </tr>
                                <tr className="discover-table-border">
                                  <td className="discover-table-left discover-card-content">
                                    <span>Gross Yield </span>
                                  </td>
                                  <td className="font-weight-bold">
                                    <span className="font-normal discover-table-left mr-2 ml-2">
                                      {" "}
                                      :{" "}
                                    </span>
                                    <span className="text-secondary">
                                      $ {parseInt( item.AnnualRental.$numberDecimal ).toLocaleString( 'en-US' )}
                                    </span>
                                  </td>
                                </tr>
                                <tr className="discover-table-border discover-table-border-down">
                                  <td className="discover-table-left discover-card-content">
                                    <span>Guaranteed Annual ROI</span>
                                  </td>
                                  <td className="font-weight-bold">
                                    <span className="font-normal discover-table-left mr-2 ml-2">
                                      :
                                    </span>{" "}
                                    <span className="text-secondary">
                                      {item.ROI.$numberDecimal}%
                                    </span>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <div
                              className="col-12 mt-4 text-center d-flex justify-content-between align-items-center">
                              {item.propertyTitle == "Studio Apartment with Highway view"
                                || item.propertyTitle == "2BR Apartment at Burj Khalifa Fountain View"
                                || item.propertyTitle == "Private Infinity Pool 1BR Apartment"
                                ? <CircularProgressBar percentage={100} />
                                : item.propertyTitle == "Harrington House at Jumeirah Village Circle - 1BR"
                                  || item.propertyTitle == "Kensington Waters - I at Meydan - 1 BR"
                                  || item.propertyTitle == "Private Infinity Pool 1BR Apartment"
                                  ? <CircularProgressBar percentage={50} />
                                  : <CircularProgressBar percentage={40} />}
                              <div className="property-clock-wrap">
                                <div className="text-secondary font-bold big property-clock">
                                  {/* <img src="/public/images/Timer-watch.svg" /> */}
                                  {propblockdata.length !== 0 &&
                                    propblockdata[index][5] > 0 && (
                                      <Timer
                                        unixtime={propblockdata[index][5]}
                                      />
                                    )}
                                </div>
                              </div>
                            </div>
                            <div style={{ width: "100%", marginTop: "1rem" }}>
                              {item.propertyTitle == "Studio Apartment with Highway view" || item.propertyTitle == "2BR Apartment at Burj Khalifa Fountain View" || item.propertyTitle == "Private Infinity Pool 1BR Apartment"
                                ?
                                <button
                                  className="btn "
                                  style={{ width: "100%", backgroundColor: "grey" }}
                                >
                                  Sold Out!
                                </button> :
                                <Link
                                  style={{ width: "100%", display: "block" }}
                                  to={{
                                    pathname: token ? `/details/${item.property.id}` : "/login",
                                    state: {
                                      blockChainData:
                                        propblockdata.length !== 0 &&
                                        propblockdata[index],
                                      property: item,
                                    },
                                  }}
                                >
                                  <button
                                    className="btn btn-gradient"
                                    style={{ width: "100%" }}
                                  >
                                    View Details
                                  </button>
                                </Link>}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) )}
              {allProperty.length !== 0 && (
                <div
                  style={{
                    margin: "2rem auto",
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <ReactPaginate
                    previousLabel={"Previous"}
                    nextLabel={"Next"}
                    pageCount={pagecount}
                    onPageChange={changePage}
                    containerClassName={"paginationBttns"}
                    previousLinkClassName={"previousBttns"}
                    nextLinkClassName={"nextBttns"}
                    disabledClassName={"paginationDisabled"}
                    activeClassName={"paginationActive"}
                  />
                </div>
              )}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default IsLoggedIn( IsLoadingHOC( Discover2 ) );

import { Interface } from "@ethersproject/abi";
import MultiCallAbi from "../../abi/multicallabi.json";
import { RPC } from "./constants";
import Web3 from "web3";

const Multicall = async ( abi, calls ) => {
  const web3 = new Web3( RPC );
  const multi = new web3.eth.Contract(
    MultiCallAbi.abi,
    "0x11ce4B23bD875D7F5C6a31084f55fDe1e9A87507"
  );
  const itf = new Interface( abi );

  const calldata = calls.map( ( call ) => [
    call.address.toLowerCase(),
    itf.encodeFunctionData( call.name, call.params ),
  ] );
  const { returnData } = await multi.methods.aggregate( calldata ).call();
  const res = returnData.map( ( call, i ) =>
    itf.decodeFunctionResult( calls[i].name, call )
  );

  return res;
};
export default Multicall;

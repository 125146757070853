import "../../assets/css/scss/main.scss";
import StakingCard from "../../components/StakingCard";
import { farms } from "../../Config/index"
import ComingSoon from "./ComingSoon";
import OnPlatform from "./OnPlatform";
import PriceCalculator from "./PriceCalculator";
import ShareToken from "./ShareToken";

const Staking = () => {
    return (
        <>
            <div className="body">
                <div className="staking">
                    <div className="container">
                        <div className="staking__list">
                            {
                                farms.map( ( pool, i ) => (
                                    <StakingCard pool={pool} i={i} key={i} />
                                ) )
                            }
                        </div>
                    </div>
                </div>
            </div>
            <ComingSoon />
            <OnPlatform />
            <PriceCalculator />
            <ShareToken />
        </>
    )
}

export default Staking

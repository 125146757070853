import axios from "axios";

import { store } from '../Redux/store';

export const withoutAuthAxios = () => {
  return axios.create( {
    baseURL: process.env.REACT_APP_BASH_URL,
    // baseURL: "https://stagging-token-api.aqarchain.io"
  } );
};

export const authAxios = () => {
  let token = store.getState( 'state' ).auth.token;
  return axios.create( {
    baseURL: process.env.REACT_APP_BASH_URL,
    // baseURL: "https://stagging-token-api.aqarchain.io",
    headers: {
      Authorization: `${token ? `Bearer ${token}` : null}`,
    },
  } );
};

export const kycAxios = () => {
  return axios.create( {
    baseURL: "https://api.kycaid.com",
    headers: {
      Authorization: `Token 25b98c0a1e14904b84383a41a69f5db18638`,
      "Access-Control-Allow-Origin": '*'
    }
  } )
}
import css from './index.module.scss'
import Placeholder from "../../Placeholder"
const SideData = ( { property } ) => {

  const {
    valuation,
    property: { property_status: status, },
    totalTokenSupply,
    tokenPrice,
  } = property;

  const onChain = null
  return (
    <>
      <ul className={css.dataList}>
        <li>
          Property Value
          <span>{status === "Upcoming" ? valuation.toLocaleString( "en-US", {
            style: "currency",
            currency: "USD"
          } ) : !onChain.totalSupply ? <Placeholder /> : onChain.totalSupply}</span>
        </li>
        <li>
          Per Share Price
          <span>
            {
              status === "Upcoming" ? Number( tokenPrice.$numberDecimal ).toLocaleString( "en-US", {
                style: "currency",
                currency: "USD"
              } ) : !onChain.tokenPrice ? <Placeholder /> : onChain.tokenPrice.toLocaleString( "en-US", {
                style: "currency",
                currency: "USD"
              } )
            }
          </span>
        </li>
        <li>
          Total Shares
          <span>{status === "Upcoming" ? Number( totalTokenSupply.$numberDecimal ).toLocaleString( "en-US" ) : !onChain.totalSupply ? <Placeholder /> : onChain.totalSupply}</span>
        </li>
      </ul>
    </>
  )
}

export default SideData
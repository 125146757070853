import React from 'react';
import dotenv from 'dotenv'
import ReactDOM from 'react-dom';
import App from './App';
import { store, persistor } from './Redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
dotenv.config()

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
  document.getElementById( 'root' )
);

import React from 'react'

const Team = () => {

    const dataList = [
        {
            name: "Waqas Nakhwa",
            designation: "CEO",
            image: require( "../../assets/images/team-members/01-Waqas-Nakhwa.png" ).default
        },
        {
            name: "Haitham AL Hammadi",
            designation: "COO",
            image: require( "../../assets/images/team-members/02-Haitham-AL-Hammadi.png" ).default
        },
        {
            name: "Saoud Al Nuaimi",
            designation: "CMO",
            image: require( "../../assets/images/team-members/03-Saoud-Al-Nuaimi.png" ).default
        },
        {
            name: "Munther AlBlooshi",
            designation: "CPO",
            image: require( "../../assets/images/team-members/04-Munther-AlBlooshi.png" ).default
        },
        {
            name: "Akash Kapadia",
            designation: "Head of Design",
            image: require( "../../assets/images/team-members/06-Akash-Kapadia.png" ).default
        },
        {
            name: "Dhruvil Rana",
            designation: "UI-UX Designer",
            image: require( "../../assets/images/team-members/08-Dhruvil-Rana.png" ).default
        },
        {
            name: "Sanchit Dawar",
            designation: "Blockchain Developer",
            image: require( "../../assets/images/team-members/12-Sanchit-Dawar.png" ).default
        },
        {
            name: "Nahlah Alhorayess",
            designation: "Graphic Designer",
            image: require( "../../assets/images/team-members/13-Nahlah-Alhorayess.png" ).default
        },
        {
            name: "Bothayna Emad Hawsawi",
            designation: "Content Specialist (Arabic)",
            image: require( "../../assets/images/team-members/14-Bothayna-Emad-Hawsawi.png" ).default
        },
        {
            name: "Priyanka Joshi",
            designation: "Content Writer",
            image: require( "../../assets/images/team-members/15-Priyanka-Joshi.png" ).default
        },
    ]

    return (
        <div className="container-lg py-5">
            <div className='row'>
                <div className='col-12'>
                    <div className='TokenListed'>
                        <div className='TokenListed__text'>
                            <h2 className='mb-5'>Team Members</h2>
                        </div>
                        <div className='team--group'>
                            {
                                dataList.map( ( item, index ) => (
                                    <div className='team--item' key={index}>
                                        <img
                                            className="img-fluid"
                                            src={item.image}
                                            alt={item.name} />
                                        <div>
                                            <h3>{item.name}</h3>
                                            <p>{item.designation}</p>
                                        </div>
                                    </div>
                                ) )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Team
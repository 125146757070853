import OwnershipWorkPic from '../../../assets/images/landing/ownership-work.png'

function OwnershipWork () {
    return (
        <div className="ownershipWork">
            <div className="container-lg">
                <div className="ownershipWork__con">
                    <div className="row no-gutters mt-2 mb-2 align-items-center">

                        <div className="col-md-6 col-12 order2">
                            <div className="text-md-left text-center ownershipWork__text">
                                <h2 className="content-subheader font-weight-bold mt-lg-4 mt-2 mb-4 alt-brand-blue">How does fractional ownership work?</h2>
                                <p>In fractional ownership, you own a share of the real estate itself and are issued a deed for the property, not a time that you can use the home. This keeps the costs lower than whole ownership, but you still have access to the home if you are satisfied with the sharing model</p>
                            </div>
                        </div>
                        <div className="col-md-6 col-12 order1">
                            <div className='ownershipWork__img'>
                                <img className="img-fluid" src={OwnershipWorkPic} alt="ownership-work" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default OwnershipWork;
import React from 'react'
import pricecalculatorPic from '../../../assets/images/staking-pic/price-calculator-pic-2.png'

const PriceCalculator = () => {
  return (
    <section className='price-calculator'>
      <div className='container'>
        <div className='price-calculator__row'>
          <div className='price-calculator__col'>
            <div className='price-calculator__title'>
              <h2>How is the Price calculated for the Property Share Token</h2>
            </div>
          </div>
          <div className='price-calculator__col'>
            <div className='price-calculator__img'>
              <img className="img-fluid" src={pricecalculatorPic} alt="pricecalculatorPic" />
            </div>
          </div>
        </div>  
      </div>

      </section>
  )
}

export default PriceCalculator
import React, { useState } from 'react'
import Step3 from './Step3';
import Step4 from './Step4';

const Registration = () => {

  const [activeStep, setActiveStep] = useState( 3 )

  return (
    <div id="user-registration-section_1-signup-step6" className="container-lg">
      <div className="row no-gutters">
        <div className="col-xl-1 col-md-12">
          <div className="user-registration-card-bg">
            <img alt="" className="p-4" src="/images/dots-variant-1.svg" />
          </div>
        </div>
        <div className="col-xl-5 col-lg-6 col-md-12 col-12">
          {activeStep === 3 && <Step3 setActiveStep={setActiveStep} />}
          {activeStep === 4 && <Step4 setActiveStep={setActiveStep} />}

        </div>
        <div className="col-lg-6 col-12 text-center text-lg-left">
          <div className="registration-artwork-top bg-dots-variant-1 bg-position-center-right-offset bg-repeat-no-repeat p-5">
            <h2>Take a break from</h2>
            <h2>financial work, and focus</h2>
            <h2>
              on{' '}
              <span className="font-weight-bold">
                what matters the most
              </span>
            </h2>
          </div>
          <div className="registration-artwork-bottom">
            <img
              alt="User Registration Artwork"
              className="img-fluid w-90"
              src="/images/User-Registration-Artwork.svg"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Registration

import React from 'react';
import {Player} from 'video-react';

export default ({data}) => {
//   console.log('propsprops', data.video);
  return (
    <Player
      playsInline
      poster="/assets/poster.png"
        src={"https://media.istockphoto.com/videos/architectural-drawing-changed-3d-house-interior-video-id643893980"}
    //   src={`${defaultOptions.baseUrl}/api${data.video}`}
    />
  );
};

import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Timer } from "../../helper/Timer";
import Web3 from "web3";
import getRpcUrl from "../../utils/getRpcUrl";
import { useWeb3React } from "@web3-react/core";
import { Link } from "react-router-dom";
import { loadAddressAndAllowance, getBalances, renderSwapButton } from "../../utils/functionhelper";
import WalletPopup from "../../components/Popup/Popup";
import WalletDetailsPopup from "../../components/WalletDetailsPopup/WalletDetailsPopup";
import PropertySlider from "./PropertySlider.jsx";
import { toast } from "react-toastify";
import BannerDetails from "./BannerDetails";
import trimAdd from "../../utils/TrimAddress";
import { getPropertyDetails } from "../../Redux/action/App";
import WhiteListFrom from "./WhiteListFrom";
import SideData from "./SideData";
import IsLoadingHOC from "../IsLoadingHOC";
import IsLoggedIn from "../IsLoggedIn";
import { useHistory } from "react-router-dom";

function Details ( { setLoading, token } ) {
  const [activeTab, setActiveTab] = useState( "description" );
  const history = useHistory()

  const [aqr, setaqr] = useState( "0" );
  const [usdt, setusdt] = useState( "1" );
  const [usdc, setusdc] = useState( "2" );
  const [matic, setmatic] = useState( "3" );
  const [wbtc, setwbtc] = useState( "4" );
  const [weth, setweth] = useState( "5" );

  const [aqrprice, setaqrprice] = useState();
  const [maticprice, setmaticprice] = useState();
  const [wbtcprice, setwbtcprice] = useState();
  const [wethprice, setwethprice] = useState();

  const [blockChainData, setblockChainData] = useState( {} );
  const [avalibleBalanceState, setAvalibleBalanceState] = useState( 0 );
  const [apidata, setapidata] = useState( {} );

  const [coinbalance, setcoinbalance] = useState( {
    aqrBalance: Number( 0 ),
    usdtBalance: Number( 0 ),
    usdcBalance: Number( 0 ),
    wbtcBalance: Number( 0 ),
    wethBalance: Number( 0 ),
    maticBalance: Number( 0 )
  } );

  const [option, setoption] = useState( 1 );
  const [amount, setamount] = useState( 0 );
  const [enteredamount, setenteredamount] = useState( 0 );
  const [vaultaddress, setvaultaddress] = useState( 0 );
  const [Allowance, setAllowance] = useState( {
    aqrAllowance: Number( 0 ),
    usdtAllowance: Number( 0 ),
    usdcAllowance: Number( 0 ),
    wbtcAllowance: Number( 0 ),
    wethAllowance: Number( 0 ),
  } );
  const [claimableBalance, setclaimablebalance] = useState( 0 );
  const [ref, setref] = useState( "" );
  const [approvalload, setApprovAlload] = useState( false )

  const [walletOpen, setWalletOpen] = useState( false );
  const closeModal = () => setWalletOpen( false );
  const [changePopupOpen, setChangePopupOpen] = useState( false );
  const closeModalChangePopup = () => setChangePopupOpen( false );

  const [userid, setuserid] = useState();
  const params = useParams();
  const location = useLocation();
  const web3 = new Web3( getRpcUrl() );
  const { account } = useWeb3React();
  const dispatch = useDispatch()
  const { host } = window.location;
  const { auth, app } = useSelector( ( state ) => state );

  const userfetch = async () => {
    setuserid( auth.user._id );
  };

  function numberWithCommas ( x ) {
    if ( x ) {
      return x.toString().replace( /\B(?=(\d{3})+(?!\d))/g, "," );
    } else return x;
  }

  const apicall = async () => {
    dispatch( getPropertyDetails( params.id ) )
      .then(
        response => {
          setapidata( response.data )
        },
        error => {
          console.log( error.response )
        }
      )
      .catch(
        error => console.log( error )
      )
    await axios
      .get(
        "https://min-api.cryptocompare.com/data/pricemulti?fsyms=MATIC,BTC,ETH,BNB,XTZ&tsyms=USD"
      )
      .then( ( res ) => {
        try {
          setmaticprice( res.data.MATIC.USD );
          setwbtcprice( res.data.BTC.USD );
          setwethprice( res.data.ETH.USD );
        } catch ( err ) {
          console.log( err, "error" );
        }
      } );
  };

  useEffect( () => {
    userfetch();
    apicall();
    if ( !app.referalAddress || app.referalAddress === "0x0000000000000000000000000000000000000000" ) {
      setReferalAddress()
    }
  }, [] );

  useEffect( () => {
    if ( !token ) {
      history.push( "/new-discover" );
    }
    loadData();
  }, [account] );

  const loadData = async () => {
    if ( account ) {
      getBalances( account ).then( ( res ) => {
        setcoinbalance( {
          aqrBalance: res.aqr,
          usdtBalance: res.usdt,
          usdcBalance: res.usdc,
          wbtcBalance: res.wbtc,
          wethBalance: res.weth,
          maticBalance: res.matic,
        } );
      } );

      loadAddressAndAllowance( params, account ).then( ( res ) => {
        console.log( res, "responce" )
        setblockChainData( res.total )
        setclaimablebalance( res.claimable )
        setAvalibleBalanceState( res.avaliblebalance )
        setvaultaddress( res.b )
        setAllowance( {
          aqrAllowance: res.allowance.aqrAllow,
          usdtAllowance: res.allowance.usdtAllow,
          usdcAllowance: res.allowance.usdcAllow,
          wbtcAllowance: res.allowance.wbtcAllow,
          wethAllowance: res.allowance.wethAllow,
        } )
      } );
    }
  };

  const handleChangeCurrency = async ( e ) => {
    console.log( e.target.value );
    setenteredamount( 0 );
    setamount( 0 );
    setoption( e.target.value );
  };
  const handleAmountchange = async ( e, option ) => {
    setamount(
      option == 1 || option == 2
        ? e.target.value * blockChainData.tokenprice
        : option == 3
          ? ( ( e.target.value * blockChainData.tokenprice ) / maticprice ).toFixed( 2 )
          : option == 4
            ? ( ( e.target.value * blockChainData.tokenprice ) / wbtcprice ).toFixed( 3 )
            : option == 5
              ? ( ( e.target.value * blockChainData.tokenprice ) / wethprice ).toFixed( 2 )
              : null
    );
    setenteredamount( e.target.value );
  };



  // Get User Referal Address from useLocation Hooks and Store in Redux State
  const setReferalAddress = async () => {
    const referalAddress = location.search.split( "=" )[1]
    const refAddressLocal = referalAddress ? referalAddress : '0x0000000000000000000000000000000000000000'
    dispatch( {
      type: "REFERAL_ADDRESS",
      payload: refAddressLocal
    } )
  }


  return (
    <>
      {walletOpen && (
        <WalletPopup
          walletOpen={walletOpen}
          setWalletOpen={true}
          closeModal={closeModal}
        />
      )}
      {changePopupOpen && (
        <WalletDetailsPopup
          setWalletOpen={setWalletOpen}
          changePopupOpen={changePopupOpen}
          setChangePopupOpen={setChangePopupOpen}
          closeModal={closeModalChangePopup}
        />
      )}
      <section id="property-detail-header">
        <div className="container-lg pt-2">
          <div className="row">
            <div className="col-lg-8 text-center text-lg-left">
              <div className="row">
                <div className="col-lg-8 text-center text-lg-left">
                  <p className="mt-3 mb-0">
                    Property ID:{" "}
                    <span className="font-weight-bold">
                      {apidata.property?.id}
                    </span>
                  </p>
                  <h1 className="font-weight-bold mb-3">{apidata.propertyTitle}</h1>
                </div>
                <div className="col-lg-4 text-center text-lg-right">
                  <div className="show-inline-container mt-md-4 mb-sm-4">
                    <div className="show-inline mt-2 mb-sm-2">
                      <a href="#" className="text-secondary">
                        <i
                          className="fa fa-3x fa-thumbs-o-up"
                          aria-hidden="true"
                        />
                      </a>
                    </div>
                    <div className="show-inline mt-2 mb-sm-2 ml-md-5">
                      <a href="#" className="text-secondary">
                        <i
                          className="fa fa-3x fa-bookmark-o"
                          aria-hidden="true"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 text-center text-lg-left">
                {/* <p className="mt-3 mb-0">Property ID: <span className="font-weight-bold">AQ96034</span></p>
                  <h1 className="font-weight-bold mb-3">Bejur Iftag Villa</h1> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="property-detail-hero">
        <div className="container-lg">
          <div className="row">
            <div className="col-lg-8 text-center">
              <div id="property-detail-hero-image" className="mb-4 mb-lg-0">
                <PropertySlider data={apidata} />
                <div id="property-detail-hero-image-badge">
                  <img
                    src="/images/aqarchain-verified.svg"
                    alt=""
                    className="mr-2"
                  />{" "}
                  Aqarverified
                </div>
              </div>
            </div>

            <div className="col-lg-4 text-center">
              <div id="property-details" className="mb-3">
                {
                  apidata.property?.property_status.toLowerCase() === "upcoming" ? (
                    <>
                      <SideData property={apidata} />
                      <hr
                        style={{
                          background:
                            "linear-gradient(89deg, #FBB042 0%, #FBB042 4%, #FDC010 39%, #FFDE11 100%)",
                          height: "2px",
                          border: "none",
                        }}
                      />
                      <WhiteListFrom id={apidata.id} property={apidata} setLoading={setLoading} />
                    </>
                  ) : (
                    <>
                      <div className="discover-card text-lg-left text-center">
                        <div className="card-body">
                          {account && (
                            <button
                              className="btn btn-gradient referralBtn"
                              onClick={() => {
                                navigator.clipboard.writeText( `${process.env.REACT_APP_URL}${location.pathname}?ref=${account}` );
                                toast.success( "URL Copied to Clipboard" )
                              }}
                            >Copy Referral link</button>
                          )}
                          <table
                            id="discover-table"
                            className="discover-table col-12"
                          >
                            <tbody>
                              <tr className="discover-table-border">
                                <td className="discover-table-left discover-card-content">
                                  <span>Token Name</span>
                                </td>
                                <td className="font-weight-bold discover-card-content">
                                  <span className="font-normal discover-table-left mr-2 ml-2">
                                    {" "}
                                    :{" "}
                                  </span>
                                  <span className="text-secondary">
                                    {blockChainData.tokensupply}&nbsp;
                                    {blockChainData.symbol}
                                  </span>
                                </td>
                              </tr>
                              <tr className="discover-table-border">
                                <td className="discover-table-left discover-token-value">
                                  <span>Per Share Price</span>
                                </td>
                                <td className="font-weight-bold">
                                  <span className="font-normal discover-table-left mr-2 ml-2">
                                    {" "}
                                    :{" "}
                                  </span>
                                  <span className="text-secondary">
                                    {`${blockChainData.tokenprice ? Number( blockChainData.tokenprice ).toLocaleString( "en-US", { style: "currency", currency: "USD" } ) : ""}`}
                                  </span>
                                  <br />
                                </td>
                              </tr>
                              <tr className="discover-table-border">
                                <td className="discover-table-left discover-card-content">
                                  <span>Gross Yield </span>
                                </td>
                                <td className="font-weight-bold">
                                  <span className="font-normal discover-table-left mr-2 ml-2">
                                    {" "}
                                    :{" "}
                                  </span>
                                  <span className="text-secondary">
                                    {`${apidata.AnnualRental ? Number( apidata.AnnualRental.$numberDecimal ).toLocaleString( "en-US", { style: "currency", currency: "USD" } ) : ""}`}
                                  </span>
                                </td>
                              </tr>
                              <tr className="discover-table-border discover-table-border-down">
                                <td className="discover-table-left discover-card-content">
                                  <span>Guaranteed Annual ROI</span>
                                </td>
                                <td className="font-weight-bold">
                                  <span className="font-normal discover-table-left mr-2 ml-2">
                                    :
                                  </span>{" "}
                                  {apidata.ROI?.$numberDecimal} %
                                </td>
                              </tr>
                              <tr className="discover-table-border discover-table-border-down">
                                <td className="discover-table-left discover-card-content">
                                  <span>Claimable Balance</span>
                                </td>
                                <td className="font-weight-bold">
                                  <span className="font-normal discover-table-left mr-2 ml-2">
                                    :
                                  </span>{" "}
                                  <span className="text-secondary">
                                    {claimableBalance}
                                  </span>
                                </td>
                              </tr>
                            </tbody>
                          </table>

                          {blockChainData.endtime > 0 && (
                            <div className="col-12 mt-4 text-center d-flex justify-content-center">
                              <div className="property-clock-wrap">
                                <div className="text-secondary font-bold big property-clock">
                                  <Timer unixtime={blockChainData.endtime} />
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div id="property-details-buy-out-fractions" className="mt-4">
                        <hr
                          style={{
                            background:
                              "linear-gradient(89deg, #FBB042 0%, #FBB042 4%, #FDC010 39%, #FFDE11 100%)",
                            height: "2px",
                            border: "none",
                          }}
                        />

                        <div className="tab-content row mt-2 mb-2 p-4">
                          <div id="fractions" className="col-12 tab-pane fade in active show" style={{ padding: "0" }}>
                            <div className="referalBox">
                              {
                                !app.referalAddress || app.referalAddress === "0x0000000000000000000000000000000000000000"
                                  ? (
                                    <p>No Referal Address</p>
                                  )
                                  : (
                                    <>
                                      <p style={{ marginBottom: "0.35rem" }}>Refered Address</p>
                                      <h4 style={{ margin: 0 }}>{trimAdd( app.referalAddress, 12 )}</h4>
                                    </>
                                  )
                              }
                            </div>
                            <div className=" d-flex align-items-center justify-content-between mt-2 mb-3">
                              <div className=" text-left font-normal">
                                <span>Buy Tokens</span>
                              </div>
                              <div className=" text-right uc text-secondary font-semibold">
                                <span>
                                  BALANCE:{" "}
                                  {option == 0
                                    ? ( coinbalance.aqrBalance + " " + "AQR" )
                                    : option == 1
                                      ? ( coinbalance.usdtBalance + " " + "USDT" )
                                      : option == 2
                                        ? ( coinbalance.usdcBalance + " " + "USDC" )
                                        : option == 3
                                          ? ( coinbalance.maticBalance + " " + "MATIC" )
                                          : option == 4
                                            ? ( coinbalance.wbtcBalance + " " + "WBTC" )
                                            : option == 5
                                              ? ( coinbalance.wethBalance + " " + "WETH" )
                                              : null}
                                </span>
                              </div>
                            </div>
                            <div className="property-details-buy-out-fractions mb-3 row d-flex align-items-center">
                              <div className="col-8 text-left">
                                <input
                                  style={{
                                    border: "none",
                                    width: "100%",
                                    outline: "none",
                                    padding: "1rem 15px",
                                  }}
                                  className="big text-secondary font-bold mb-0"
                                  min="1"
                                  step="1"
                                  pattern="[0-9]"
                                  type="number"
                                  onChange={( e ) => handleAmountchange( e, option )}
                                  value={enteredamount}
                                />
                              </div>
                              <div className="col-4 p-3 text-right">
                                <select
                                  id="ms-dropdown-selection"
                                  className="form-control secondary-select"
                                  style={{ backgroundColor: "rgb(255,222,17,0.1)" }}

                                >
                                  <option
                                    data-image="/images/crypto-icons/BTC.svg"
                                    value="BTC"
                                  >
                                    {blockChainData.symbol}
                                  </option>
                                </select>
                              </div>
                            </div>


                            <div className="property-details-buy-out-fractions row d-flex align-items-center">
                              <div className="col-8 text-left">
                                <input
                                  style={{
                                    border: "none",
                                    width: "100%",
                                    outline: "none",
                                    padding: "1rem 15px",
                                  }}
                                  className="big text-secondary font-bold mb-0"
                                  type="number"
                                  value={amount}
                                  disabled
                                />
                              </div>
                              <div className="col-4 p-3 text-right">
                                <select
                                  id="ms-dropdown-selection"
                                  className="form-control secondary-select"
                                  style={{ backgroundColor: "rgb(255,222,17,0.1)" }}
                                  onChange={( e ) => {
                                    handleChangeCurrency( e );
                                  }}
                                >
                                  <option
                                    data-image="https://flagcdn.com/72x54/us.png"
                                    disabled
                                  >
                                    AQRtz {` Coming soon`}
                                  </option>
                                  <option
                                    data-image="https://flagcdn.com/72x54/us.png"
                                    value={aqr}
                                    disabled
                                  >
                                    AQR {` Coming soon`}
                                  </option>
                                  <option
                                    data-image="https://flagcdn.com/72x54/us.png"
                                    value={usdt}
                                    defaultChecked
                                  >
                                    USDT
                                  </option>
                                  <option
                                    data-image="https://flagcdn.com/72x54/us.png"
                                    value={usdc}
                                  >
                                    USDC
                                  </option>
                                  <option
                                    data-image="https://flagcdn.com/72x54/us.png"
                                    value={matic}
                                  >
                                    MATIC
                                  </option>
                                  <option
                                    data-image="https://flagcdn.com/72x54/ca.png"
                                    value={wbtc}
                                  >
                                    WBTC
                                  </option>
                                  <option
                                    data-image="https://flagcdn.com/72x54/us.png"
                                    value={weth}
                                  >
                                    WETH
                                  </option>
                                </select>
                              </div>
                            </div>
                            {option == 0 ? (
                              <div className="row d-flex align-items-center mt-2 mb-2">
                                <div className="col-4 text-left font-normal">
                                  <span>Discount Applied</span>
                                </div>
                                <div className="col-2 text-right uc text-secondary font-semibold">
                                  <span>$0.00</span>
                                </div>
                              </div>
                            ) : null}
                            <div className="row d-flex align-items-center mt-5">
                              <div className="col text-center">
                                {userid ? (
                                  renderSwapButton( account, option, Allowance, vaultaddress, userid, enteredamount, app.referalAddress, approvalload, setApprovAlload )
                                ) : (
                                  <Link to="/login">
                                    <button
                                      className="btn btn-gradient w-100"
                                      type="button"
                                    >
                                      Log in
                                    </button>
                                  </Link>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )
                }

              </div>
            </div>
          </div>
        </div>
      </section >
      <BannerDetails apidata={apidata} />
      <section id="property-detail-content">
        <div id="property-detail-tab-links-wrapper">
          <div id="property-detail-tab-links" className="container-lg">
            <div className="row">
              <div className="col-xl-8">
                <ul
                  className="nav nav-tabs nav-tabs nav-justified md-tabs property-detail-nav"
                  id="property-detail-tabs"
                >
                  <li className="nav-item mx-md-1 m-2">
                    <a
                      className={`nav-link ${activeTab === 'description' ? 'active' : ''}`}
                      id="property-detail-tab-description-tab"
                      onClick={() => setActiveTab( "description" )}
                    >
                      Description
                    </a>
                  </li>
                  <li className="nav-item mx-md-1 m-2">
                    <a
                      className={`nav-link ${activeTab === "amenities" ? "active" : ""}`}
                      id="property-detail-tab-amenities-tab"
                      onClick={() => setActiveTab( "amenities" )}
                    >
                      Amenities
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="container-lg">
          <div className="row">
            <div className="col-xl-8">
              <div
                className="tab-content mt-3 mb-3"
                id="property-detail-tabs-content"
              >
                {
                  activeTab === 'description' && (
                    <div className="my-5" id="property-detail-tab-description">
                      <div className="text-center text-lg-left p-4 p-lg-5 has-box-shadow">
                        <h2 className="font-weight-bold mb-4">Description</h2>
                        <figure>
                          <p className="mb-0">
                          </p>
                        </figure>
                        {apidata.property?.description.split( "\n" ).map( ( item, index ) => (
                          <p className="mb-3" key={index}>
                            {item}
                          </p>
                        ) )}

                      </div>
                    </div>
                  )
                }
                {
                  activeTab === 'amenities' && (
                    <div className="my-5" id="property-detail-tab-amenities">
                      <div className="text-center text-lg-left p-4 p-lg-5 has-box-shadow ">
                        <h2 className="font-weight-bold mb-4">Amenities</h2>
                        <div id="property-detail-amenities" className="text-center d-block d-lg-flex justify-content-around">
                          {
                            apidata.property.amenities.map( ( amenity, index ) => (
                              <div className="property-detail-amenity" key={index}>
                                <div className="amenity--img">
                                  <img src={`https://token-api.aqarchain.io/${amenity.image}`} alt="Elevator" />
                                </div>
                                <p className="mb-3 mt-3 mb-lg-0 font-weight-bold">{amenity.feature}</p>
                              </div>
                            ) )
                          }
                        </div>
                      </div>
                    </div>
                  )
                }
              </div>
            </div>

          </div>
        </div>
      </section>
    </>
  );
}

export default IsLoadingHOC( IsLoggedIn( Details ) );

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getUserProfile, updateUserProfile } from '../../../Redux/action/Auth'
import Select from 'react-select'
// import { optionsCountry } from '../../../Config/constant';
import { getAllCuntry } from '../../../Redux/action/App';
import IsLoadingHOC from '../../IsLoadingHOC';
import { toast } from 'react-toastify';
import IsLoggedIn from '../../IsLoggedIn';


const Profile = (props) => {

  const { setLoading } = props
  const dispatch = useDispatch()
  const { auth, app } = useSelector(state => state)

  const [editView, setEditView] = useState(true)

  const [values, setValues] = useState({
    first_name: auth.user.first_name,
    last_name: auth.user.last_name,
    mobile: auth.user.mobile,
    email: auth.user.email,
    country: auth.user.country,
    state: auth.user.state,
    city: auth.user.city,
    country_code: auth.user.country_code
  })

  const { first_name, last_name, mobile, email, country, state, city, country_code } = values

  useEffect(() => {
    dispatch(getUserProfile())
    dispatch(getAllCuntry());
  },[])


  const handleProfileUpdate = async () => {
    setLoading(true)
    await dispatch(updateUserProfile(
      {
        first_name,
        last_name,
        country,
        state,
        city,
      }
    ))
      .then(
        response => {
          setLoading(false)
          toast.success(response.message)
          setEditView(!editView)
        },
        error => {
          setLoading(false)
        }
      )
      .catch(
        error => console.log(error)
      )
  }



  return (
    <div
      className={`tab-pane show active`}
      id="company-nav-tab-my-profile"
      role="tabpanel"
      aria-labelledby="company-nav-tab-my-profile-tab">
        {/* <IsLoggedIn/> */}
      <div className="text-lg-left p-4 p-lg-5 has-box-shadow has-border-radius">
        <div className="row d-lg-flex align-items-center">
          <div className="col-lg-6 col-12">
            <h3 className="mb-3 mb-lg-3 font-weight-bold text-lg-left text-center">
              My Profile
            </h3>
          </div>
          <div className="col-lg-6 col-12 text-right">
            <button
              className={`edit-button btn btn-sm btn-gradient-secondary ${!editView ? "d-none" : ""}`}
              id="edit-profile-btn"
              onClick={() => setEditView(!editView)}>
              <span>Edit</span>
              <img alt="" src="public/images/icon-edit.svg" />
            </button>
          </div>
        </div>

        <div className="row my-4">
          <div className="col-lg-6 col-12">
            <div className="form-group">
              <label htmlFor="first-name">First Name</label>
              <input
                onChange={e => setValues({ ...values, first_name: e.target.value })}
                value={first_name}
                type="text"
                className="form-control secondary-input"
                id="first-name"
                placeholder="Enter First Name"
                disabled={editView}
              />
            </div>
          </div>
          <div className="col-lg-6 col-12">
            <div className="form-group">
              <label htmlFor="last-name">Last Name</label>
              <input

                onChange={e => setValues({ ...values, last_name: e.target.value })}
                value={last_name}
                type="text"
                className="form-control secondary-input"
                id="last-name"
                placeholder="Enter Last Name"
                disabled={editView}
              />
            </div>
          </div>
        </div>
        <div className="row my-4">
          <div className="col-lg-6 col-12">
            <label>Mobile Number</label>
            <div className="form-group form-inline">
              <select
                disabled
                className="form-control onProfilePage secondary-select mr-lg-2"
                id="mobile-no-prepend" value={`+${country_code}`}>
                <option >+{country_code}</option>
              </select>
              <input
                value={mobile}
                readOnly
                type="text"
                className="form-control secondary-input"
                id="mobile-no"
                placeholder="Enter Mobile Number"
              />
            </div>
          </div>
          <div className="col-lg-6 col-12">
            <div className="form-group">
              <label htmlFor="email-id">Email ID</label>
              <input
                value={email}
                readOnly
                type="email"
                className="form-control secondary-input"
                id="email-id"
                placeholder="Enter Email ID"
              />
            </div>
          </div>
        </div>
        <div className="row my-3">
          <div className="col-lg-6 col-12">
            <div className="form-group">
              <label htmlFor="country">Country</label>
              <Select
                isDisabled={editView}
                defaultValue={{ label: country }}
                options={app.cuntryData}
                onChange={e => {
                  setValues({ ...values, country: e.label })
                }}
              />
            </div>
          </div>
          {/* <div className="col-lg-6 col-12">
            <div className="form-group">
              <label htmlFor="state">State</label>
              <Select
                isDisabled={editView}
                defaultValue={{ label: state }}
                options={app.stateData}
                onChange={e => {
                  dispatch( getCityData( { stateId: e.value } ) );
                  setValues( { ...values, state: e.label } )
                }}
              />
            </div>
          </div> */}
        </div>
        <div className="row my-3">
          <div className="col-lg-6 col-12">
            <div className="form-group">
              <label htmlFor="city">City</label>
              {/* <Select
                isDisabled={editView}
                defaultValue={{ label: city }}
                options={app.cityData}
                onChange={e => {
                  setValues({ ...values, city: e.label })
                }}
              /> */}
              <input
                type="text"
                className="form-control secondary-input"
                id="city"
                placeholder="Enter City"
                value={city}
                disabled={editView}
                onChange={e => {
                  setValues({ ...values, city: e.label })
                }}
              />
            </div>
          </div>
        </div>
        <div className="row my-3">
          <div className="col-lg-4 col-md-6 col-12">
            <button
              className={`btn btn-gradient-secondary ${editView ? 'd-none' : ''
                } w-100`}
              id="save-profile-btn"
              onClick={handleProfileUpdate}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default IsLoadingHOC(Profile)

import React from 'react'

const Pagination = ( props ) => {
    const { NFTs, pagination, setPagination } = props
    return (
        NFTs.length > 0 && (
            <div className="pagination">
                <ul className="pagination--group ">
                    <li className={`pagination--item ${pagination.activePage === 1 ? "disabled" : ""}`}
                        onClick={() => setPagination( {
                            ...pagination,
                            activePage: pagination.activePage === 1 ? 1 : pagination.activePage - 1
                        } )}>Previous</li>

                    {
                        [...Array( pagination.totalPageCount )].map( ( _, index ) => (
                            <li className={`pagination--item ${pagination.activePage === index + 1 ? "active" : ""}`}
                                onClick={() => setPagination( { ...pagination, activePage: index + 1 } )}
                                key={index}
                            >{index + 1}</li>
                        ) )
                    }
                    <li
                        className={`pagination--item ${pagination.activePage === pagination.totalPageCount ? "disabled" : ""}`}
                        onClick={() => setPagination( {
                            ...pagination,
                            activePage: pagination.activePage === pagination.totalPageCount ? 1 : pagination.activePage + 1
                        } )}>Next</li>
                </ul>
            </div >
        )
    )
}

export default Pagination
import modal from "../../../assets/modal/bamboo_tree.glb"

const ModelViewer = ( { poster, modal } ) => {
    return (
        <model-viewer
            style={{ width: "100%", height: "100%", backgroundColor: "#272724" }}
            camera-controls alt="A 3D model of an astronaut"
            ar
            ios-
            auto-rotate
            enable-pan
            shadow-intensity="1"
            ar-modes="webxr scene-viewer quick-look"
            poster={poster}
            src={modal}
        ></model-viewer>
    )

}

export default ModelViewer
import React from 'react';

const Buy = () => {
	return (
		<div
			class='tab-pane fade active show'
			id='user-dashboard-nav-tab-buy'
			role='tabpanel'
			aria-labelledby='user-dashboard-nav-tab-buy-tab'
		>
			<div class='text-center text-lg-left p-4 p-lg-5 has-box-shadow has-border-radius'>
				<div class='row d-lg-flex align-items-center'>
					<div class='col-lg-4 col-12'>
						<h3 class='font-weight-bold mb-3 mb-lg-4'>Buy AQR</h3>
					</div>
				</div>
				<div className='row'>
					<div className='col-lg-8 col-12'>
						<div className='form-group'>
							<label htmlFor='amount'>
								Enter the amount you need to purchase.
							</label>
							<input
								type='text'
								className='form-control secondary-input'
								id='amount'
								placeholder='Enter the amount'
								aria-describedby='amountHelp1'
							/>
							<small
								id='amountHelp1'
								class='form-text text-muted'
							>
								*Minimum purchase amount should be greater than
								USD 100.00 (1 AQR = USD 0.25)
							</small>
						</div>
					</div>
				</div>
				<div className='row my-3'>
					<div className='col-lg-4 col-md-6 col-12'>
						<button
							className='btn btn-gradient-secondary'
							id='buy-btn'
						>
							Buy Now
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Buy;

import World from '../../../assets/images/landing/World_s First Decentralized.png'

function RealEstate () {
    return (
        <section className="container-lg seection--world">
            <div className="world">
                <div className="row no-gutters mt-2 mb-2 align-items-center">
                    <div className="col-md-6 col-12">
                        <div className='world__img'>
                            <img className="img-fluid" src={World} alt="World" width="100%" />
                        </div>
                    </div>
                    <div className="col-md-6 col-12">
                        <div className="text-md-left text-center world__text">
                            <h2 className="content-subheader font-weight-bold mt-lg-4 mt-2 mb-4 alt-brand-blue">World’s First Decentralized Real EstateNFT Marketplace with Metaverse</h2>
                            <p>Aqarchain is the pioneer in Fractionalizing NFT of real world real estate assets. We have also revolutionized this by making a digital asset of the real world real estate asset. We at aqar chain have focussed in providing a seamless experience in transactions in a pure digital format by eliminating all middlemen through this platform.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}


export default RealEstate;
import React, { useState, useCallback } from "react";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
const PropertySlider = ( { data } ) => {

    const [currentImage, setCurrentImage] = useState( 0 );
    const [viewerIsOpen, setViewerIsOpen] = useState( false );

    const openLightbox = useCallback( ( event, { photo, index } ) => {
        setCurrentImage( index );
        setViewerIsOpen( true );
    }, [] );

    const closeLightbox = () => {
        setCurrentImage( 0 );
        setViewerIsOpen( false );
    };


    const photos = [
        { src: `https://token-api.aqarchain.io/${data.property?.cover}` }
    ]; ( () => {
        if ( data.property ) {
            data.property.floorPlans.map( ( item, _ ) => {
                photos.push( { src: `https://token-api.aqarchain.io/${item}`, width: "0", height: "0" } )
            } )
        }
    } )()





    return (
        <>
            <Gallery photos={photos} onClick={openLightbox} />
            <ModalGateway>
                {viewerIsOpen ? (
                    <Modal onClose={closeLightbox}>
                        <Carousel
                            currentIndex={currentImage}
                            views={photos.map( x => ( {
                                ...x,
                                srcset: x.srcSet,
                                caption: x.title
                            } ) )}
                        />
                    </Modal>
                ) : null}
            </ModalGateway>
        </>
    );
};

export default PropertySlider;

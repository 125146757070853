import React from 'react'
import Header from '../Public/Header'
import Sidebar from './SideBar'



const PrivateLayout = ({children}) => {
    return (
        <>
            <Header />
            <main className="wrapper container-lg">
                <Sidebar />
                <div id="content" className="flex-grow-1">
                    <div id="page-content" className="py-lg-5 pl-lg-5 p-md-2 p-3">
                    <div className="row no-gutters">
                        <div className="col-lg-12">
                        <div className="tab-content dashboard-nav-tabs-content" id="company-nav-tabs-content">
                            <div
                                className="tab-pane"
                                id="company-nav-tab-dashboard"
                                role="tabpanel"
                                aria-labelledby="company-nav-tab-dashboard-tab">
                            </div>
                            {children}
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
            </main>
        </>
    )
}

export default PrivateLayout

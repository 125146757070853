import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import Login from '../Login'
import ResendVerifyLink from './resendLink'
import IsLoadingHOC from '../IsLoadingHOC'
import { toast } from 'react-toastify'
import { verifyEmailToken } from '../../Redux/action/Auth'
import { useDispatch } from 'react-redux'
import { Redirect } from "react-router-dom"

const EmailVerify = ( props ) => {
    const history = useHistory();
    const { setLoading } = props;
    const [verifying, setVerifying] = useState( true )
    const params = useParams()
    const dispatch = useDispatch()
    

    useEffect( () => {
        setLoading( true )
        dispatch( verifyEmailToken( { token: params.token } ) ).then( ( res ) => {
            setVerifying( true );
            setLoading( false );
            toast.success( "Email verified" );
            history.push( "/dashboard/profile" )
        }, ( error ) => {
            setLoading( false )
            setVerifying( false )
            if ( Array.isArray( error.response.data.message ) ) {
                error.response.data.message.map( item => toast.error( item ) )
            } else {
                toast.error( error.response.data.message )
            }
            history.push("/registration/resend/email")
        } )
    }, [params.token] )

    return verifying ? <Login /> : <Redirect to="/registration/resend/email" />;
}

export default IsLoadingHOC( EmailVerify );

import Web3 from "web3";

const getInWei = (value) => {
  const web3 = new Web3(window.ethereum);

  const valueInString = value.toString();
  const valueInWei = web3.utils.toWei(valueInString, "ether");
  return valueInWei;
};

export default getInWei;

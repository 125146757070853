function TokenListed () {
    const dataList = [
        {
            image: require( "../../../assets/images/landing/Dex-tool.png" ).default,
            url: "https://www.dextools.io/app/polygon/pair-explorer/0xbddcf113703b8a20f8ce783eaae7fb421bd6ae9e",
            name: "Dex Tools",
        },
        {
            image: require( "../../../assets/images/landing/Quickswap.png" ).default,
            url: "https://info.quickswap.exchange/#/token/0xae204ee82e60829a5850fe291c10bf657af1cf02",
            name: "Quickswap Charts",
        },
        {
            image: require( "../../../assets/images/landing/Coinmarketcap.png" ).default,
            url: "https://coinmarketcap.com/currencies/aqar-chain/",
            name: "CoinMarketCap",
        },
        {
            image: require( "../../../assets/images/landing/Coingecko.png" ).default,
            url: "https://www.coingecko.com/en/coins/aqar-chain",
            name: "Coingecko",
        },
    ]
    return (
        <div className="container-lg">
            <div className='row'>
                <div className='col-12'>
                    <div className='TokenListed'>
                        <div className='TokenListed__text'>
                            <h2>AQR Token is now listed on</h2>
                        </div>
                        <div className='TokenListed__list'>
                            {
                                dataList.map( ( item, index ) => (
                                    <div className='TokenListed__item' key={index}>
                                        <img
                                            className="img-fluid"
                                            src={item.image}
                                            alt={item.name} width="50px" />
                                        <a href={item.url} target="_blank">View Now</a>
                                    </div>
                                ) )
                            }

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default TokenListed;
import React from "react";

const Advisor = () => {
  const dataList = [
    {
      name: "Maria Shmatova",
      designation: "Regulatory & Compliance Advisor",
      image: require("../../assets/images/advisor/01-Maria-Shmatova.png")
        .default,
    },
    {
      name: "Saeed Al Darmaki",
      designation: "Advisor",
      image: require("../../assets/images/advisor/02-Saeed-Al-Darmaki.png")
        .default,
    },
    {
      name: "Engg - Mansour AlObaid",
      designation: "Strategic Advisor",
      image:
        require("../../assets/images/advisor/03-Engg---Mansour-AlObaid.png")
          .default,
    },
    {
      name: "Gaurav Dubey",
      designation: "Advisor",
      image: require("../../assets/images/advisor/04-Gaurav-Dubey.png").default,
    },
    {
      name: "Shafique Ibrahim",
      designation: "Advisor",
      image: require("../../assets/images/advisor/05-Shafique-Ibrahim.png")
        .default,
    },
    // {
    //     name: "Joeri Vaangeelan",
    //     designation: "Advisor",
    //     image: require( "../../assets/images/advisor/06-Joeri-Vaangeelan.png" ).default
    // },
    {
      name: "Mohammed Mahfoudh",
      designation: "Advisor",
      image: require("../../assets/images/advisor/07-Mohammed-Mahfoudh.png")
        .default,
    },
    {
      name: "Dr. Mohamed Al Hemairy",
      designation: "Advisor",
      image: require("../../assets/images/advisor/Dr.-Mohamed-Al-Hemairy.png")
        .default,
    },
    {
      name: "Dr. Majid Mansouri",
      designation: "Advisor",
      image: require("../../assets/images/advisor/Dr.-Majid-Mansouri.png")
        .default,
    },
    {
      name: "Mohammed Ramdani",
      designation: "Advisor",
      image: require("../../assets/images/advisor/Mohammed-Ramdani.png")
        .default,
    },
  ];

  return (
    <div className="container-lg py-5">
      <div className="row">
        <div className="col-12">
          <div className="TokenListed">
            <div className="TokenListed__text">
              <h2 className="mb-5">Advisiors</h2>
            </div>
            <div className="advisor--group">
              {dataList.map((item, index) => (
                <div className="advisor--item" key={index}>
                  <img className="img-fluid" src={item.image} alt={item.name} />
                  <h3>{item.name}</h3>
                  <p>{item.designation}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Advisor;

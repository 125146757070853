import React from 'react'
import Animation3 from './Animation3'

const ShareProperty = () => {
  return (
      <>
        <div className="share-property">
        <div className="container-lg">
          <div className="share-property__row">
            <div className="share-property__col order-col">
              <div className="claim__content">
                <h1 >Property Share token can be claimed from the users dashboard</h1>
                <p>After the completion of the crowdsale of the property, the Property Share token can be claimed from the users dashboard and it will be available in the metamask wallet. This token can now be Staked on the Platform and earn Rewards in AQR at the same rate at which the real world property earns.</p>
                <a className="btn btn-gradient btn-rounded semi-small">Login / Signup Now</a>
              </div>
            </div>
            <div className="share-property__col">
              <div className="real__video-content">
                <div className="polygon-box">
                  <Animation3 />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </>
  )
}

export default ShareProperty
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import OwnerMenu from './OwnerMenu';

const Sidebar = () => {
  const dispatch = useDispatch();
  let history = useHistory();
  let user = useSelector( state => state.auth.user );
  
  const logout = () => {
    dispatch({type: 'LOGOUT'});
    history.push('/');
  };
  return (
    <nav id="sidebar">
      <div className="sidebar-top text-center">
        <div className="user-details-card has-border-radius p-4">
          <div className="user-profile-pic-section">
           {!user.profile ? (
              <img
                className="user-profile-pic"
                src="https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png"
                alt={`${user.first_name} avatar`}
              />
            ) : (
              <img
                className="user-profile-pic"
                src={`https://api.aqarchain.com/api${user.profile}`}
                alt={`${user.first_name} avatar`}
              />
            )}
          </div>
          <div className="user-details mt-2">
            <p className="mb-0 font-weight-bold text-capitalize">
              {`${user.first_name} ${user.last_name}`}
            </p>
          </div>
        </div>
      </div>
      <ul
        className="nav nav-tabs nav-tabs dashboard-nav-tabs flex-column has-border-radius mt-5 mb-5"
        id="company-nav-tabs"
        role="tablist">
        <OwnerMenu />
      </ul>
      <ul className="nav nav-tabs dashboard-nav-tabs has-border-radius flex-column">
        <li className="nav-item">
          <div onClick={logout} className="nav-link" id="logout">
            Log Out
          </div>
        </li>
      </ul>
    </nav>
  );
};

export default Sidebar;

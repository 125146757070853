import React from 'react'
import { useLocation } from 'react-router-dom';
import Header from '../Public/Header';
import AqarlandSideBar from './Sidebar/index';

const AqarlandLayout = ( { children } ) => {

    const location = useLocation()


    return (
        <div className='aqraland--wrapper'>
            <Header />
            <div className="body">
                <AqarlandSideBar />
                {
                    location.pathname === "/aqarland/map" || location.pathname === "/aqarland/collabLand" ? (
                        children
                    ) : (
                        <div className="main">
                            <div className="container">{children}</div>
                        </div>
                    )
                }

            </div>
        </div>
    )
}

export default AqarlandLayout

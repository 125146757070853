import React from "react";
import { toast } from "react-toastify";
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { Formik } from "formik";
import { login } from "../../Redux/action/Auth";
import IsLoadingHOC from '../IsLoadingHOC';



const Login = ( props ) => {

  const { setLoading } = props;
  const { login } = props;
  const history = useHistory()
  return (
    <>
      <Formik
        initialValues={{ email: '', password: '' }}
        onSubmit={async ( values ) => {
          setLoading( true )
          await login( values )
            .then(
              response => {
                toast.success( response.message );
                history.goBack()
                setLoading( false )
              },
              error => {
                setLoading( false )
                toast.error( error.response.data.message )
              }
            )
            .catch( error => {
              setLoading( false )
              console.log( error );
            } )
        }}
      >

        {( {
          values,
          handleChange,
          handleSubmit,
        } ) => (

          <form className="my-4" onSubmit={handleSubmit}>
            <div className="form-group">
              <input
                type="text"
                name="email"
                value={values.email}
                onChange={handleChange}
                className="form-control secondary-input"
                id="email-address"
                placeholder="Email ID"
              />
            </div>
            <div className="form-group">
              <input
                type="password"
                name="password"
                value={values.password}
                onChange={handleChange}
                className="form-control secondary-input"
                id="password2"
                placeholder="Enter Password"
              />
            </div>
            <div>
              <span className="form-text text-muted text-center font-weight-bold text-dark float-right">
                <Link to={'/forgot-password'}>Forgot Password?</Link>
              </span>
            </div>
            <button
              type="submit"
              id="signup-button"
              className="btn btn-gradient-secondary w-100 my-6"
            >
              Login
            </button>
          </form>

        )}

      </Formik>


    </>
  );
};

export default connect( null, { login } )( IsLoadingHOC( Login ) );



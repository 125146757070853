
import ownershipPic from '../../../assets/images/landing/ownership-pic.svg'

function Ownership () {
    return (
        <div className="Ownership">
            <div className="container-lg">
                <div className="Ownership__con">
                    <div className="row no-gutters mt-2 mb-2 align-items-center">
                        <div className="col-md-6 col-12 Ownership__bg-shep">
                            <div className='Ownership__img'>
                                <img className="img-fluid" src={ownershipPic} alt="ownershipPic" />
                            </div>
                        </div>
                        <div className="col-md-6 col-12">
                            <div className="text-md-left text-center Ownership__text">
                                <h2 className="content-subheader font-weight-bold mt-lg-4 mt-2 mb-4 alt-brand-blue">What Is Fractional Ownership</h2>
                                <p>
                                    Fractional ownership is a method in which several unrelated
                                    parties can share in, and mitigate the risk of, ownership
                                    of a high-value tangible asset, usually a jet, yacht or
                                    piece of resort real estate. It can be done for strictly
                                    monetary reasons, but typically there is some amount of
                                    personal access involved.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default Ownership;
import React from "react";
import Loader from "react-loader-spinner";
const LoadingSpiner = (props) => {
	return (
        <div className="loaderHolder" style={{
            position: "fixed",
            width: "100%",
            height: "100vh",
            top: 0,
            left: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "#00000061",
            zIndex: 999,
        }}>
			<div className="loaderMain">
				<Loader
					type="Circles"
					color="#f9cc38"
					height={100}
					width={100}
				/>
				{/* <div>{props.message}</div> */}
			</div>
		</div>
	);
};
export default LoadingSpiner;

import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isExpired } from "react-jwt";

export const IsLoggedIn = ( WrappedComponent ) => {
    const HOC = props => {
        const { auth } = useSelector( state => state )
        const dispatch = useDispatch();
        if ( auth.token && isExpired( auth.token ) ) {
            dispatch( { type: "LOGOUT" } );
        }
        return (
            <WrappedComponent {...props} token={auth.token} />
        );
    }
    return HOC;
}

export default IsLoggedIn

import React from 'react'
import sharetokenPic from '../../../assets/images/staking-pic/share-token-pic.svg'


const ShareToken = () => {
  return (
    <section className='share-token'>
      <div className='container'>
        <div className='share-token__row'>
          <div className='share-token__col order2'>
            <div className='share-token__content'>
              <div className='share-token__title'>
                  <h2>Staking of Property Share Token</h2>
              </div>
              <div className='share-token__dec'>
                <p>
                  After the completion of the crowdsale of the property,
                  the Property Share token can be claimed from the users
                  dashboard and it will be available in the metamask wallet.
                  This token can now be Staked on the Platform and earn
                  Rewards in AQR at the same rate at which the real world
                  property earns.
              </p>
              <p>
                  For example if the ROI on the property
                  is 6% per annum then the staking rewards will also be
                  6% per Annum.
              </p>
              <p>
                  So stay tuned as the property share tokens
                  are released the staking will be Live and available
                  here.
              </p>
              </div>
              <button className='btn btn-secondary btn-rounded'>Book your Property Share tokens</button>

            </div>
          </div>
          <div className='share-token__col order1'>
            <div className='share-token__img'>
              <img className="img-fluid" src={sharetokenPic} alt="sharetokenPic" />
            </div>
          </div>
        </div>
      </div>   
    </section>
    
  )
}

export default ShareToken
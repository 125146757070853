import React from 'react'
import AQRpic from "../../../assets/images/login/AQR.svg";
import Animation2 from './Animation2';
const ClaimProperty = () => {
  return (
      <>
          <div className="claim-property">
        <div className="container-lg">
          <div className="claim-property__row">
            <div className="claim-property__col">
              <div className="real__video-content">
                <div className="polygon-box">
                  <img src={AQRpic} alt="AQRpic" width="150px" />
                  <Animation2 />
                </div>
              </div>
            </div>
            <div className="claim-property__col">
              <div className="claim__content">
                <h1 >Claim AQR & Property Share Tokens </h1>
                <p>On Aqar chain Platform the users will be able to stake not only the native utility token of the platform $AQR, but also the property share token which represents the fractional share in the property.</p>
                <a className="btn btn-gradient btn-rounded semi-small">Login / Signup Now</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      </>
  )
}

export default ClaimProperty
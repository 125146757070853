import { Formik } from 'formik'
import React from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { checkUserExists } from '../../Redux/action/Auth'
import IsLoadingHOC from '../IsLoadingHOC'

const Step3 = ( props ) => {
    const { setActiveStep, setLoading } = props


    const dispatch = useDispatch()






    return (
        <div className="py-md-6 p-5 mx-3 has-box-shadow">
            <div className="form-heading my-5">
                <h3 className="font-weight-bold text-dark">
                    Confirm your Email Address
                </h3>
                <h3 className="font-weight-bold text-dark">
                    for seam less experience
                </h3>
            </div>
            <Formik
                initialValues={{
                    email: "",
                    password: "",
                    password_confirm: "",
                }}
                onSubmit={values => {
                    if ( !values.email ) {
                        toast.error( "Please Enter Email" )
                    } else if ( !values.password || values.password.length < 8 ) {
                        toast.error( "Password length must be more than 8 characters" )
                    } else if ( !values.password_confirm ) {
                        toast.error( "Please Enter Password Confirm" )
                    } else if ( values.password !== values.password_confirm ) {
                        toast.error( "Your Password and Password Confirm are not same" )
                    }
                    else {
                        setLoading( true )
                        dispatch( checkUserExists( values.email ) )
                            .then(
                                () => {
                                    dispatch( { type: "SAVE_USER_TEMP_DATA", payload: values } )
                                    setActiveStep( 4 )
                                    setLoading( false )
                                },
                                error => {
                                    setLoading( false )
                                    toast.error( error.data.message )
                                }
                            )
                            .catch(
                                error => {
                                    console.log( error );
                                }
                            )
                    }
                }}
            >
                {( {
                    handleSubmit,
                    handleChange,
                    values
                } ) => (
                    <form className="my-3" onSubmit={handleSubmit}>
                        <div className="form-group">
                            <input
                                type="email"
                                className="form-control secondary-input"
                                id="email-id"
                                placeholder="Email Address"
                                name="email"
                                required
                                onChange={handleChange}
                                value={values.email}
                            />
                        </div>
                        <div className="form-group">
                            <input
                                type="password"
                                className="form-control secondary-input"
                                id="password"
                                name="password"
                                value={values.password}
                                onChange={handleChange}
                                placeholder="Create Password"
                            />
                        </div>
                        <div className="form-group">
                            <input
                                type="password"
                                className="form-control secondary-input"
                                id="confirm-password"
                                placeholder="Confirm Password"
                                name="password_confirm"
                                onChange={handleChange}
                                value={values.password_confirm}
                            />
                        </div>
                        <button
                            id="signup-button"
                            className="btn btn-gradient-secondary w-100 my-5"
                            type="submit"
                        >
                            Sign Up
                        </button>
                    </form>
                )}

            </Formik>
            <small className="form-text text-muted text-center">
                Already have an account?{' '}
                <span
                    className="font-weight-bold text-muted">
                    <Link to={'/login'}>Click here to Login</Link>
                </span>
            </small>

        </div>
    )
}

export default IsLoadingHOC( Step3 )

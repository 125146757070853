import { useWeb3React } from '@web3-react/core';
import { BigNumber } from 'ethers';
import React from 'react'
import { useRef } from 'react';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import Web3 from 'web3'
import { AqarMarketplace, ERC20Abi } from '../abi';
import { AQRTokenAddress, networkID } from '../Config/network';
import GasPriceEstimator from '../utils/gasEstimator';
import trimAdd from '../utils/TrimAddress';
const customMsg = ( message, hash ) => {
    return (
        <>
            <p>{message}</p>
            <a
                style={{
                    color: "#FBB040",
                    fontSize: "14px",
                    fontWeight: "600"
                }}
                href={`https://polygonscan.com/tx/${hash}`}
                target="_blank">
                {trimAdd( hash, 10 )}
            </a>
        </>
    )
}

const useShopping = () => {
    const { account } = useWeb3React()
    const web3 = new Web3( window.ethereum )
    const marketPlaceContract = new web3.eth.Contract( AqarMarketplace.abi, AqarMarketplace.networks[networkID].address ).methods;
    const ERC20Contract = new web3.eth.Contract( ERC20Abi.abi, AQRTokenAddress ).methods;
    const gasEstimator = new GasPriceEstimator( "polygon" );

    const dispatch = useDispatch()
    const onWalletToast = useRef( null )
    const onTrxToast = useRef( null )

    const getApproveToken = useCallback(
        async ( amount ) => {
            toast.dismiss()
            onWalletToast.current = toast( "Please wait...", {
                type: "loading",
                isLoading: true,
                autoClose: false
            } )
            await ERC20Contract
                .approve( AqarMarketplace.networks[networkID].address,
                    BigNumber.from( amount ).mul( BigNumber.from( "10" ).pow( BigNumber.from( "18" ) ) ) )
                .send( {
                    from: account,
                    gasPrice: web3.utils.toWei( Math.ceil( await gasEstimator.estimate() ).toString(), "gwei" )
                } )
                .on( "error", error => {
                    toast.update( onWalletToast.current, {
                        render: error.message,
                        type: "error",
                        isLoading: false,
                        autoClose: 5000
                    } )
                } )
                .on( "transactionHash", hash => {
                    onTrxToast.current = toast( customMsg( "Transaction hash:", hash ), {
                        type: "loading",
                        isLoading: true,
                    } )
                    toast.update( onWalletToast.current, {
                        render: "Transaction have been Confirmed by you",
                        type: "success",
                        isLoading: false,
                    } )
                } )
                .on( "receipt", receipt => {
                    if ( receipt.status ) {
                        toast.update( onTrxToast.current, {
                            render: "Transaction success",
                            type: "success",
                            isLoading: false,
                            autoClose: 5000
                        } )
                        toast.dismiss( onWalletToast.current )
                    } else {
                        toast.update( onTrxToast.current, {
                            render: "Transaction failed",
                            type: "error",
                            isLoading: false,
                            autoClose: 5000
                        } )
                    }
                } )
        },
        [account],
    )
    const buyNFTs = useCallback(
        async ( { collections, tokenId, quantity }, buywithTokenAddress ) => {
            toast.dismiss()
            onWalletToast.current = toast( "Please wait...", {
                type: "loading",
                isLoading: true,
                autoClose: false
            } )
            await marketPlaceContract.createOffer( collections, tokenId, quantity, buywithTokenAddress )
                .send( {
                    from: account,
                    gasPrice: web3.utils.toWei( Math.ceil( await gasEstimator.estimate() ).toString(), "gwei" )
                } )
                .on( "error", error => {
                    toast.update( onWalletToast.current, {
                        render: error.message,
                        type: "error",
                        isLoading: false,
                        autoClose: 5000
                    } )
                } )
                .on( "transactionHash", hash => {
                    onTrxToast.current = toast( customMsg( "Transaction hash:", hash ), {
                        type: "loading",
                        isLoading: true,
                    } )
                    toast.update( onWalletToast.current, {
                        render: "Transaction have been Confirmed by you",
                        type: "success",
                        isLoading: false,
                    } )
                    dispatch( { type: "REMOVE_CART_ITEMS" } )
                } )
                .on( "receipt", receipt => {
                    if ( receipt.status ) {
                        toast.update( onTrxToast.current, {
                            render: "Transaction success",
                            type: "success",
                            isLoading: false,
                            autoClose: 5000
                        } )
                        toast.dismiss( onWalletToast.current )
                    } else {
                        toast.update( onTrxToast.current, {
                            render: "Transaction failed",
                            type: "error",
                            isLoading: false,
                            autoClose: 5000
                        } )
                    }
                } )
        },
        [account],
    )
    return { getApproveToken, buyNFTs }
}

export default useShopping 
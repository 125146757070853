import React from 'react'
import onplatformPic from '../../../assets/images/staking-pic/on-platform-pic.svg'

const OnPlatform = () => {
  return (
    <section className='on-platform'>
      <div className='container'>
        <div className='on-platform__row'>
            <div className='on-platform__col'>
                <div className='on-platform__img'>
                <img className="img-fluid" src={onplatformPic} alt="onplatformPic" />
                </div>
            </div>
            <div className='on-platform__col'>
                <div className='on-platform__content'>
                    <p>
                        On Aqar chain Platform the users will be able to stake not only the
                        native utility token of the platform $AQR, but also the property
                        share token which represents the fractional share in the property.   
                    </p>
                    <p>
                        The Property is a digital Asset which is minted into a NFT and the
                        property is divided into square feet, so 1 Square feet is 1 share
                        in the property. This share is a fractional portion of the Property
                        NFT, which is also called the Property Share token.
                    </p>
                </div>
            </div> 
        </div>
      </div>   
    </section>
  )
}

export default OnPlatform
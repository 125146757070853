


function AqarchainNews () {

  const data = [
    {
      image: require( "../../../assets/images/news-logos/1.svg" ).default,
      url: "https://news.yahoo.com/aqarchain-io-real-estate-tokenization-053700021.html"
    },
    {
      image: require( "../../../assets/images/news-logos/2.svg" ).default,
      url: "https://finance.yahoo.com/news/aqarchain-io-real-estate-tokenization-053700021.html"
    },
    {
      image: require( "../../../assets/images/news-logos/3.svg" ).default,
      url: "https://money.yahoo.com/aqarchain-io-real-estate-tokenization-053700021.html"
    },
    {
      image: require( "../../../assets/images/news-logos/4.svg" ).default,
      url: "https://www.outlookindia.com/website/story/outlook-spotlight-aqarchainio-to-launch-the-first-decentralized-real-estate-nft-marketplace/398201"
    },
    {
      image: require( "../../../assets/images/news-logos/5.svg" ).default,
      url: "https://www.livemint.com/brand-post/aqarchainio-to-launch-the-first-decentralized-real-estate-nft-marketplace-11634829900968.html"
    },
    {
      image: require( "../../../assets/images/news-logos/6.svg" ).default,
      url: "https://www.unlock-bc.com/81163/aqarchain-showcases-first-global-blockchain-platform-offering-fractionalized-nfts-for-properties/"
    },
    {
      image: require( "../../../assets/images/news-logos/7.svg" ).default,
      url: "https://financialpost.co.in/index.php/2021/10/19/aqarchain-io-to-launch-the-first-decentralized-real-estate-nft-marketplace/"
    },
    {
      image: require( "../../../assets/images/news-logos/8.svg" ).default,
      url: "http://www.businessworld.in/article/Aqarchain-io-To-Launch-The-First-Decentralized-Real-Estate-NFT-Marketplace/20-10-2021-409304/"
    },
    {
      image: require( "../../../assets/images/news-logos/9.svg" ).default,
      url: "https://www.dnaindia.com/business/report-aqarchainio-to-launch-the-first-decentralized-real-estate-nft-marketplace-2916391"
    },
    {
      image: require( "../../../assets/images/news-logos/10.svg" ).default,
      url: "https://www.business-standard.com/content/press-releases-ani/aqarchain-io-to-launch-the-first-decentralized-real-estate-nft-marketplace-121101901185_1.html"
    },
    {
      image: require( "../../../assets/images/news-logos/11.svg" ).default,
      url: "https://www.newsbtc.com/press-releases/aqarchain-io-real-estate-tokenization-platform-launches-aqr-token-ieo-on-mena-leading-emirex-exchange/"
    },
    {
      image: require( "../../../assets/images/news-logos/12.svg" ).default,
      url: "https://www.theweek.in/wire-updates/business/2021/10/19/pwr25-aqarchain.io.html?fbclid=IwAR00gi8uVX2GMG1gVua6ukPD3ryygadBAo3aHeUrWu7IAg7Y90tQ4nb1Ng4"
    }
  ]

  return (
    <section className="PartnersBy">
      <div id="landing-page-section_5-partner-and-supported-container" className="partner-and-supported-by-section-container container-lg">
        <div className="row no-gutters">
          <div className="col-lg-12 col-12  text-center">
            <h1 className="font-weight-bold text-white text-center mb-5 PartnersBy__text">Aqarchain in the News</h1>
          </div>
        </div>
        <div className="row no-gutters">
          <div className="col-lg-12 col-12">
            <div className='image--group'>
              {
                data.map( ( item, index ) => (
                  <div className='image--item' key={index}>
                    <a href={item.url}>
                      <img src={item.image} alt="" />
                    </a>
                  </div>
                ) )
              }
            </div>
          </div>
        </div>
      </div>
    </section>

  )
}


export default AqarchainNews;
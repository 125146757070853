import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Web3 from 'web3';
import { AqarMarketplace } from '../../../abi';
import NFTCard from '../../../components/NFTCard';
import { getCollections, getNFTs } from '../../../Redux/action/App.js';
import IsLoadingHOC from '../../../components/IsLoadingHOC';
import { useWeb3React } from '@web3-react/core';
import useOracle from '../../../hookss/useOracle';
import { ethers } from 'ethers';
import Filter from './Filter';
import Pagination from '../../../components/Pagination';
import ModelViewer from './ModelViewer';

const Marketplace = ({ setLoading }) => {
	const [NFTs, setNFTs] = useState([]);
	const [totalNFTs, setTotalNFTs] = useState(0);
	const [collections, setCollections] = useState([]);
	const [activeCollection, setActiveCollection] = useState('');
	const { AQRPrice, oneDAI } = useOracle();
	const { cart } = useSelector((state) => state);
	const [search, setSearch] = useState({
		text: '',
		sort: 'price.asc',
		min: '',
		max: '',
	});
	const [pagination, setPagination] = useState({
		limit: 20,
		activePage: 1,
		totalPageCount: 3,
	});

	const { account } = useWeb3React();
	const provider = new ethers.providers.JsonRpcProvider(
		'https://nd-240-117-310.p2pify.com/83ce4985408b1cfb834635c52c5ead03'
	);
	const signer = new ethers.VoidSigner(account, provider);
	const AqarMarketplaceInstance = new ethers.Contract(
		AqarMarketplace.networks[process.env.REACT_APP_CHAIN_ID].address,
		AqarMarketplace.abi,
		signer
	);
	useEffect(async () => {
		(async () => {
			console.log(await AqarMarketplaceInstance.owner(), 'AqarMarketplaceInstance');
		})();
		getNFTsList(search);
		getCollectionsList();
	}, [pagination.activePage, activeCollection, AQRPrice, oneDAI, search.sort, search.text, search.min, search.max]);

	const getCollectionsList = async () => {
		setLoading(true);
		await getCollections()
			.then(
				(response) => {
					let collections = [];
					response.data.map((collection, index) => {
						collections.push({ value: collection.address, label: collection.name });
					});
					setCollections(collections);
					setLoading(false);
				},
				(error) => {
					console.log('responseError', error.response.data);
					setLoading(false);
				}
			)
			.catch((error) => console.log(error));
	};

	const getNFTsList = async (options) => {
		setLoading(true);
		const { text, sort, min, max } = options;
		const _text = text ? text : '';
		const _min = Math.floor(min / oneDAI);
		const _max = Math.floor(max / oneDAI);
		await getNFTs({ ...pagination, activeCollection, _text, sort, _min, _max })
			.then(
				(response) => {
					if (cart.cartItems.length > 0) {
						let nfts = [];
						response.data.docs.map((nft, index) => {
							if (cart.cartItems.find((item) => item.assetId === nft.assetId)) {
								nfts.push({ ...nft, isInCart: true });
							} else {
								nfts.push({ ...nft, isInCart: false });
							}
						});
						setNFTs(nfts);
					} else {
						setNFTs(response.data.docs);
					}
					setTotalNFTs(response.data.totalDocs);
					setPagination({ ...pagination, totalPageCount: response.data.totalPages });
					setLoading(false);
				},
				(error) => {
					console.log('responseError', error.response.data);
					setLoading(false);
				}
			)
			.catch((error) => {
				console.log(error);
			});
	};

	return (
		<>
			<Filter
				collections={collections}
				setSearch={setSearch}
				search={search}
				setActiveCollection={setActiveCollection}
				getNFTsList={getNFTsList}
			/>
			<div className='page--header'>
				<div>
					<h2>AqarLand Marketplace</h2>
					<p>{totalNFTs} NFTs to add in your Assets</p>
				</div>
				<div>
					<button
						className={search.sort === 'price.desc' ? 'active' : ''}
						onClick={() => {
							if (search.sort === 'price.asc') {
								setSearch({ ...search, sort: 'price.desc' });
							} else {
								setSearch({ ...search, sort: 'price.asc' });
							}
						}}
					>
						<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'>
							<path d='M19 3l4 5h-3v12h-2V8h-3l4-5zm-5 15v2H3v-2h11zm0-7v2H3v-2h11zm-2-7v2H3V4h9z' />
						</svg>
					</button>
				</div>
			</div>
			<div className='marketplace'>
				<div className='tab-content marketplace--content' id='myTabContent'>
					<div className='tab-pane fade show active' id='home' role='tabpanel' aria-labelledby='home-tab'>
						{/* <ModelViewer /> */}
						<div className='assets-content'>
							{NFTs.length > 0 &&
								NFTs.map((item, index) => <NFTCard item={{ ...item, AQRPrice, oneDAI }} key={index} />)}
						</div>
						{totalNFTs > 20 && (
							<Pagination NFTs={NFTs} pagination={pagination} setPagination={setPagination} />
						)}
					</div>
				</div>
			</div>
		</>
	);
};

export default IsLoadingHOC(Marketplace);

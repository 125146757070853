import Web3 from "web3";

const getInEth = ( value ) => {
  const web3 = new Web3( window.ethereum );
  const valueInString = value.toString();
  const valueInEth = web3.utils.fromWei( valueInString, "ether" );
  return valueInEth;
};

export default getInEth;

import {
  BrowserRouter as Router,
} from "react-router-dom";
import React from "react";
import { Web3ReactProvider } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "video-react/dist/video-react.css";
import "./assets/css/theme.css";
import "./assets/css/scss/main.scss";
import Routing from "./Routing";
import { MoralisProvider } from "react-moralis";


function App () {
  function getLibrary ( provider ) {
    const library = new Web3Provider( provider );
    library.pollingInterval = 12000;
    return library;
  }

  return (
    <MoralisProvider appId={process.env.REACT_APP_MORALIS_APP_ID} serverUrl={process.env.REACT_APP_MORALIS_SERVER}>
      <Web3ReactProvider getLibrary={getLibrary}>
        <div className="App">
          <Router>
            <Routing />
          </Router>
        </div>
        <ToastContainer />
      </Web3ReactProvider>
    </MoralisProvider>
  );
}

export default App;

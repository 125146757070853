import { useState } from "react"
import ClaimCard from "../../Claim"
import { claim } from "../../../constants/addresses.json"

const Claim = () => {

    const tabItems = [
        {
            id: 2,
            title: "Public",
            address: claim.public
        },
        {
            id: 0,
            title: "Seed",
            address: claim.seed,
        },
        {
            id: 1,
            title: "Private",
            address: claim.private,
        },
        {
            id: 3,
            title: "Advisor",
            address: claim.advisor
        },
        {
            id: 4,
            title: "Bounty",
            // address: claim.bounty
        },
        // {
        //     id: 5,
        //     title: "Team",
        // },
        // {
        //     id: 6,
        //     title: "Vendors",
        // },
        // {
        //     id: 6,
        //     title: "Dummy",
        //     address: "0x3f820EF730EF04c2CEdc66d3ee1299c83f4860d9",
        // },
    ]

    const [activeTab, setActiveTab] = useState( 0 )


    const allProperty = [
        {
            date: "January 12, 2022",
            event: "TGE",
            seedRound: "0%",
            private: "1%",
            public: "50%",
            advisors: "0%",
            bounty: "0%"
        },
        {
            date: "February 12, 2022",
            event: "Month 01",
            seedRound: "6.6667%",
            private: "8.25%",
            public: "16.6667%",
            advisors: "8.3334%",
            bounty: "0%"
        },
        {
            date: "March 12, 2022",
            event: "Month 02",
            seedRound: "6.6667%",
            private: "8.25%",
            public: "16.6667%",
            advisors: "8.3334%",
            bounty: "0%"
        },
        {
            date: "April 12, 2022",
            event: "Month 03",
            seedRound: "6.6667%",
            private: "8.25%",
            public: "16.6667%",
            advisors: "8.3334%",
            bounty: "16.6667%"
        },
        {
            date: "May 12, 2022",
            event: "Month 04",
            seedRound: "6.6667%",
            private: "8.25%",
            public: "0%",
            advisors: "8.3334%",
            bounty: "16.6667%"
        },
        {
            date: "June 12, 2022",
            event: "Month 05",
            seedRound: "6.6667%",
            private: "8.25%",
            public: "0%",
            advisors: "8.3334%",
            bounty: "16.6667%"
        },
        {
            date: "July 12, 2022",
            event: "Month 06",
            seedRound: "6.6667%",
            private: "8.25%",
            public: "0%",
            advisors: "8.3334%",
            bounty: "16.6667%"
        },
        {
            date: "August 12, 2022",
            event: "Month 07",
            seedRound: "6.6667%",
            private: "8.25%",
            public: "0%",
            advisors: "8.3334%",
            bounty: "16.6667%"
        },
        {
            date: "September 12, 2022",
            event: "Month 08",
            seedRound: "6.6667%",
            private: "8.25%",
            public: "0%",
            advisors: "8.3334%",
            bounty: "16.6667%"
        },
        {
            date: "October 12, 2022",
            event: "Month 09",
            seedRound: "6.6667%",
            private: "8.25%",
            public: "0%",
            advisors: "8.3334%",
            bounty: "0%"
        },
        {
            date: "November 12, 2022",
            event: "Month 10",
            seedRound: "6.6667%",
            private: "8.25%",
            public: "0%",
            advisors: "8.3334%",
            bounty: "0%"
        },
        {
            date: "December 12, 2022",
            event: "Month 11",
            seedRound: "6.6667%",
            private: "8.25%",
            public: "0%",
            advisors: "8.3334%",
            bounty: "0%"
        },
        {
            date: "January 12, 2023",
            event: "Month 12",
            seedRound: "6.6667%",
            private: "8.25%",
            public: "0%",
            advisors: "8.3334%",
            bounty: "0%"
        },
        {
            date: "February 12, 2023",
            event: "Month 13",
            seedRound: "6.6667%",
            private: "0%",
            public: "0%",
            advisors: "0%",
            bounty: "0%"
        },
        {
            date: "March 12, 2023",
            event: "Month 14",
            seedRound: "6.6667%",
            private: "0%",
            public: "0%",
            advisors: "0%",
            bounty: "0%"
        },
        {
            date: "April 12, 2023",
            event: "Month 15",
            seedRound: "6.6667%",
            private: "0%",
            public: "0%",
            advisors: "0%",
            bounty: "0%"
        },
    ]

    return (
        <div
            className='tab-pane fade active show'
            id='user-dashboard-nav-tab-buy'
            role='tabpanel'
            aria-labelledby='user-dashboard-nav-tab-buy-tab'
        >
            <div className='text-center text-lg-left p-4 p-lg-5 has-box-shadow has-border-radius'>
                <h3 className='font-weight-bold mb-3 mb-lg-4'>Total AQR Balance: 0.00 AQR</h3>

                <div className="claimtab--conatiner">
                    <ul className="claimtab--group">
                        {
                            tabItems.map( ( item, index ) => (
                                <li
                                    className={`claimtab--item ${activeTab === index
                                        ? "active"
                                        : ""}`}
                                    onClick={() => setActiveTab( index )}
                                    key={index}
                                >
                                    {item.title}
                                </li>
                            ) )
                        }
                    </ul>
                </div>
                <div className="claimtab--content">
                    <ClaimCard address={tabItems[activeTab].address} />
                </div>
                <div className="dashboard-table-container table-responsive">
                    <table className="dashboard-table">
                        <thead>
                            <tr>
                                <th>Dates</th>
                                <th>Event</th>
                                <th>Seed Round</th>
                                <th>Private</th>
                                <th>Public</th>
                                <th>Advisors</th>
                                <th>Bounty</th>
                            </tr>
                        </thead>
                        <tbody>
                            {allProperty.length !== 0
                                ? allProperty.map( ( cell, index ) => (
                                    <tr key={index}>
                                        <td>
                                            {cell.date}
                                        </td>
                                        <td>
                                            {cell.event}
                                        </td>
                                        <td>
                                            {cell.seedRound}
                                        </td>
                                        <td>
                                            {cell.private}
                                        </td>
                                        <td>
                                            {cell.public}
                                        </td>
                                        <td>
                                            {cell.advisors}
                                        </td>
                                        <td>
                                            {cell.bounty}
                                        </td>
                                    </tr> )
                                )
                                : null}
                        </tbody>
                        <thead>
                            <tr>
                                <th colSpan={2}>TOTAL RELAESED $AQR TOKENS</th>
                                <th>100%</th>
                                <th>100%</th>
                                <th>100%</th>
                                <th>100%</th>
                                <th>100%</th>
                            </tr>
                        </thead>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default Claim

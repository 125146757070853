import React from 'react'
import comingsoonPic from '../../../assets/images/staking-pic/coming-soon-pic.png'

const ComingSoon = () => {
    return (
        <section className="coming-soon">
            <div className='container'>
                <div className='coming-soon__row'>
                    <div className='coming-soon__col'>
                        <div className='coming-soon__pic'>
                            <img className="img-fluid" src={comingsoonPic} alt="comingsoonPic" />
                        </div>
                    </div>
                </div>
            </div>
            
        </section>
      
  )
}

export default ComingSoon


import token from '../../../assets/images/landing/AQR-token.png'
import copy from '../../../assets/images/landing/copy.svg'
import useOracle from '../../../hookss/useOracle';
import trimAdd from "../../../utils/TrimAddress"
function TokenPrice () {

    const tokonomics = {
        tSupply: 99805267.06,
        cSupply: 2332159.15 + 1087065.97 + 1393850.81,
    }


    const { AQRPrice } = useOracle()
    return (
        <div className="tokenPrice">
            <div className="tokenPrice__list">
                <div className="token">
                    <div className="token__name">
                        <img alt='' src={token} width="40px" />
                        <p>Aqar Chain (AQR)</p>
                    </div>
                    <div className="token__price">
                        <span>{AQRPrice.toFixed( 6 )}</span>
                        {/* <p>33.0%</p> */}
                    </div>
                </div>
                <div className="tokenPrice__total">
                    <ul>
                        <li>
                            <span>Total token supply</span>
                            <p>{tokonomics.tSupply.toLocaleString( "en-US" )}</p>
                        </li>
                        <li>
                            <span>Circulating Supply</span>
                            <p>{tokonomics.cSupply.toLocaleString( "en-US" )}</p>
                        </li>
                    </ul>
                </div>
                <div className="tokenPrice__total">
                    <ul>
                        <li>
                            <span>Fully diluted Market Cap</span>
                            <p>{( AQRPrice * tokonomics.tSupply ).toLocaleString( 'en-US', {
                                style: 'currency',
                                currency: 'USD',
                            } )}</p>
                        </li>
                        <li>
                            <span>Market Cap</span>
                            <p>{( AQRPrice * tokonomics.cSupply ).toLocaleString( 'en-US', {
                                style: 'currency',
                                currency: 'USD',
                            } )}</p>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="tokenAddress">
                <div className="tokenAddress__itme">
                    <div className='tokenAddress__input'>
                        <div>
                            AQR Address
                        </div>
                        <div className='copy-icon'>
                            <p>{trimAdd( "0xae204ee82e60829a5850fe291c10bf657af1cf02" )}</p>
                            <button className='copy-btn' onClick={() => {
                                navigator.clipboard.writeText( "0xae204ee82e60829a5850fe291c10bf657af1cf02" );
                                alert( "Address Copied successfully" )
                            }}> <img alt='' src={copy} width="20px" /></button>
                        </div>
                    </div>
                    <div className='tokenAddress__input'>
                        <div>
                            Pair Address
                        </div>
                        <div className='copy-icon'>
                            <p>{trimAdd( "0xbddcf113703b8a20f8ce783eaae7fb421bd6ae9e" )}</p>
                            <button className='copy-btn' onClick={() => {
                                navigator.clipboard.writeText( "0xbddcf113703b8a20f8ce783eaae7fb421bd6ae9e" );
                                alert( "Address Copied successfully" )
                            }}><img alt='' src={copy} width="20px" /></button>
                        </div>
                    </div>
                    <div className='tokenAddress__input'>
                        <div>
                            Burn Address
                        </div>
                        <div className='copy-icon'>
                            <p>{trimAdd( "0x5C35b8e1737b454352a9b0aA71F32123323Ecb8E" )}</p>
                            <button className='copy-btn' onClick={() => {
                                navigator.clipboard.writeText( "0x5C35b8e1737b454352a9b0aA71F32123323Ecb8E" );
                                alert( "Address Copied successfully" )
                            }}><img alt='' src={copy} width="20px" /></button>
                        </div>
                    </div>
                </div>
                <a
                    className="btn btn-secondary btn-rounded w-212px btn-sm buy--btn"
                    target="_blank"
                    href="https://quickswap.exchange/#/swap?inputCurrency=0xae204ee82e60829a5850fe291c10bf657af1cf02"
                >Buy AQR</a>
            </div>
        </div>

    )
}


export default TokenPrice;
import React from 'react';
import { useLocation, Link } from 'react-router-dom';

const OwnerMenu = () => {
	let location = useLocation();
	const CustomLink = ({ url, className, id, title, disabled }) => {
		return (
			<li className='nav-item' style={{ pointerEvents: `${disabled}` }}>
				<Link to={url} className={`${className}`} id={id}>
					{title}
				</Link>
			</li>
		);
	};
	return (
		<>
			<CustomLink
				url='/dashboard/profile'
				className={`nav-link ${
					location.pathname === '/dashboard/profile' ? 'active' : ''
				}`}
				id='company-nav-tab-my-profile-tab'
				title='My Profile'
			/>
			<CustomLink
				url='/dashboard/kyc'
				className={`nav-link ${
					location.pathname === '/dashboard/kyc' ? 'active' : ''
				}`}
				id='user-dashboard-nav-tab-kyc-tab'
				title='KYC (coming soon)'
				disabled='none'
			/>
			<CustomLink
				url='/dashboard/portfolio'
				className={`nav-link ${
					location.pathname === '/dashboard/portfolio' ? 'active' : ''
				}`}
				id='user-dashboard-nav-tab-kyc-tab'
				title='My Portfolio'
			/>
			<CustomLink
				url='/dashboard/claim'
				className={`nav-link ${
					location.pathname === '/dashboard/claim' ? 'active' : ''
				}`}
				id='user-dashboard-nav-tab-kyc-tab'
				title='Claim'
			/>
{/* 
			<CustomLink
				url='/dashboard/buy'
				className={`nav-link ${
					location.pathname === '/dashboard/buy' ? 'active' : ''
				}`}
				id='user-dashboard-nav-tab-buy-tab'
				title='Buy AQR'
			/> */}

			{/* <CustomLink
				url='/dashboard/save-properties'
				className={`nav-link ${
					location.pathname === '/dashboard/save-properties'
						? 'active'
						: ''
				}`}
				id='user-dashboard-nav-tab-my-saved-properties-tab'
				title='My Saved Properties'
			/> */}
		</>
	);
};

export default OwnerMenu;

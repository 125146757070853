import { Switch } from 'react-router-dom';
import discover2 from '../components/discover/discover2';
import { injected } from '../connectors';

import AppRoute from './AppRoute';
import PublicLayout from '../layout/Public';
import details from '../components/details/details';
import Login from '../containers/Login';
import Dashboard from '../components/Dashboard/Dashboard';
import PrivateLayout from '../layout/Private';
import PrivateRoute from './PrivateRoute';
import Profile from '../components/Dashboard/Profile/Profile';
import Registration from '../components/Registration';
import ForgotPassword from '../components/ForgotPassword';
import ResetPassword from '../components/ResetPassword';
import { useEffect } from 'react';
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core';
import { setupNetwork } from '../utils/wallet';
import Portfolio from '../components/Dashboard/Portfolio/index';
import emailVerify from '../components/EmailVerify/index';
import ResendLink from '../components/EmailVerify/resendLink';
import Staking from '../containers/Staking';
import Pools from '../containers/Pools';
import Buy from '../components/Dashboard/Buy';
import AqarlandLayout from '../layout/Aqarland';
import Properties from '../containers/Aqarland/Properties';
import CollabLand from '../containers/Aqarland/Lands';
import Map from '../containers/Aqarland/Map';
import Claim from '../components/Dashboard/Claim';
import Marketplace from '../containers/Aqarland/Marketplace';
import Shopping from '../containers/Aqarland/Shopping';

import NewLanding from "../containers/Landing"
import DetailsPage from '../containers/Aqarland/Marketplace/Details';
import Team from '../containers/Team';
import Advisor from '../containers/Advisor';
import NewDiscover from '../containers/Discover';
// const ALLUser = Authorization(['TEACHER', 'STUDENT', 'SCHOOL_ADMINISTRATOR']);
// const TeacherAndSchoolAdmin = Authorization( ["SCHOOL_ADMINISTRATOR", "TEACHER"] )
// const Teacher = Authorization( ['TEACHER'] )
// const SchoolAdmin = Authorization( ["SCHOOL_ADMINISTRATOR"] )

const Routing = () => {
	const { activate } = useWeb3React();

	useEffect( () => {
		defaultConnect();
	}, [] );

	useEffect( () => {
		console.warn = () => null;
		if ( window && window.ethereum ) {
			window.ethereum.on( 'chainChanged', async () => {
				try {
					await activate( injected, async ( error ) => {
						if ( error instanceof UnsupportedChainIdError ) {
							const hasSetup = await setupNetwork();
							if ( hasSetup ) {
								activate( injected );
							}
						}
					} );
				} catch ( error ) {
					console.error( error );
				}
			} );
		}
	}, [] );

	const defaultConnect = async () => {
		try {
			await activate( injected, async ( error ) => {
				if ( error instanceof UnsupportedChainIdError ) {
					const hasSetup = await setupNetwork();
					if ( hasSetup ) {
						activate( injected );
					}
				}
			} );
		} catch ( error ) {
			console.error( error );
		}
	};

	return (
		<Switch>
			<AppRoute
				exact
				path='/'
				component={NewLanding}
				layout={PublicLayout}
			/>

			<AppRoute
				exact
				path='/team'
				component={Team}
				layout={PublicLayout}
			/>
			<AppRoute
				exact
				path='/advisor'
				component={Advisor}
				layout={PublicLayout}
			/>
			<AppRoute
				exact
				path='/discover'
				component={NewDiscover}
				layout={PublicLayout}
			/>

			<AppRoute
				exact
				path='/new-discover'
				component={NewDiscover}
				layout={PublicLayout}
			/>

			<AppRoute
				exact
				path="/staking"
				component={Staking}
				layout={PublicLayout} />

			<AppRoute
				exact
				path="/pools/:id"
				component={Pools}
				layout={PublicLayout} />

			<AppRoute
				exact
				path='/aqarland'
				component={Marketplace}
				layout={AqarlandLayout}
			/>
			<AppRoute
				exact
				path='/nft/:address/:id'
				component={DetailsPage}
				layout={AqarlandLayout}
			/>

			<AppRoute
				exact
				path='/aqarland/shopping'
				component={Shopping}
				layout={AqarlandLayout}
			/>

			<AppRoute
				exact
				path='/aqarland/properties'
				component={Properties}
				layout={AqarlandLayout}
			/>
			<AppRoute
				exact
				path='/aqarland/collabLand'
				component={CollabLand}
				layout={AqarlandLayout}
			/>
			<AppRoute
				exact
				path='/aqarland/map'
				component={Map}
				layout={AqarlandLayout}
			/>

			<AppRoute
				exact
				path='/pools'
				component={Pools}
				layout={PublicLayout}
			/>

			<AppRoute
				exact
				path='/discover2'
				component={discover2}
				layout={PublicLayout}
			/>

			<AppRoute
				exact
				path='/registration/verify/email/:token'
				component={emailVerify}
				layout={PublicLayout}
			/>

			<AppRoute
				exact
				path='/registration/resend/email'
				component={ResendLink}
				layout={PublicLayout}
			/>

			{/* <AppRoute
				exact
				path='/details/:id'
				component={details}
				layout={PublicLayout}
			/> */}
			<AppRoute
				exact
				path='/details/:id'
				component={details}
				layout={PublicLayout}
			/>

			<AppRoute
				exact
				path='/login'
				component={Login}
				layout={PublicLayout}
			/>

			<AppRoute
				exact
				path='/registration'
				component={Registration}
				layout={PublicLayout}
			/>

			<AppRoute
				exact
				path='/forgot-password'
				component={ForgotPassword}
				layout={PublicLayout}
			/>

			<AppRoute
				exact
				path='/reset/:token'
				component={ResetPassword}
				layout={PublicLayout}
			/>

			<PrivateRoute
				exact
				path='/dashboard'
				component={Dashboard}
				layout={PrivateLayout}
			/>

			<PrivateRoute
				exact
				path='/dashboard/profile'
				component={Profile}
				layout={PrivateLayout}
			/>

			{/* <PrivateRoute
				exact
				path='/dashboard/kyc'
				component={KYC}
				layout={PrivateLayout}
			/> */}

			<PrivateRoute
				exact
				path='/dashboard/portfolio'
				component={Portfolio}
				layout={PrivateLayout}
			/>

			<PrivateRoute
				exact
				path='/dashboard/buy'
				component={Buy}
				layout={PrivateLayout}
			/>

			<PrivateRoute
				exact
				path='/dashboard/claim'
				component={Claim}
				layout={PrivateLayout}
			/>

			{/* <PrivateRoute
				exact
				path='/dashboard/kyc-process'
				component={KycProcess}
				layout={PrivateLayout}
			/> */}

			{/* <Route exact path="/discover" exact component={discover}></Route> */}
		</Switch>
	);
};

export default Routing;

import { useState } from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { getNFTDetails, getNFTs } from '../../../../Redux/action/App'
import { addToCart } from "../../../../Redux/action/Cart";
import useOracle from "../../../../hookss/useOracle";
import NFTCard from '../../../../components/NFTCard'
import TransactionsHistory from "./TransactionsHistory"
import useNFTs from '../../../../hookss/useNFTs'
import ModelViewer from '../ModelViewer'

const DetailsPage = () => {
    const params = useParams()
    const dispatch = useDispatch()
    const { cart } = useSelector( state => state )
    const [nft, setNft] = useState( "" )
    const [count, setCount] = useState( 1 );
    const { AQRPrice, oneDAI } = useOracle();
    const [recommendedNFTs, setRecommendedNFTs] = useState( [] )
    const [metadata, setMetadata] = useState( null )
    const { stoke } = useNFTs( params.id, params.address )

    const getNFTData = async () => {
        const { address, id } = params
        await getNFTDetails( id, address )
            .then(
                response => {
                    setNft( response.data )
                },
                error => {
                    setNft( {} )
                }
            )
    }

    const getRecommendedNFTs = async () => {
        const options = {
            limit: 8,
            activeCollection: params.address,
        }
        await getNFTs( { ...options } )
            .then(
                response => {
                    setRecommendedNFTs( response.data.docs )
                },
                error => {
                    console.log( Object.entries( error ) );
                }
            )
            .catch()
    }

    useEffect( () => {
        getNFTData()
        getRecommendedNFTs()
    }, [params.id, params.address] )


    if ( !nft ) {
        return <h1 style={{
            textAlign: "center",
            color: "#fff"
        }}>Loading</h1>
    }

    return (
        <>
            <div className='page--header'>
                <Link to="/aqarland" className='back--btn'>
                    <button>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                            <path d="M7.828 11H20v2H7.828l5.364 5.364-1.414 1.414L4 12l7.778-7.778 1.414 1.414z" />
                        </svg>
                    </button>
                    Go Back
                </Link>
                <Link to="/aqarland/shopping" className="cart--btn">
                    <span>{cart.cartItems.length}</span>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                        <path d="M4 6.414L.757 3.172l1.415-1.415L5.414 5h15.242a1 1 0 0 1 .958 1.287l-2.4 8a1 1 0 0 1-.958.713H6v2h11v2H5a1 1 0 0 1-1-1V6.414zM5.5 23a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm12 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z" />
                    </svg>
                </Link>
            </div>
            <div className='page--body'>
                <div className='nft--row'>
                    <div className='nft--image'>
                        {console.log( "nft.nft", nft.nft )}
                        {params.address === "0xa6565fB64de81208582c6be0d0beb61f249701Fc"
                            ? (
                                <div className='image--box' style={{ height: "487px" }}>
                                    <ModelViewer
                                        modal={String( nft.nft.animation_url ).replace( "https://aqarchain.mypinata.cloud/", "https://ipfs.io/" )}
                                        poster={String( nft.nft.image ).replace( "https://aqarchain.mypinata.cloud/", "https://ipfs.io/" )} />
                                    <a href='https://digital-forest.pages.dev/' target={"_blank"} className='btn btn-gradient w-100 btn-sm '>Explore Metaverse</a>
                                </div>
                            ) : nft && (
                                <div className='image--box'>
                                    <img src={String( nft.nft.image ).replace( "https://aqarchain.mypinata.cloud/", "https://ipfs.io/" )} alt={nft.nft.name} />
                                </div>
                            )
                        }
                    </div>
                    <div className='nft--deatils'>
                        <h2 style={{
                            color: "#F4B43A",
                            fontSize: "1.2rem",
                            fontWeight: "600",
                        }}>#{nft.assetId}</h2>
                        <h1>{nft.nft.name}</h1>
                        <p>{nft.nft.description}</p>
                        <div className='nft--row info--row'>
                            <div className=''>
                                <div className='available'>
                                    <p>Available</p>
                                    <h4>
                                        <span>{stoke}</span>
                                        /
                                        <span>{nft.stock}</span>
                                    </h4>
                                </div>
                            </div>
                            <div>
                                <div className='available'>
                                    <p>AQR</p>
                                    <h4>
                                        <span>
                                            {( nft.price * oneDAI ).toLocaleString( "en-US", {
                                                minimumFractionDigits: 2, maximumFractionDigits: 2
                                            } )}
                                        </span>
                                        /
                                        <span>
                                            {( nft.price ).toLocaleString( "en-US", {
                                                style: "currency",
                                                currency: "USD",
                                            } )}
                                        </span>
                                    </h4>
                                </div>
                            </div>
                        </div>
                        <div className='nft--row'>
                            <div className='addToCart--action'>
                                <div className='buyBtn-group'>
                                    <button
                                        onClick={() => {
                                            setCount( count === 1 ? 1 : count - 1 )
                                        }}
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="#aaaaaa"><path d="M5 11h14v2H5z" /></svg>
                                    </button>
                                    <span>{count}</span>
                                    <button
                                        onClick={() => {
                                            setCount( count === nft.stock ? nft.stock : count + 1 )
                                        }}
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="#aaaaaa"><path d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z" /></svg>
                                    </button>
                                </div>
                            </div>
                            <div className=''>
                                <button
                                    className="btn btn-gradient w-100 btn-sm btn-rounded addToCart--btn"
                                    onClick={() => {
                                        dispatch( addToCart( { ...nft, amount: count, availableStoke: nft.stoke } ) )
                                        toast.success( `${count} Item Added to cart` )
                                        setCount( 1 )

                                    }}
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                        <path d="M4 6.414L.757 3.172l1.415-1.415L5.414 5h15.242a1 1 0 0 1 .958 1.287l-2.4 8a1 1 0 0 1-.958.713H6v2h11v2H5a1 1 0 0 1-1-1V6.414zM5.5 23a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm12 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z" />
                                    </svg>
                                    Add to Cart
                                </button>
                            </div>
                        </div>
                        <TransactionsHistory address={params.address} id={params.id} />
                    </div>
                </div>
            </div>
            <div className='page--header'>
                <h2>Explore more <span>{nft.nftCollection.name}</span></h2>
            </div>
            <div className='page--body'>
                <div className='nft--row'>
                    <div className="assets-content">
                        {recommendedNFTs.length > 0 &&
                            recommendedNFTs.map( ( nft, index ) => (
                                <NFTCard item={{ ...nft, AQRPrice, oneDAI }} key={index} />
                            ) )}
                    </div>
                </div>
            </div>
        </>
    )
}

export default DetailsPage
import { useCallback } from "react";
import { useWeb3React } from "@web3-react/core";
import getInWei from "../utils/getInWei";
import getsmartcontract from "../utils/getsmartcontract";
import { toast } from 'react-toastify'
import useWeb3 from './useWeb3'

const useWithdraw = ( id ) => {
  const { account } = useWeb3React();
  const web3 = useWeb3()
  // const { toastInfo, toastError, toastSuccess } = useToast();

  const callWithdraw = useCallback(
    async ( pid, amount ) => {
      let token;
      if ( pid === 0 ) token = "AQR";
      else token = "LP";

      const FarmSmartContract = getsmartcontract( id );

      toast.info(
        "Processing...",
        `You requested to withdraw ${amount} ${token}`
      );

      const amountInWei = getInWei( amount );
      await FarmSmartContract.methods
        .withdraw( pid, amountInWei )
        .send( { from: account } )
        .on( "transactionHash", hash => {
          toast.info( `Transaction hash: ${hash}` );
        } )
        .on( "confirmation", ( confirmationNumber, receipt ) => {
          if ( confirmationNumber === 1 ) {
            toast.success( `Transaction confirmed: ${receipt.transactionHash}` );
          }
        } )
        .on( "receipt", receipt => {
          toast.info( `Transaction receipt: ${receipt.transactionHash}` );
        } )
        .on( "error", error => {
          toast.error( `Transaction error: ${error.message}` );
        } );

      toast.success( "Success", `${amount} ${token} withdrawn successfully` );
      // await FarmSmartContract.methods
      //   .withdraw( pid, amountInWei )
      //   .estimateGas( { from: account } )
      //   .then( ( gasAmount ) => {
      //     web3.eth.getGasPrice( async ( err, currentGasPrice ) => {
      //       if ( err ) toast.error( "Error while estimating gas" + err.message );
      //       else {

      //       }
      //     } );
      //   }, err => {
      //     toast.error( "Error while estimating gas" + err.message );
      //   } );
    },
    [account]
  );

  return { callWithdraw };
};
export default useWithdraw;

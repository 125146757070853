const initialState = {
    cartItems: [],
};

export default function ( state = initialState, action ) {
    switch ( action.type ) {
        case "ADD_TO_CART":
            let itemFound = false;
            let addItems = state.cartItems.map( item => {
                if ( item.id === action.payload.id ) {
                    item.amount += action.payload.amount;
                    itemFound = true;
                }
                return item;
            } )
            if ( !itemFound ) addItems.push( action.payload );
            return { ...state, cartItems: addItems };
        case "REMOVE_FROM_CART":
            const filData = state.cartItems.filter( item => item.id !== action.payload.id )
            return { ...state, cartItems: filData }
        case "ADD_ITEM_QTY":
            let addItemsQTY = [];
            state.cartItems.map( item => {
                if ( item.id === action.payload.id ) {
                    item.amount += 1
                }
                return addItemsQTY.push( item )
            } )
            return { ...state, cartItems: addItemsQTY }
        case "REMOVE_ITEM_QTY":
            let removeItemsQTY = [];
            state.cartItems.map( item => {
                if ( item.id === action.payload.id ) {
                    item.amount = item.amount === 1
                        ? 1
                        : item.amount - 1
                }
                return removeItemsQTY.push( item )
            } )
            return { ...state, cartItems: removeItemsQTY }
        case "REMOVE_CART_ITEMS":
            return { ...state, cartItems: [] }
        default:
            return state
    }
}
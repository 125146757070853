import { Link, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import Web3 from "web3";
import BigNumber from "bignumber.js";
import farms from "../../Config/index";
import getSmartContract from "../../utils/getsmartcontract";
import getInEth from "../../utils/getInEth";
import getTokenSmartContract from "../../utils/getTokenSmartContract";
import useApprove from "../../hookss/useApprove";
import useDeposit from "../../hookss/useDeposit";
import useUnstake from "../../hookss/useWithdraw";
import useHarvest from "../../hookss/useHarvest";

const Pools = ( props ) => {
  const { id } = useParams();
  const pid = id == "0" || id == "2" ? "0" : "1"

  const { callApprove } = useApprove( id );
  const [poolTxt, setPoolTxt] = useState( farms[id].isEnded ? "UNSTAKE" : "STAKE" );

  const handleChange = ( e ) => {
    setPoolTxt( e.target.dataset.label );
  };

  const [inputVal, setInputVal] = useState( "" );
  const [inputValueUnstake, setInputValueUnstake] = useState( "" );

  const { callDeposit } = useDeposit( id );
  const { callWithdraw } = useUnstake( id );
  const { callHarvest } = useHarvest( id );

  const onChangeInputVal = ( e ) => {
    setInputVal( e.target.value );
  };

  const handleMaxDeposit = ( AQRTokenBalance ) => {
    const getMaxValue = async () => {
      try {
        const maxValue = AQRTokenBalance;
        setInputVal( maxValue );
      } catch {
        //  toastError("Error", "Could not set max deposit value");
      }
    };
    getMaxValue();
  };

  const onSubmitDeposit = async ( AQRTokenBalance ) => {
    if ( parseFloat( inputVal ) <= 0 || inputVal === "" ) {
      //  toastError("Error", `Cannot deposit 0 ${token}, please input some value`);
    } else if (
      new BigNumber( inputVal ).isGreaterThan( new BigNumber( AQRTokenBalance ) )
    ) {
      //  toastError("Error", `Cannot deposit more than ${TokenBalance}`);
    } else {
      setBtnLoading( () => true );
      await callDeposit( pid, inputVal.toString() );
      setBtnLoading( () => false );
    }
  };

  const onChangeInputValueUnstake = ( e ) => {
    setInputValueUnstake( e.target.value );
  };

  const handleMaxWithdraw = () => {
    const getMaxValue = async () => {
      try {
        const maxValue = localValues.stakedValue;
        setInputValueUnstake( maxValue );
      } catch {
        // toastError("Error", "Could not set max deposit value");
      }
    };
    getMaxValue();
  };

  const onSubmitUnstake = async () => {
    console.log( "inn " )
    if ( parseFloat( inputValueUnstake ) <= 0 || inputValueUnstake === "" ) {
      //   toastError("Error", `Please input some ${token}s to withdraw`);
    } else if (
      new BigNumber( inputValueUnstake ).isGreaterThan(
        new BigNumber( localValues.stakedValue )
      )
    ) {
      //   toastError(
      //     "Error",
      //     `Cannot withdraw more than ${localValues.stakedValue}`
      //   );
    } else {
      setBtnLoading( () => true );
      await callWithdraw( pid, inputValueUnstake.toString() );
      setBtnLoading( () => false );
    }
  };
  const onSubmitReward = async () => {
    setBtnLoading( () => true );
    await callHarvest( pid );
    setBtnLoading( () => false );
  };

  const onSubmitApprove = async () => {
    setBtnLoading( () => true );
    await callApprove();
    setBtnLoading( () => false );
  };

  const [timerValue, setTimerValue] = useState( "-1" );
  const [networkId, setNetworkId] = useState( null );
  const [btnLoading, setBtnLoading] = useState( false );
  const [allowanceOfAQR, setAllowanceOfAQR] = useState( "0" );
  const [AQRTokenBalance, setAQRTokenBalance] = useState( "0" );

  const [localValues, setLocalValues] = useState( {
    stakedValue: "0",
    nvi: "0",
    accRewards: "0",
  } );

  const { account } = useWeb3React();
  const web3 = new Web3( window.ethereum );

  const loadLocalValues = async () => {
    const networkId = await web3.eth.net.getId();
    setNetworkId( networkId );
    if ( networkId === 137 && account ) {
      try {
        console.log( id )
        const FarmSmartContract = getSmartContract( id );
        const AQRSmartContract = getTokenSmartContract(
          farms[id].tokenAddresses[137]
        );
        console.log( FarmSmartContract, AQRSmartContract, farms[id].tokenAddresses[137] )
        // get local values
        const { amount, rewardDebt } = await FarmSmartContract.methods
          .userInfo( pid, account )
          .call();

        const AQRStakedValueInEth = getInEth( amount );

        const AQRAccRewardsInWei = await FarmSmartContract.methods
          .pendingAQR( pid, account )
          .call();

        const AQRAccRewardsInEth = getInEth( AQRAccRewardsInWei );

        console.log( AQRStakedValueInEth, AQRAccRewardsInWei );

        let AQRNviInEth;

        if ( AQRAccRewardsInEth && AQRStakedValueInEth ) {
          AQRNviInEth = new BigNumber( AQRAccRewardsInEth )
            .multipliedBy( 100 )
            .div( new BigNumber( AQRStakedValueInEth ) );
        } else AQRNviInEth = new BigNumber( 0 );

        // get allowance of AQR

        const tokenAllowance = await AQRSmartContract.methods
          .allowance( account, farms[id].lpAddresses[137] )
          .call();
        const tokenAllowanceInEth = getInEth( tokenAllowance );
        console.log( account, farms[id].lpAddresses[137], AQRSmartContract, "get sake" );
        setAllowanceOfAQR( () => tokenAllowanceInEth );

        // get AQR token balance
        const tokenBalance = await AQRSmartContract.methods
          .balanceOf( account )
          .call();
        const tokenBalanceInEth = getInEth( tokenBalance );

        setAQRTokenBalance( () => tokenBalanceInEth );

        setLocalValues( {
          stakedValue: AQRStakedValueInEth.toString(),
          nvi: AQRNviInEth.toString(),
          accRewards: AQRAccRewardsInEth.toString(),
        } );

        console.log(
          AQRStakedValueInEth.toString(),
          AQRNviInEth.toString(),
          AQRAccRewardsInEth.toString()
        );
      } catch ( e ) {
        // toastError("Error", "Failed to fetch user details");
      }
    }
  };

  useEffect( () => {
    if ( window.ethereum ) {
      if ( account ) loadLocalValues();
      const init = setInterval( () => {
        if ( account ) loadLocalValues();
      }, 10000 );

      return () => clearInterval( init );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, allowanceOfAQR] );

  return (
    <div className="body">
      <div className="pools">
        <div className="container">
          <div className="stake">
            <div className="stake__card">
              <div className="stake__card__header">
                <div className="back-btn">
                  <Link to="/staking">
                    <img
                      src={
                        require( "../../assets/images/staking-pic/arrow-right-circle.svg" )
                          .default
                      }
                      alt=""
                    />
                    <p>Go Back</p>
                  </Link>
                </div>
                <div className="text">
                  {id === "1" || id === "3" ? (
                    <img
                      src={require( "../../assets/images/staking-pic/maticaqr02.png" ).default}
                      alt="" />
                  ) : (
                    <img
                      src={require( "../../assets/images/staking-pic/AQR-token.svg" ).default}
                      alt=""
                      style={{ width: "70px" }}
                    />
                  )}
                  <h4>{farms[id].lpSymbol}</h4>
                </div>
              </div>
              <div className="stake__card__body">
                <div
                  className="btn-group"
                  role="group"
                  aria-label="Basic radio toggle button group"
                >
                  <input
                    type="radio"
                    className="btn-check"
                    name="btnradio"
                    id="btnradio1"
                    autoComplete="off"
                    onChange={handleChange}
                    data-label="STAKE"
                    disabled={farms[id].isEnded}
                    checked={poolTxt === "STAKE"}
                  />
                  <label className="btn btn-outline-primary" htmlFor="btnradio1">
                    Stake
                  </label>
                  <input
                    type="radio"
                    className="btn-check"
                    name="btnradio"
                    id="btnradio3"
                    autoComplete="off"
                    onChange={handleChange}
                    data-label="UNSTAKE"
                    checked={poolTxt === "UNSTAKE"}
                  />
                  <label className="btn btn-outline-primary" htmlFor="btnradio3">
                    Unstake
                  </label>
                </div>
                <div className="stake__card__text">
                  <div className="balance">
                    <p>{`${poolTxt}`}</p>
                    {poolTxt === "STAKE" ? (
                      <span>
                        {id === 0 || id === 2 ? "AQR " : "LP "}BALANCE:{" "}
                        {AQRTokenBalance}
                      </span>
                    ) : (
                      <span>STAKED BALANCE: {( localValues.stakedValue )}</span>
                    )}
                  </div>
                  <div className="input-group">
                    {poolTxt === "STAKE" ? (
                      <input
                        className="form-control"
                        type="number"
                        min="1"
                        value={inputVal}
                        onChange={onChangeInputVal}
                      />
                    ) : (
                      <input
                        className="form-control"
                        type="number"
                        min="1"
                        value={inputValueUnstake}
                        onChange={onChangeInputValueUnstake}
                      />
                    )}
                    {poolTxt === "STAKE" ? (
                      <button
                        className="input-group-text"
                        id="basic-addon2"
                        onClick={() => handleMaxDeposit( AQRTokenBalance )}
                      >
                        MAX
                      </button>
                    ) : (
                      <button
                        className="input-group-text"
                        id="basic-addon2"
                        onClick={() => handleMaxWithdraw( AQRTokenBalance )}
                      >
                        MAX
                      </button>
                    )}
                  </div>
                  {poolTxt === "STAKE" ? (
                    allowanceOfAQR > 100000 ? (
                      <button
                        className="btn btn-primary"
                        onClick={() => onSubmitDeposit( AQRTokenBalance )}
                      >
                        {btnLoading === false ? "Deposit" : "Processing"}
                      </button>
                    ) : (
                      <button
                        className="btn btn-primary"
                        onClick={onSubmitApprove}
                      >
                        {btnLoading === false ? "Approve" : "Processing"}
                      </button>
                    )
                  ) : (
                    <button
                      className="btn btn-primary"
                      onClick={() => onSubmitUnstake()}
                    // disabled={id == 0 || id ==1 ?true:false}
                    >
                      {btnLoading === false ? "Withdraw" : "Processing"}
                    </button>
                  )}
                </div>
              </div>
              <div className="stake__card__footer">
                <div className="rewards">
                  <span>Rewards Earned</span>
                  <p>{localValues.accRewards} AQR</p>
                </div>
                <button className=" btn" onClick={onSubmitReward}>
                  <a href="#">Claim Rewards</a>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pools;

import SearchBar from "../../../components/SearchBar";
import css from "./index.module.scss";


const TopSection = ( { open, setOpen, properties, setProperties } ) => {
    return (
        <section id="discover-section_1">
            <div className='container-lg'>
                <div className={css.pageHeader + " "}>
                    <ul className={css.tabGroup}>
                        <li
                            className={`${css.tabItme} ${open === "upcoming" ? css.active : ""}`}
                            onClick={() => setOpen( "upcoming" )}
                        >
                            Upcoming
                        </li>
                        <li
                            className={`${css.tabItme} ${open === "active" ? css.active : ""}`}
                            onClick={() => setOpen( "active" )}
                        >
                            Active
                        </li>
                        <li
                            className={`${css.tabItme} ${open === "closed" ? css.active : ""}`}
                            onClick={() => setOpen( "closed" )}
                        >
                            Closed
                        </li>
                    </ul>
                    <div id="discover-form" className={css.searchBox}>
                        <div id="discover-form-top">
                            <SearchBar setProperties={setProperties} open={open} />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default TopSection
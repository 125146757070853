
import MetaversePic from '../../../assets/images/landing/metaverse-Pic.svg'

function Metaverse () {
    return (
        <div className="metaverse">
            <div className="container-lg">
                <div className="metaverse__container">
                    <div className="row no-gutters mt-2 mb-2 align-items-center">
                        <div className="col-md-6 col-12 metaverse__bg-shep ">
                            <div className='metaverse__img'>
                                <img className="img-fluid" src={MetaversePic} alt="" width="100%" />
                            </div>
                        </div>
                        <div className="col-md-6 col-12">
                            <div className="text-md-left text-center metaverse__text">
                                <h2 className="content-subheader font-weight-bold mt-lg-4 mt-2 mb-4 alt-brand-blue">Metaverse</h2>
                                <p>
                                    AqarLand is the virtual land Metaverse of the Aqarchain platform. Buying a real world property in whole or in fractions will make the user eligible to claim the similar area in the virtual land. The land on Aqarland can only be claimed by purchasing a listed property on Aqarchain.io It is an independent NFT that buyers will claim with gamified utility of the land.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default Metaverse;
import Web3 from "web3";
import { farms } from "../Config";
import STAKE from "../abi/stake.json";

const getSmartContract = (id) => {
  const web3 = new Web3(window.ethereum);
  
  const stakeSmartContract =  new web3.eth.Contract(
    STAKE.abi ,
    farms[id].lpAddresses["137"]
  );
  return stakeSmartContract;
};

export default getSmartContract;

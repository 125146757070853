import { useCallback } from "react";
import Web3 from "web3";
import { useWeb3React } from "@web3-react/core";
import farms from "../Config/index";
import getTokenSmartContract from "../utils/getTokenSmartContract";
import { toast } from 'react-toastify'

const useApprove = (id) => {
  const { account } = useWeb3React();
  const web3 = new Web3(window.ethereum);

  const callApprove = useCallback(
    async (
      amount = "115792089237316195423570985008687907853269984665640564039457584007913129639935"
    ) => {
      toast.info("Processing...", "Approving your account for deposits");
      const TokenSmartContract = getTokenSmartContract(farms[id].tokenAddresses["137"]);

      await TokenSmartContract.methods
        .approve(farms[id].lpAddresses["137"], web3.utils.toBN(amount))
        .estimateGas({ from: account })
        .then((gasAmount) => {
          web3.eth.getGasPrice(async (err, currentGasPrice) => {
            if (err) {
              toast.error("Error while estimating gas \n" + err.message + "\nPlease set gas manully");
              await TokenSmartContract.methods
                .approve(farms[id].lpAddresses["137"], web3.utils.toBN(amount))
                .send({ from: account })
                .on("transactionHash", hash => {
                  toast.info(`Transaction hash: ${hash}`);
                })
                .on("confirmation", (confirmationNumber, receipt) => {
                  if (confirmationNumber === 1) {
                    toast.success(`Transaction confirmed: ${receipt.transactionHash}`);
                  }
                })
                .on("receipt", receipt => {
                  toast.info(`Transaction receipt: ${receipt.transactionHash}`);
                })
                .on("error", error => {
                  toast.error(`Transaction error: ${error.message}`);
                });
            } else {
              await TokenSmartContract.methods
                .approve(farms[id].lpAddresses["137"], web3.utils.toBN(amount))
                .send({ from: account, gas: gasAmount, gasPrice: currentGasPrice })
                .on("transactionHash", hash => {
                  toast.info(`Transaction hash: ${hash}`);
                })
                .on("confirmation", (confirmationNumber, receipt) => {
                  if (confirmationNumber === 1) {
                    toast.success(`Transaction confirmed: ${receipt.transactionHash}`);
                  }
                })
                .on("receipt", receipt => {
                  toast.info(`Transaction receipt: ${receipt.transactionHash}`);
                })
                .on("error", error => {
                  toast.error(`Transaction error: ${error.message}`);
                });
            }
          });
        }, async err => {
          toast.error("Error while estimating gas \n" + err.message + "\nPlease set gas manully");
          await TokenSmartContract.methods
            .approve(farms[id].lpAddresses["137"], web3.utils.toBN(amount))
            .send({ from: account })
            .on("transactionHash", hash => {
              toast.info(`Transaction hash: ${hash}`);
            })
            .on("confirmation", (confirmationNumber, receipt) => {
              if (confirmationNumber === 1) {
                toast.success(`Transaction confirmed: ${receipt.transactionHash}`);
              }
            })
            .on("receipt", receipt => {
              toast.info(`Transaction receipt: ${receipt.transactionHash}`);
            })
            .on("error", error => {
              toast.error(`Transaction error: ${error.message}`);
            });
        });
    },
    [account, web3.utils]
  );

  return { callApprove };
};
export default useApprove;

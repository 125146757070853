import BigNumber from "bignumber.js/bignumber";

BigNumber.config( {
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
} );

export const farms = [
  {
    pid: 2,
    lpSymbol: "AQR",
    stakingPeriod: "1 Year",
    lpAddresses: {
      137: "0x3DCBED4fC49f7f4046EeDEce69E15f975477dCCA",
      80001: "0x061F11dc3493a5Eaf7e8FD15991c78d132F20b84",
    },
    tokenSymbol: "AQR",
    tokenAddresses: {
      137: "0xaE204EE82E60829A5850FE291C10bF657AF1CF02",
      80001: "0x061F11dc3493a5Eaf7e8FD15991c78d132F20b84",
    },
  },
  {
    pid: 3,
    lpSymbol: "AQR - LP (MATIC)",
    stakingPeriod: "1 Year",
    lpAddresses: {
      137: "0x3DCBED4fC49f7f4046EeDEce69E15f975477dCCA",
      80001: "0x061F11dc3493a5Eaf7e8FD15991c78d132F20b84",
    },
    tokenSymbol: "AQR",
    tokenAddresses: {
      137: "0xbDDCF113703b8A20F8Ce783eAAE7fb421bD6AE9E",
      80001: "0x061F11dc3493a5Eaf7e8FD15991c78d132F20b84",
    },
  },
  {
    pid: 0,
    lpSymbol: "AQR",
    stakingPeriod: "1 Month",
    isEnded: true,
    lpAddresses: {
      137: "0xd797F200f23CAb4bcF874C62d577DdB01d94429d",
      80001: "0x061F11dc3493a5Eaf7e8FD15991c78d132F20b84",
    },
    tokenSymbol: "AQR",
    tokenAddresses: {
      137: "0xaE204EE82E60829A5850FE291C10bF657AF1CF02",
      80001: "0x061F11dc3493a5Eaf7e8FD15991c78d132F20b84",
    },
  },
  {
    pid: 1,
    lpSymbol: "AQR - LP (MATIC)",
    stakingPeriod: "1 Month",
    isEnded: true,
    lpAddresses: {
      137: "0xd797F200f23CAb4bcF874C62d577DdB01d94429d",
      80001: "0x061F11dc3493a5Eaf7e8FD15991c78d132F20b84",
    },
    tokenSymbol: "AQR",
    tokenAddresses: {
      137: "0xbDDCF113703b8A20F8Ce783eAAE7fb421bD6AE9E",
      80001: "0x061F11dc3493a5Eaf7e8FD15991c78d132F20b84",
    },
  },
];

export default farms;

export const BLOCKS_PER_YEAR = new BigNumber( 15768000 );

export const BLOCKS_PER_DAY = new BigNumber( 41142 );


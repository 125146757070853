import Web3 from "web3";
import Token from "../abi/Token.json";

const getTokenSmartContract = (address) => {
  const web3 = new Web3(window.ethereum);

  const tokenSmartContract = new web3.eth.Contract(
    Token.abi ,
    address
  );
  return tokenSmartContract;
};

export default getTokenSmartContract;


import Arrow from '../../../assets/images/landing/Arrow.svg'
import TokenUtilityPic from '../../../assets/images/landing/AQR-token-utility.png'


function TokenUtility () {
	return (
		<div className="TokenUtility">
			<div className="container-lg">
				<div className="row align-items-center">
					<div className="col-md-6 order2">
						<div className="TokenUtility__text">
							<h2 className="content-subheader font-weight-bold mt-lg-4 mt-2 mb-4 ">AQR Token Utility</h2>
							<p>The utility of our token is not limited to NFT'S but also involves</p>
							<div className="payments">
								<ul>
									<li>
										<img src={Arrow} alt="" />
										<p>Rental payments</p>
									</li>
									<li>
										<img src={Arrow} alt="" />
										<p>Treasury building</p>
									</li>
									<li>
										<img src={Arrow} alt="" />
										<p>Dividend payouts</p>
									</li>
									<li>
										<img src={Arrow} alt="" />
										<p>Staking and rewards distribution</p>
									</li>
									<li>
										<img src={Arrow} alt="" />
										<p>Governance voting</p>
									</li>
									<li>
										<img src={Arrow} alt="" />
										<p>Discount offerings</p>
									</li>
									<li>
										<img src={Arrow} alt="" />
										<p>Asset purchase</p>
									</li>
								</ul>
							</div>
						</div>

					</div>
					<div className="col-md-6 order1">
						<img className="img-fluid TokenUtility__img" src={TokenUtilityPic} alt="" width="80%" />
					</div>

				</div>


			</div>

		</div>

	)
}


export default TokenUtility;
function PartnersBy() {
  return (
    <section className="PartnersBy">
      <div
        id="landing-page-section_5-partner-and-supported-container"
        className="partner-and-supported-by-section-container container-lg"
      >
        <div className="row no-gutters">
          <div className="col-lg-12 col-12  text-center">
            <h1 className="font-weight-bold text-white text-center mb-5 PartnersBy__text">
              Partners and Supported By
            </h1>
          </div>
        </div>
        <div className="row no-gutters">
          <div className="col-lg-12 col-12">
            <div className="image--group">
              {[...Array(27)].map((_, index) => (
                <div className="image--item" key={index}>
                  <img
                    src={
                      require(`../../../assets/images/clients-logos/${
                        index + 1
                      }.svg`).default
                    }
                    alt=""
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PartnersBy;

import { authAxios, withoutAuthAxios } from "../../Config/axios"

export const verifyEmailToken = data => _dispatch => {
    return new Promise( ( resolve, reject ) => {
        withoutAuthAxios().get( `/auth/register/verify/email/${data.token}` )
            .then(
                response => {
                    resolve( response.data );
                    _dispatch( { type: "USER_DATA_UPDATE", payload: response.data.data } );
                    _dispatch( { type: "SAVE_TOKEN", payload: response.data.data.token } )
                },
                error => reject( error )
            )
    } )
}

export const resendEmailVerification = data => {
    return new Promise( async ( resolve, reject ) => {
        await withoutAuthAxios().post( "/auth/register/resend/email/", data )
            .then(
                response => resolve( response.data ),
                error => reject( error )
            )
    } )
}

export const login = data => async _dispatch => {
    return new Promise( async ( resolve, reject ) => {
        await withoutAuthAxios().post( "/auth/login", data )
            .then(
                response => {
                    resolve( response.data )
                    _dispatch( { type: "USER_DATA_UPDATE", payload: response.data.data } );
                    _dispatch( { type: "SAVE_TOKEN", payload: response.data.data.token } )
                },
                error => reject( error )
            )
            .catch(
                error => console.log( error )
            )
    } )
}

export const signup = data => async _dispatch => {
    return new Promise( async ( resolve, reject ) => {
        await withoutAuthAxios().post( "/auth/register", data )
            .then(
                response => {
                    resolve( response.data )
                    _dispatch( { type: "USER_DATA_UPDATE", payload: response.data.data.user } );
                    _dispatch( { type: "SAVE_TOKEN", payload: response.data.data.token } )
                },
                error => reject( error )
            )
            .catch(
                error => console.log( error )
            )
    } )
}

export const forgotPassword = data => async () => {
    return new Promise( async ( resolve, reject ) => {
        await withoutAuthAxios().post( "/auth/forgotpassword", data )
            .then(
                response => resolve( response.data ),
                error => reject( error.response )
            )
            .catch(
                error => console.log( error )
            )
    } )
}


export const resetPassword = ( data, token ) => async () => {
    return new Promise( async ( resolve, reject ) => {
        await withoutAuthAxios().post( `/auth/resetpassword/${token}`, data )
            .then(
                response => resolve( response.data ),
                error => reject( error )
            )
            .catch(
                error => console.log( error )
            )
    } )
}


export const sendOTP = data => async () => {
    return new Promise( async ( resolve, reject ) => {
        await withoutAuthAxios().post( "/auth/register/send-otp/", data )
            .then(
                response => resolve( response.data ),
                error => reject( error )
            )
            .catch(
                error => console.log( error )
            )
    } )
}


export const varifyOtp = data => async () => {
    return new Promise( async ( resolve, reject ) => {
        await withoutAuthAxios().post( "/auth/register/verify-otp/", data )
            .then(
                response => resolve( response.data ),
                error => reject( error )
            )
            .catch(
                error => console.log( error )
            )
    } );
};



export const checkUserExists = email => async () => {
    return new Promise( async ( resolve, reject ) => {
        await withoutAuthAxios()
            .get( `/auth/user/${email}` )
            .then(
                response => {
                    resolve( response )
                },
                error => {
                    reject( error.response )
                }
            )
            .catch(
                error => console.log( error )
            )
    } )
}

export const registerUser = ( data, token ) => async _dispatch => {
    return new Promise( async ( resolve, reject ) => {
        await withoutAuthAxios().post( "/auth/register/", data, { headers: { otp: `Bearer ${token}` } } )
            .then(
                response => {
                    resolve( response.data )
                    // _dispatch( { type: "USER_DATA_UPDATE", payload: response.data.user } );
                    // _dispatch( { type: "SAVE_TOKEN", payload: response.data.token } )
                },
                error => reject( error )
            )
            .catch(
                error => console.log( error )
            )
    } );
};


export const getUserProfile = () => async _dispatch => {
    return new Promise( async ( resolve, reject ) => {
        await authAxios().get( "/auth/profile" )
            .then(
                response => {
                    resolve( response.data )
                    _dispatch( { type: "USER_DATA_UPDATE", payload: response.data.data } );
                },
                error => reject( error )
            )
            .catch(
                error => console.log( error )
            )
    } )
}

export const updateUserProfile = data => async _dispatch => {
    return new Promise( async ( resolve, reject ) => {
        await authAxios().patch( "/auth/profile", data )
            .then(
                response => {
                    resolve( response.data )
                    _dispatch( { type: "USER_DATA_UPDATE", payload: response.data.data } );
                },
                error => reject( error )
            )
            .catch(
                error => console.log( error )
            )
    } )
}


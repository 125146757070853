
import PlayEarnPic from '../../../assets/images/landing/Play 2 Earn (P2E).png'

function PlayEarn () {
    return (
        <div className="playEarn">
            <div className="container-lg">
                <div className="playEarn__con">
                    <div className="row no-gutters mt-2 mb-2 align-items-center">
                        <div className="col-md-6 col-12 order2">
                            <div className="text-md-left text-center playEarn__text">
                                <h2 className="content-subheader font-weight-bold mt-lg-4 mt-2 mb-4 alt-brand-blue">Play 2 Earn (P2E)</h2>
                                <p>The virtual land on Aqarland once claimed gives the user an option to build, develop and populate the land with assets and commercially monetize. The land is an independent NFT that can be sold on NFT marketplaces or imported into the gaming metaverse of any partner land exploration games. The AqarLand game has its own assets that can be purchased with AQR tokens.</p>
                            </div>
                        </div>
                        <div className="col-md-6 col-12 order1">
                            <div className='playEarn__img'>
                                <img alt='' className="img-fluid" src={PlayEarnPic} width="100%" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default PlayEarn;
import { useWeb3React } from '@web3-react/core';
import { BigNumber } from 'ethers';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Web3 from 'web3';
import claimABI from '../../abi/claim.json';
import IsLoadingHOC from '../IsLoadingHOC';

const Claim = (props) => {
	const { address, setLoading } = props;
	const { account } = useWeb3React();
	const web3 = new Web3(
		window.ethereum ||
			new Web3.providers.HttpProvider('https://nd-240-117-310.p2pify.com/83ce4985408b1cfb834635c52c5ead03')
	);
	const contract = new web3.eth.Contract(claimABI, address);
	const [withdraw, setWithdraw] = useState(0);
	const [totalAmount, setTotalAmount] = useState(0);
	useEffect(async () => {
		if (account && address) {
			setLoading(true);
			await contract.methods
				.withdraw(account)
				.call()
				.then((response) => {
					setWithdraw(
						Number(
							BigNumber.from(response)
								.div(BigNumber.from('10').pow(BigNumber.from('18')))
								.toString()
						)
					);
					setLoading(false);
				});
			await contract.methods
				.totalAmount(account)
				.call()
				.then((response) => {
					setTotalAmount(
						Number(
							BigNumber.from(response)
								.div(BigNumber.from('10').pow(BigNumber.from('18')))
								.toString()
						)
					);
					setLoading(false);
				});
		}
	}, [account, address]);

	return (
		<div className='claim--container'>
			<div className='claim--item'>
				<h2>{totalAmount === 0 ? '0.00' : totalAmount.toFixed(2)} AQR</h2>
				<h4>AQR Balance</h4>
			</div>
			<div className='claim--item claimable'>
				<div>
					<h2>{totalAmount - withdraw === 0 ? '0.00' : (totalAmount - withdraw).toFixed(2)} AQR</h2>
				</div>
				<div>
					<h4>Available</h4>
				</div>
			</div>
			<div className='claim--item'>
				<h2>{withdraw === 0 ? '0.00' : withdraw} AQR</h2>
				<h4>withdrawn</h4>
			</div>
		</div>
	);
};

export default IsLoadingHOC(Claim);

let chainID,
    networkID,
    RPCUrl,
    BlockChainExplorerURL,
    NetworkName,
    AQRTokenAddress;

if ( window.location.hostname === "www.aqarchain.io" ) {
    chainID = `0x${Number( 137 ).toString( 16 )}`;
    networkID = 137;
    RPCUrl = "https://nd-240-117-310.p2pify.com/83ce4985408b1cfb834635c52c5ead03";
    BlockChainExplorerURL = "https://polygonscan.com/";
    NetworkName = "Polygon";
    AQRTokenAddress = "0xaE204EE82E60829A5850FE291C10bF657AF1CF02";
} else {
    chainID = `0x${Number( 80001 ).toString( 16 )}`;
    networkID = 137;
    RPCUrl = "https://nd-806-729-751.p2pify.com/7b8a2f2e611df7f099c6176f12530402";
    BlockChainExplorerURL = "https://mumbai.polygonscan.com/";
    NetworkName = "Matic TestNet";
    AQRTokenAddress = "0xaE204EE82E60829A5850FE291C10bF657AF1CF02";
}


export {
    chainID,
    networkID,
    RPCUrl,
    BlockChainExplorerURL,
    NetworkName,
    AQRTokenAddress
}
import { useWeb3React } from '@web3-react/core';
import { BigNumber } from 'ethers';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import Web3 from 'web3';
import { AqarMarketplace, ERC20Abi } from '../../../abi';
import IsLoadingHOC from '../../../components/IsLoadingHOC';
import { AQRTokenAddress, networkID } from '../../../Config/network';
import useOracle from '../../../hookss/useOracle';
import useShopping from '../../../hookss/useShopping';
import { addItemQty, removeFromCart, removeItemQty } from '../../../Redux/action/Cart';
const Shopping = ( props ) => {
    const { cartItems, setLoading, token } = props;
    const dispatch = useDispatch();
    const { account } = useWeb3React();
    const web3 = new Web3( window.ethereum )
    const { getApproveToken, buyNFTs } = useShopping()
    const ERC20Contract = new web3.eth.Contract( ERC20Abi.abi, AQRTokenAddress ).methods;
    const [allowance, setAllowance] = useState( null )
    const [totalPrice, setTotalPrice] = useState( 0 )
    const { AQRPrice, oneDAI } = useOracle()

    useEffect( async () => {
        if ( account ) {
            const _allowance = await ERC20Contract.allowance( account, AqarMarketplace.networks[networkID].address ).call();
            setAllowance( Number( BigNumber.from( _allowance )
                .div( BigNumber.from( "10" )
                    .pow( BigNumber.from( "18" ) ) )
                .toString() ) );
        }
        totalUSD()
    }, [account, cartItems, AQRPrice, oneDAI, allowance] );

    const totalUSD = () => {
        if ( cartItems.length > 0 ) {
            const _totalUSD = cartItems.reduce( ( totalHolder, m ) => totalHolder + m.price * m.amount, 0 );
            setTotalPrice( _totalUSD );
        }
    }

    const approveToken = async () => {
        setLoading( true );
        const approveAmount = ( ( totalPrice * oneDAI ) + 5 ).toFixed();
        await getApproveToken( approveAmount ).catch( error => {
            setLoading( false );
        } ).then( response => {
            setLoading( false )
        } )
    }


    const buyNFTsHandler = async () => {
        setLoading( true );
        let collections = [];
        let tokenId = [];
        let quantity = [];
        cartItems.forEach( nft => {
            tokenId.push( nft.assetId );
            collections.push( nft.nftAddress );
            quantity.push( nft.amount )
        } )
        await buyNFTs( { collections, tokenId, quantity }, AQRTokenAddress ).then( response => {
            setLoading( false );
        } ).catch( error => {
            setLoading( false );
        } )
    }
    return (
        <>
            <div className='page--header shopping--header'>
                <h3 className='page--title'>Shopping</h3>
                <p className='page--subtitle'>{`${cartItems.length} Items`} </p>
            </div>
            <div className='shopping--row'>
                <div className='shopping--card'>
                    <div className='shopping--body'>
                        {
                            cartItems.length > 0 ? (
                                cartItems.map( ( data, index ) => {
                                    return (
                                        <div className='item' key={index}>
                                            <div className='item--action'>
                                                <button
                                                    className='action--item'
                                                    onClick={() => {
                                                        dispatch( removeFromCart( data ) )
                                                    }}
                                                >
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                                        <path d="M7 4V2h10v2h5v2h-2v15a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V6H2V4h5zM6 6v14h12V6H6zm3 3h2v8H9V9zm4 0h2v8h-2V9z" />
                                                    </svg>
                                                </button>
                                            </div>
                                            <div className='item--details'>
                                                <img src={String( data.nft.image ).replace( "https://aqarchain.mypinata.cloud", "https://ipfs.io/" )} alt={data.nft.name} />
                                                <div>
                                                    <h3>{data.nft.name}</h3>
                                                    <div className='tags'>
                                                        {/* <span>{data.nftCollection.symbol}</span> */}
                                                        <span>
                                                            <img src={require( "../../../assets/images/aqr-icon.svg" ).default} width="15px" alt="AQR icon" />
                                                            <span>{( data.price * oneDAI ).toLocaleString( "en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 } )}</span>/<span>{data.price.toLocaleString( "en-US", { style: 'currency', currency: 'USD' } )}</span>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='item--others'>
                                                <div className="buyBtn-group">
                                                    <button
                                                        onClick={() => {
                                                            if ( data.amount !== 1 ) {
                                                                dispatch( removeItemQty( data ) )
                                                            }
                                                        }}>
                                                        <i className="fa fa-minus" aria-hidden="true" />
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="#aaaaaa"><path d="M5 11h14v2H5z" /></svg>
                                                    </button>
                                                    <span>{data.amount}</span>
                                                    <button
                                                        onClick={() => {
                                                            if ( data.amount < data.availableStoke ) {
                                                                dispatch( addItemQty( data ) )
                                                            }
                                                        }}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="#aaaaaa"><path d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z" /></svg>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                } )
                            ) : (
                                <div className='noItem--card'>
                                    <div className='cart--icon'>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path d="M4 6.414L.757 3.172l1.415-1.415L5.414 5h15.242a1 1 0 0 1 .958 1.287l-2.4 8a1 1 0 0 1-.958.713H6v2h11v2H5a1 1 0 0 1-1-1V6.414zM5.5 23a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm12 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"></path></svg>
                                    </div>
                                    <h4>Add items to your cart and start buying!</h4>
                                    <Link to='/aqarland' className='btn btn-gradient btn-rounded'>Go to Marketplace</Link>
                                </div>
                            )
                        }
                    </div>
                </div>
                {
                    cartItems.length > 0 && (
                        <div className='buy--card'>
                            <div className='buy--info'>
                                <h3>
                                    <img src={require( "../../../assets/images/aqr-icon.svg" ).default} width="20px" alt="AQR icon" />
                                    {( totalPrice * oneDAI ).toLocaleString( "en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 } )} <span>/ {totalPrice.toLocaleString( "en-US", { style: 'currency', currency: 'USD' } )}</span>
                                </h3>
                                <p>Total Price</p>
                            </div>
                            {
                                token ? (
                                    <button
                                        onClick={allowance > totalPrice * oneDAI
                                            ? buyNFTsHandler
                                            : approveToken}
                                        className='btn btn-gradient btn-rounded'
                                    >{allowance > totalPrice * oneDAI
                                        ? "Buy NFT"
                                        : "Approve AQR"}
                                    </button>
                                ) : (
                                    <Link to="/login" className='btn btn-gradient btn-rounded w-212px'>Login</Link>
                                )
                            }
                        </div>
                    )
                }
            </div>
        </>
    )
};


const mapStateToProps = ( state ) => {
    const { cartItems } = state.cart;
    const token = state.auth.token
    return { cartItems, token }
}

export default connect( mapStateToProps, {} )( IsLoadingHOC( Shopping ) );
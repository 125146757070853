


function WhyAqarchain () {
   return (
      <section id="landing-page-why-aqarchain-section_3" className="why-aqarchain-section">
         <div id="landing-page-section_3-container" className="landing-page-why-aqarchain-container container-fluid bg-secondary">
            <div className="mx-lg-8 mx-3">
               <div className="row no-gutters justify-content-lg-center mb-6 mt-6">
                  <div className="col-lg-10 col-md mx-4 text-center">
                     <div className="why-aqarchain-container">
                        <h1 className="font-weight-bold text-center content-header mb-3 text-white">Why Aqarchain.io?</h1>
                        <p className="big text-white">Aqarchain.io delivers a unique way to unlock NFT liquidity and provides<br />community access to owning parts of iconic and historic Real Estate NFTs.</p>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div id="landing-page-section_3-container-four-blocks" className="landing-page-why-aqarchain-container-four-blocks container-lg mb-6">
            <div className="mx-xl-8 mx-lg-5 mx-3 mt-sm-6 mb-sm-6">
               <div className="row row-cols-1 row-cols-md-4 g-4">
                  <div className="col-sm-12 col-md-6 col-lg-3 mt-2 mb-2">
                     <div className="card h-100 service-block four-blocks">
                        <div className="service-img four-blocks">
                           <p><img alt="" className="img-yellow partner-and-supported-logo-img img-fluid" src="images/why-aqarchain/why-aqarchain-img_1-accessibility_yellow.svg" /></p>
                           <p><img alt="" className="img-white partner-and-supported-logo-img img-fluid" src="images/why-aqarchain/why-aqarchain-img_1-accessibility_white.svg" /></p>
                        </div>
                        <div className="card-body">
                           <h3 className="card-title font-weight-bold mb-3">Accessibility</h3>
                           <p className="card-text big">Get access to the best real estate deals that were only available to institutional investors. Invest into real estate in fractions and earn dividends with capital appreciation.</p>
                        </div>
                     </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-3 mt-2 mb-2">
                     <div className="card h-100 service-block four-blocks">
                        <div className="service-img four-blocks">
                           <p><img alt="" className="img-yellow partner-and-supported-logo-img img-fluid" src="images/why-aqarchain/why-aqarchain-img_1-freedom_yellow.svg" /></p>
                           <p><img alt="" className="img-white partner-and-supported-logo-img img-fluid" src="images/why-aqarchain/why-aqarchain-img_1-freedom_white.svg" /></p>
                        </div>
                        <div className="card-body">
                           <h3 className="card-title font-weight-bold mb-3">Freedom</h3>
                           <p className="card-text big">No lock in period on investments, and no entry barrier to exclusive deals offered on the tokenization platform. Invest in properties with as low as USD 100/-</p>
                        </div>
                     </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-3 mt-2 mb-2">
                     <div className="card h-100 service-block four-blocks">
                        <div className="service-img four-blocks">
                           <p><img alt="" className="img-yellow partner-and-supported-logo-img img-fluid" src="images/why-aqarchain/why-aqarchain-img_1-diversity_yellow.svg" /></p>
                           <p><img alt="" className="img-white partner-and-supported-logo-img img-fluid" src="images/why-aqarchain/why-aqarchain-img_1-diversity_white.svg" /></p>
                        </div>
                        <div className="card-body">
                           <h3 className="card-title font-weight-bold mb-3">Diversity</h3>
                           <p className="card-text big">Get investment deals from affordable housing to luxury and ultra luxury residential properties in fractions. Access to global properties and other asset classes like commercial, retail and hospitality.</p>
                        </div>
                     </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-3 mt-2 mb-2">
                     <div className="card h-100 service-block four-blocks">
                        <div className="service-img four-blocks">
                           <p><img alt="" className="img-yellow partner-and-supported-logo-img img-fluid" src="images/why-aqarchain/why-aqarchain-img_1-creativity_yellow.svg" /></p>
                           <p><img alt="" className="img-white partner-and-supported-logo-img img-fluid" src="images/why-aqarchain/why-aqarchain-img_1-creativity_white.svg" /></p>
                        </div>
                        <div className="card-body">
                           <h3 className="card-title font-weight-bold mb-3">Creativity</h3>
                           <p className="card-text big">With the P2E opportunity available in the meta verse of AqarLand. The users have endless possibilities in imaginations of what can be developed and monetized. An opportunity to reap higher profits and dividends.</p>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
   )
}


export default WhyAqarchain;
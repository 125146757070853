import factoryabi from "../abi/aqarfactory.json";
import erc20abi from "../abi/erc20.json";
import vaultabi from "../abi/aqarvault.json";
import {
  factory,
  usdtAddress,
  wethAddress,
  usdcAddress,
  wbtcAddress,
  aqrAddress,
} from "../constants/addresses.json";
import Web3 from "web3";
import getRpcUrl from "./getRpcUrl";
import Multicall from "../helper/multicall/multicall";
import BigNumber from "bignumber.js";
import { useState } from "react";
import { ethers } from "ethers";

const web3 = new Web3(getRpcUrl());
var setapprovalload = false;

export const getBalances = async (account) => {
  const erccall = [
    {
      address: usdtAddress.address,
      name: "balanceOf",
      params: [account],
    },
    {
      address: usdcAddress.address,
      name: "balanceOf",
      params: [account],
    },
    {
      address: wbtcAddress.address,
      name: "balanceOf",
      params: [account],
    },
    {
      address: wethAddress.address,
      name: "balanceOf",
      params: [account],
    },
  ];
  var matic = await web3.eth.getBalance(account);
  var [usdt, usdc, wbtc, weth] = await Multicall(erc20abi.abi, erccall);
  usdt = new BigNumber(parseInt(usdt))
    .dividedBy(new BigNumber(10).pow(6))
    .toFixed(2);
  usdc = new BigNumber(parseInt(usdc))
    .dividedBy(new BigNumber(10).pow(6))
    .toFixed(2);
  weth = new BigNumber(parseInt(weth))
    .dividedBy(new BigNumber(10).pow(18))
    .toFixed(2);
  wbtc = new BigNumber(parseInt(wbtc))
    .dividedBy(new BigNumber(10).pow(8))
    .toFixed(2);
  matic = new BigNumber(parseInt(matic))
    .dividedBy(new BigNumber(10).pow(18))
    .toFixed(2);
  return { usdt, usdc, weth, matic, wbtc };
};

export const loadAddressAndAllowance = async (params, account) => {
  const factorycalls = [
    {
      address: factory.address,
      name: "propIdTopropTokenId",
      params: [params.id], //location.pathname.slice(9)
    },
  ];
  const [propIdTopropTokenId] = await Multicall(factoryabi.abi, factorycalls);

  const vaultaddrescall = [
    {
      address: factory.address,
      name: "NftToToken",
      params: [parseInt(propIdTopropTokenId)],
    },
  ];
  const [b] = await Multicall(factoryabi.abi, vaultaddrescall);

  const vaultcalls = [
    {
      address: b.toString(),
      name: "totalSupply",
    },
    {
      address: b.toString(),
      name: "tokenPrice",
    },
    {
      address: b.toString(),
      name: "name",
    },
    {
      address: b.toString(),
      name: "symbol",
    },
    {
      address: b.toString(),
      name: "ListPrice",
    },
    {
      address: b.toString(),
      name: "endtime",
    },
    {
      address: b.toString(),
      name: "fee",
    },
  ];
  const [totalsupply, tokenprice, name, symbol, listprice, endtime, fee] =
    await Multicall(vaultabi.abi, vaultcalls);
  var total = {
    totalsupply: new BigNumber(parseInt(totalsupply))
      .dividedBy(new BigNumber(10).pow("18"))
      .toNumber()
      .toFixed(1),
    tokenprice: new BigNumber(parseInt(tokenprice)).toNumber().toFixed(2),
    name: name,
    symbol: symbol,
    listprice: new BigNumber(parseInt(listprice))
      .dividedBy(new BigNumber(10).pow("18"))
      .toNumber()
      .toFixed(2),
    endtime: parseInt(endtime),
    fee: parseInt(fee),
  };

  if (b[0] && account) {
    const claim = [
      {
        address: b[0],
        name: "claimableBalance",
        params: [account],
      },
    ];
    var [claimable] = await Multicall(vaultabi.abi, claim);

    claimable = new BigNumber(parseInt(claimable[0]))
      .dividedBy(new BigNumber(10).pow(18))
      .toNumber()
      .toFixed(2);

    const allowanceCalls = [
      {
        address: aqrAddress.address,
        name: "allowance",
        params: [account, b[0]],
      },
      {
        address: usdtAddress.address,
        name: "allowance",
        params: [account, b[0]],
      },
      {
        address: usdcAddress.address,
        name: "allowance",
        params: [account, b[0]],
      },
      {
        address: wbtcAddress.address,
        name: "allowance",
        params: [account, b[0]],
      },
      {
        address: wethAddress.address,
        name: "allowance",
        params: [account, b[0]],
      },
    ];

    var [aqrAllow, usdtAllow, usdcAllow, wbtcAllow, wethAllow] =
      await Multicall(erc20abi.abi, allowanceCalls);

    aqrAllow = new BigNumber(aqrAllow)
      .dividedBy(new BigNumber(10).pow(18))
      .toNumber();
    usdtAllow = new BigNumber(usdtAllow)
      .dividedBy(new BigNumber(10).pow(6))
      .toNumber();
    usdcAllow = new BigNumber(usdcAllow)
      .dividedBy(new BigNumber(10).pow(6))
      .toNumber();
    wbtcAllow = new BigNumber(wbtcAllow)
      .dividedBy(new BigNumber(10).pow(8))
      .toNumber();
    wethAllow = new BigNumber(wethAllow)
      .dividedBy(new BigNumber(10).pow(18))
      .toNumber();

    var allowance = { aqrAllow, usdtAllow, usdcAllow, wbtcAllow, wethAllow };

    var vaultcontract = new web3.eth.Contract(vaultabi.abi, b[0]);

    var avaliblebalance = await vaultcontract.methods.availableBalance().call();
    avaliblebalance = web3.utils.fromWei(avaliblebalance, "ether");
  }

  return { total, claimable, allowance, avaliblebalance, b };
};

export const renderSwapButton = (
  account,
  opt,
  Allowance,
  vaultaddress,
  userid,
  enteredamount,
  referal,
  approvalload,
  setApprovAlload
) => {

  if (account) {
    if (opt === "0" && Allowance.aqrAllowance === 0) {
      return (
        <button
          className="btn btn-gradient w-100"
          type="button"
          onClick={() => approvetoken(opt, vaultaddress, account, setApprovAlload)}
        >
          {approvalload ? "Approving..." : "Approve AQR"}
        </button>
      );
    }
    if (opt === "1" && Allowance.usdtAllowance === 0) {
      // console.log( "Allowance.usdtAllowance", Allowance.usdtAllowance );
      return (
        <button
          className="btn btn-gradient w-100"
          type="button"
          onClick={() => approvetoken(opt, vaultaddress, account, setApprovAlload)}
        >
          {approvalload ? "Approving..." : "Approve USDT"}
        </button>
      );
    }
    if (opt === "2" && Allowance.usdcAllowance === 0) {
      return (
        <button
          className="btn btn-gradient w-100"
          type="button"
          onClick={() => approvetoken(opt, vaultaddress, account, setApprovAlload)}
        >
          {approvalload ? "Approving..." : "Approve USDC"}
        </button>
      );
    }
    if (opt === "4" && Allowance.wbtcAllowance === 0) {
      return (
        <button
          className="btn btn-gradient w-100"
          type="button"
          onClick={() => approvetoken(opt, vaultaddress, account, setApprovAlload)}
        >
          {approvalload ? "Approving..." : "Approve WBTC"}
        </button>
      );
    }
    if (opt === "5" && Allowance.wethAllowance === 0) {
      return (
        <button
          className="btn btn-gradient w-100"
          type="button"
          onClick={() => approvetoken(opt, vaultaddress, account, setApprovAlload)}
        >
          {approvalload ? "Approving..." : "Approve WETH"}
        </button>
      );
    }
    // if(auth.user._id)
    {
      return (
        <button
          className="btn btn-gradient w-100"
          type="button"
          onClick={() =>
            buytoken(opt, vaultaddress, enteredamount, userid, account,referal)
          }
        >
          Start Buying
        </button>
      );
    }
  }
};

const approvetoken = async (opt, add, account, setApprovAlload) => {
  const web3 = await new Web3(window.ethereum);
  const aqrcontract = new web3.eth.Contract(erc20abi.abi, aqrAddress.address);
  const usdtcontract = new web3.eth.Contract(erc20abi.abi, usdtAddress.address);
  const usdccontract = new web3.eth.Contract(erc20abi.abi, usdcAddress.address);
  const wbtccontract = new web3.eth.Contract(erc20abi.abi, wbtcAddress.address);
  const wethcontract = new web3.eth.Contract(erc20abi.abi, wethAddress.address);
  if (account) {
    if (opt === "0") {
      setApprovAlload(true)
      var approve = await aqrcontract.methods
        .approve(add[0], ethers.constants.MaxUint256)
        .send({ from: account });
      setApprovAlload(false)
    }
    if (opt === "1") {
      setApprovAlload(true)
      var approve = await usdtcontract.methods
        .approve(add[0], ethers.constants.MaxUint256)
        .send({ from: account });
      setApprovAlload(false)
    }
    if (opt === "2") {
      setApprovAlload(true)
      var approve = await usdccontract.methods
        .approve(add[0], ethers.constants.MaxUint256)
        .send({ from: account });
      setApprovAlload(false)
    }
    if (opt === "4") {
      setApprovAlload(true)
      var approve = await wbtccontract.methods
        .approve(add[0], ethers.constants.MaxUint256)
        .send({ from: account });
      setApprovAlload(false)
    }
    if (opt === "5") {
      setApprovAlload(true)
      var approve = await wethcontract.methods
        .approve(add[0], ethers.constants.MaxUint256)
        .send({ from: account });
      setApprovAlload(false)
    }
  }
};
const buytoken = async (opt, add, amount, userid, account,referal) => {
    console.log(opt, add, amount, userid, account,referal,"buy mn hu")
  const web3 = await new Web3(window.ethereum);

  const vaultcontract = new web3.eth.Contract(vaultabi.abi, add[0]);

  //  console.log( amountInMwei * 100, "in mwei" );

  if (account) {
    if (opt === "0") {
      const bal = await vaultcontract.methods
        .buyFromwhiteListCrypto(
          aqrAddress.address,
          amount.toString(),
          userid,
          referal
        )
        .send({ from: account });
    }
    if (opt === "1") {
      const bal = await vaultcontract.methods
        .buyTokenWithStableCoin(
          usdtAddress.address,
          amount.toString(),
          userid,
          referal
        )
        .send({ from: account });
    }
    if (opt === "2") {
      const bal = await vaultcontract.methods
        .buyTokenWithStableCoin(
          usdcAddress.address,
          amount.toString(),
          userid,
          referal
        )
        .send({ from: account });
    }
    if (opt === "3") {
      const bal = await vaultcontract.methods
        .buyFromMatic(amount.toString(), userid, referal)
        .send({ from: account, value: amount });
    }
    if (opt === "4") {
      const bal = await vaultcontract.methods
        .buyFromBtc((amount).toString(), userid, referal)
        .send({ from: account });
    }
    if (opt === "5") {
      const bal = await vaultcontract.methods
        .buyFromwhiteListCrypto(
          wethAddress.address,
          amount.toString(),
          userid,
          referal
        )
        .send({ from: account });
    }
  }
};

import RealEstate from "./RealEstate/index"
import Platform from "./Platform/index"
import Metaverse from "./Metaverse/index"
import WhyAqarchain from "./WhyAqarchain/index"
import PlayEarn from "./Play/index"
import Ownership from "./Ownership/index"
import OwnershipWork from "./OwnershipWork/index"
import MarketingBanner from "./MarketingBanner/index"
import TokenUtility from "./TokenUtility/index"
import AqarchainNews from "./AqarchainNews/index"
import PartnersBy from "./PartnersBy/index"
import TokenListed from "./TokenListed/index"
import TokenPrice from "./TokenPrice"
function Landing () {
    return (
        <>
            <section className="section--hero">
                <div className="container-lg">
                    <div className="row">
                        <div className="col-lg-6 d-flex align-items-center">
                            <div className="hero--content">
                                <h1>What is AqarChain?</h1>
                                <p>Aqarchain is a blockchain powered real estate asset tokenization platform whose aim is to create a
                                    decentralized ecosystem where investment in real estate is as easy as investing in shares on the
                                    stock market. This aim is achieved by minting real estate NFT and fractionalizing it. Aqarchain
                                    has created a Metaverse AqarLand mapped to the real world properties.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-6 d-flex align-items-end p-0">
                            <div className="hero--img">
                                <img src={require( "../../assets/images/landing/hero-section-art.png" ).default} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section--tokenPrice">
                <TokenPrice />
            </section>
            {/* <Herosection /> */}
            <TokenListed />
            <RealEstate />
            <Platform />
            <Metaverse />
            <PlayEarn />
            <Ownership />
            <OwnershipWork />
            <MarketingBanner />
            <WhyAqarchain />
            <TokenUtility />
            <AqarchainNews />
            <PartnersBy />
        </>
    )
}


export default Landing;
import { useCallback } from "react";
import { useWeb3React } from "@web3-react/core";
import getsmartcontract from "../utils/getsmartcontract";
import { toast } from 'react-toastify'
import useWeb3 from './useWeb3'

const useHarvest = (id) => {
  const { account } = useWeb3React();
  const web3 = useWeb3()

  const callHarvest = useCallback(
    async (pid) => {
      toast.info("Processing...", "You requested to harvest your rewards");
      const FarmSmartContract = getsmartcontract(id);
      await FarmSmartContract.methods
        .withdraw(pid, "0")
        .estimateGas({ from: account })
        .then((gasAmount) => {
          web3.eth.getGasPrice(async (err, currentGasPrice) => {
            if (err) {
              toast.error("Error while estimating gas \n" + err.message + "\nPlease set gas manully");
              await FarmSmartContract.methods
                .withdraw(pid, "0")
                .send({ from: account, gas: gasAmount, gasPrice: currentGasPrice })
                .on("transactionHash", hash => {
                  toast.info(`Transaction hash: ${hash}`);
                })
                .on("confirmation", (confirmationNumber, receipt) => {
                  if (confirmationNumber === 1) {
                    toast.success(`Transaction confirmed: ${receipt.transactionHash}`);
                  }
                })
                .on("receipt", receipt => {
                  toast.info(`Transaction receipt: ${receipt.transactionHash}`);
                })
                .on("error", error => {
                  toast.error(`Transaction error: ${error.message}`);
                });
              toast.success("Success", "Rewards successfully harvested");
            } else {
              await FarmSmartContract.methods
                .withdraw(pid, "0")
                .send({ from: account, gas: gasAmount, gasPrice: currentGasPrice })
                .on("transactionHash", hash => {
                  toast.info(`Transaction hash: ${hash}`);
                })
                .on("confirmation", (confirmationNumber, receipt) => {
                  if (confirmationNumber === 1) {
                    toast.success(`Transaction confirmed: ${receipt.transactionHash}`);
                  }
                })
                .on("receipt", receipt => {
                  toast.info(`Transaction receipt: ${receipt.transactionHash}`);
                })
                .on("error", error => {
                  toast.error(`Transaction error: ${error.message}`);
                });
              toast.success("Success", "Rewards successfully harvested");
            }
          });
        },async err=>{
          toast.error("Error while estimating gas \n" + err.message + "\nPlease set gas manully");
          await FarmSmartContract.methods
                .withdraw(pid, "0")
                .send({ from: account })
                .on("transactionHash", hash => {
                  toast.info(`Transaction hash: ${hash}`);
                })
                .on("confirmation", (confirmationNumber, receipt) => {
                  if (confirmationNumber === 1) {
                    toast.success(`Transaction confirmed: ${receipt.transactionHash}`);
                  }
                })
                .on("receipt", receipt => {
                  toast.info(`Transaction receipt: ${receipt.transactionHash}`);
                })
                .on("error", error => {
                  toast.error(`Transaction error: ${error.message}`);
                });
              toast.success("Success", "Rewards successfully harvested");
        });
    },
    [account]
  );

  return { callHarvest };
};
export default useHarvest;
